import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 3),
    marginBottom: '24px',
    marginTop: '8px'
  },
  card: {
    width: theme.breakpoints.values.sm,
    maxWidth: '100%',
    overflow: 'unset',
  },
  alignRight: {
    float: 'right',
  },
  cardAction: {
    display: 'block'
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  submitGrid: {
    textAlign: 'right'
  },
  cancelBtn: {
    color: colors.red[500],
  },
  submitBtn: {
    color: colors.green[500],
  },
}));
export default useStyles;