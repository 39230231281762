import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles(theme => ({
  bbToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#cfebe9',

  }, 
  bbBoday: {
    overflowWrap: 'anywhere',
    whiteSpace: 'break-spaces',
    wordWrap: 'break-word',
    wordBreak:'break-all',
    maxWidth: theme.spacing(31),
    '&:hover': {
      '& $editButton': {
        visibility: 'visible'
      },
      '& $zoomButton': {
        visibility: 'visible'
      }
    }
  },
  dateField: {
    fontSize: '12px',
    padding: '4px',
    width: '140px',
    margin: '0px',
    textAlign: 'center'
  },
  action: {
    padding: theme.spacing(0.5)
  },
  blackboardContent: {
    padding: theme.spacing(1),
    height: '225px',
  },
  inputPlaceholder: {
    height: '320px',
    maxHeight: '480px',
    padding: theme.spacing(0, 1),
    fontSize:'0.9em'
  },
  editButton: {
    visibility: 'hidden',
    position: 'absolute',
    bottom: theme.spacing(0),
    right: theme.spacing(1),
    /* [theme.breakpoints.down('md')]: {
      top: theme.spacing(3),
      bottom: 'auto'
    }, */
  },
  zoomButton: {
    visibility: 'hidden',
    position: 'absolute',
    bottom: theme.spacing(0),
    right: theme.spacing(6),
    /* [theme.breakpoints.down('md')]: {
      top: theme.spacing(3),
      bottom: 'auto'
    }, */
  },
}));
export default useStyles;