import React, { 
  useState, 
  Fragment }              from 'react';
import { 
  Link as RouterLink }    from 'react-router-dom';
import PropTypes          from 'prop-types';
import clsx               from 'clsx';
import _                  from 'lodash';
import moment             from 'moment';
import uuid               from 'uuid/v1';

import ThumbUpAltIcon           from '@material-ui/icons/ThumbUpAlt';
import ChatIcon                 from '@material-ui/icons/Chat';
import Link                     from '@material-ui/core/Link';
import Typography               from '@material-ui/core/Typography';
import IconButton               from '@material-ui/core/IconButton';
import Tooltip                  from '@material-ui/core/Tooltip';
import Divider                  from '@material-ui/core/Divider';

import axios                              from 'utils/mcaxios';
import { 
  POST_ADD_COMMENT_LIKE_URL,
  POST_ADD_COMMENT_REPLY_URL,
  GET_FETCH_COMMENT_REPLY_URL,
  DELETE_DELETE_COMMENT_URL,
  PUT_UPDATE_COMMENT_URL, 
  GET_QUERY_COMMENT_LIKES_URL }           from 'store/rest-api';
import { SUCCESS_ADD_COMMENT_REPLY_CODE, 
  SUCCESS_UPDATE_COMMENT_CODE,
  SUCCESS_FETCH_COMMENT_LIKES_CODE,
  SUCCESS_ADD_COMMENT_LIKE_CODE }         from 'store/statusCode';
import { McAlert, 
  McAvatar, 
  McCommentInput, 
  McReplyCard, 
  McLoader, 
  McLikesList, 
  McDialogModal,
  EditMcCommentModal, 
  McCommentMoreOptions }                  from 'components';
import useStyles                          from './McCommentCard.style';

const McCommentCard = props => {
  const { comment, className, readOnly,
    onDeleteComment, onUpdateEditedComment, ...rest } = props;
  const classes = useStyles();

  const [openEditCommentModal, setOpenEditCommentModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [message, setMessage] = useState('');
  
  const [fetchReplyLoading, setFetchReplyLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [replyPageSize, setReplyPageSize] = useState('');

  const [liked, setLiked] = useState(false);
  const [commentLikesCount, setCommentLikesCount] = useState(comment.likeCount);
  const [commentReplyCount, setCommentReplyCount] = useState(comment.replyCount);
  const [commentReplies, setCommentRelies] = useState([]);

  const [submitReplyLoading, setSubmitReplyLoading] = useState(false);
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [showReplyRecords, setShowReplyRecords] = useState(false);
 
  const [replyUsername, setReplyUsername] = useState('')

  const handleCloseAlert = () => {
    setOpenConfirmModal(false);
  }

  /* [ 1 ], handle like comment */
  const hanleLikeComment = (count) => {

    axios.post(POST_ADD_COMMENT_LIKE_URL,
      {
        commentId: comment.id,
        count: count,
      })
      // .then(function (response) {
      //   const {status} = response.data;
      // })
      .catch(function (error) {
        console.log(error);
      });
  }
  const handleCommentLike = () => {
    setLiked(true);
    setCommentLikesCount(commentLikesCount => commentLikesCount + 1);
    hanleLikeComment(1)
  };

  const handleCommentUnlike = () => {
    setLiked(false);
    setCommentLikesCount(commentLikesCount => commentLikesCount - 1);
    hanleLikeComment(-1)
  };
  /* [ 2 ], handle click reply icon */
  const handleClickReplyCount = () => {
    setShowReplyRecords(!showReplyRecords);
    if(!showReplyRecords){
      fetchCommentReplies(0);
    }
    /* if(commentReplies.length == 0){
      fetchCommentReplies(0);
    } */
  }
  const handleClickReplyIcon = (replyUsername) => {
    if(!_.isEmpty(replyUsername)){
      setReplyUsername(replyUsername);
      setShowReplyInput(true);
    }else{
      setReplyUsername('');
    }
    setShowReplyInput(!showReplyInput);
  };
  const fetchCommentReplies = (skip) => {
    setFetchReplyLoading(true)

    axios.get(GET_FETCH_COMMENT_REPLY_URL.concat('/')
      .concat(comment.id).concat('/').concat(skip))
      .then(function (response) {
        const {status, commentRepliesPageVo, pageSize,
          latestReplyCount, latestLikeCount} = response.data;
        if(status){
          if(commentRepliesPageVo != null ) {
            let reversed = _.reverse(commentRepliesPageVo);
            if(skip === 0){
              setCommentRelies(reversed);
            }else{
              let newCommentReplies = [ ...reversed, ...commentReplies ]
              setCommentRelies(newCommentReplies);
            }
            setCommentReplyCount(latestReplyCount);
            setCommentLikesCount(latestLikeCount);
            setSkip(skip + parseInt(pageSize));
            setReplyPageSize(pageSize)
          }
        }
        setFetchReplyLoading(false)
      })
      .catch(function (error) {
        setFetchReplyLoading(false)
        console.log(error);
      });
  }
  const showPreviousReplies = () => {
    fetchCommentReplies(skip)
  }
  /* [ 3 ], handle Submit reply */
  const handleSubmitReply = (value) => {
    if(_.isEmpty(_.trim(value))){
      return;
    }
    setSubmitReplyLoading(true);
    axios.post(POST_ADD_COMMENT_REPLY_URL, {
      commentId: comment.id,
      replyContent: value
    })
      .then(function (response) {
        const {status} = response.data;
        if(status === SUCCESS_ADD_COMMENT_REPLY_CODE){
          fetchCommentReplies(0);
        }
        setSubmitReplyLoading(false);
        setShowReplyInput(false);
        setShowReplyRecords(true);
      })
      .catch(function (error) {
        setSubmitReplyLoading(false);
        setShowReplyInput(true);
        console.log(error);
      });
  };
  const handleOpenEditModel = () => {
    setOpenEditCommentModal(true);
  };
  const handleEditCommentModalClose = () => {
    setOpenEditCommentModal(false);
  };
  const handleOpenDeleteConfirm = () => {
    setOpenConfirmModal(true);
    setMessage('Do you want to delete this comment? All its likes and replies will be removed.');
  };
  const handleConfirm = () => {
    setOpenConfirmModal(false);
    
    //Request backend to remove reply and decrease comment replycount
    axios.delete(DELETE_DELETE_COMMENT_URL, {
      data: {
        commentId: comment.id
      }
    }).then( () => {
      onDeleteComment()
    })
      .catch( error => {
        console.log('error ', error);
      });
  }
  const handleDeleteReply = (/* removedReplyId */) => {
    setOpenConfirmModal(false);
    fetchCommentReplies(0);
  }
  const [updateLoading, setUpdateLoading] = useState(false);
  const handleUpdateComment = (content) => {
    setUpdateLoading(true);
    axios.put(PUT_UPDATE_COMMENT_URL, {
      commentId: comment.id,
      commentContent: content
    })
      .then( response => {
        const {status } = response.data;
        // const redirectPath = '/mcpost';
        if(status === SUCCESS_UPDATE_COMMENT_CODE ){
          //onClose()
          onUpdateEditedComment(comment.id, content);
        }else{
          //
        }
        setUpdateLoading(false);
        setOpenEditCommentModal(false);
      })
      .catch( error => {
        setOpenEditCommentModal(false);
        setUpdateLoading(false);
        console.log('error ', error);
      });
  }
  
  const handleUpdateReply = (replyId, content) => {
    
    // _.filter(postComments, function(comment) { return comment.commentId !== removedCommentId; });
    const filteredReplies =  commentReplies.map((reply) => {
      if(reply.id === replyId){
        reply.content = content;
      }
      return reply;
    })
    setCommentRelies([
      ...filteredReplies
    ]);

  }

  /* handle display likes modal */
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [commentLikeList, setCommentLikeList] = useState([])
  const [likesSkip, setLikesSkip] = useState(0);
  const [commentLikeTotalCount, setCommentLikeTotalCount] = useState(0);

  const handleCloseModal = () => {
    setLoading(false)
    setOpen(false)
  }
  const handleDisplayLikes = () => {
    setOpen(true)
    fetchCommentLikes(0)
  }
  const fetchCommentLikes = (likesSkip) => {
    setLoading(true)
    axios.get(GET_QUERY_COMMENT_LIKES_URL.concat('/')
      .concat(comment.id).concat('/').concat(likesSkip))
      .then(function (response) {
        const {status, commentLikeVos, pageSize, commentLikeTotalCount} = response.data;
        if(status === SUCCESS_FETCH_COMMENT_LIKES_CODE){
          if(likesSkip === 0){
            setCommentLikeList([...commentLikeVos])
          }else{
            setCommentLikeList([
              ...commentLikeList,
              ...commentLikeVos,
            ])
          }
          setLikesSkip(likesSkip + parseInt(pageSize));
          setCommentLikeTotalCount(commentLikeTotalCount)
        }
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
        console.log(error);
      });
  }
  const handleFetchCommentLikes = () => {
    fetchCommentLikes(likesSkip);
  }
  const handleUnlikeComment = () => {
    axios.post(POST_ADD_COMMENT_LIKE_URL,
      {
        commentId: comment.id,
        count: -1,
      })
      .then(function (response) {
        const {status} = response.data;
        if(status === SUCCESS_ADD_COMMENT_LIKE_CODE){
          setLiked(false);
          setCommentLikesCount(commentLikesCount => commentLikesCount - 1);
          fetchCommentLikes(0)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <Fragment>
      <McDialogModal
        onClose={handleCloseModal}
        open={open}
        title="Comment likes"
      >
        <McLikesList 
          likeList={commentLikeList}
          loading={loading}
          onFetchMoreLikes={handleFetchCommentLikes}
          onUnlike={handleUnlikeComment}
          totalCount={commentLikeTotalCount}
        />
      </McDialogModal>
      <McAlert
        handleConfirm={handleConfirm}
        message={message}
        onClose={handleCloseAlert}
        open={openConfirmModal}
        title="Alert"
        variant="warning"
      />
      {
        openEditCommentModal
        &&
        <EditMcCommentModal 
          comment={comment}
          loading={updateLoading}
          onClose={handleEditCommentModalClose}
          onUpdateComment={handleUpdateComment}
          open={openEditCommentModal}
        />
      }
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <McAvatar 
          avatarUrl={comment.createUserAvatar}
          className={classes.avatar}
          userName={comment.createUserName}
        />
        <div className={classes.bubble}>
          <div className={classes.header}>
            <Link
              color="textPrimary"
              component={RouterLink}
              to={`/user-profile/${comment.createUserId}`}
              variant="h6"
            >
              {comment.createUserName}
            </Link>
            <Typography
              className={classes.time}
              title={moment(comment.createDatetime).local().format('llll')}
              variant="body2"
            >
              {moment(comment.createDatetime).local().fromNow()}
            </Typography>
            {
              readOnly !== true
              &&
              <McCommentMoreOptions 
                comment={comment}
                onOpenDeleteConfirm={handleOpenDeleteConfirm}
                onOpenEditModel={handleOpenEditModel}
              />
            }
          </div>
          <Divider />
          <div  
            className={classes.commentContent}
            dangerouslySetInnerHTML={{ __html:comment.content}} 
            id="commentContent"
            name="commentContent"
          />
          {
            readOnly !== true
            &&
            <div
              className={classes.likeReplyBar}
            >
              {liked ? (
                <Tooltip title="Unlike">
                  <IconButton
                    className={classes.likedButton}
                    onClick={handleCommentUnlike}
                    size="small"
                  >
                    <ThumbUpAltIcon/>
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Click to like this comment">
                  <IconButton
                    className={classes.likeButton}
                    onClick={handleCommentLike}
                    size="small"
                  >
                    <ThumbUpAltIcon/>
                  </IconButton>
                </Tooltip>
              )}
              <Typography >
                {       
                  commentLikesCount > 0
                  &&
                  <Tooltip title="Click to see who liked this comment">
                    <Link 
                      className={classes.count}
                      href="#" 
                      onClick={handleDisplayLikes}
                      variant="subtitle1"
                    >
                      {commentLikesCount} likes
                    </Link>
                  </Tooltip>       
                }
              </Typography>
              <Tooltip 
                aria-label="Comments"
                title="Click to display / hide reply input"  
              >
                <IconButton 
                  aria-label="add to shopping cart"
                  className={classes.replyButton}
                  onClick={()=> handleClickReplyIcon('')}
                  size="small"
                >
                  <ChatIcon style={{ fontSize: 22 }}/>
                </IconButton>
              </Tooltip>
              <Typography>
                <Tooltip 
                  aria-label="Comments"
                  title="Click to display / hide replies" 
                >
                  <Link 
                    className={classes.count}
                    href="#" 
                    onClick={handleClickReplyCount}
                    variant="subtitle1"
                  >
                    {commentReplyCount} replies
                  </Link>
                </Tooltip>
              </Typography>
            </div>
          }
          {showReplyRecords && commentReplies && (
            <div className={classes.comments}>
              <McLoader 
                className={classes.loadingPosts}
                loading={fetchReplyLoading} 
                size={20}
                thickness={4}
              />
              {
                commentReplyCount > commentReplies.length 
                &&
                commentReplyCount > replyPageSize
                &&
                !fetchReplyLoading
                &&
                <div className={classes.loadingMoreLink}>
                  <Link 
                    className={classes.showPrevious}
                    href="#" 
                    onClick={showPreviousReplies}
                    variant="caption"
                  >
                    Loading {commentReplyCount - commentReplies.length} more replies
                  </Link>
                </div>
              }
              <Divider className={classes.divider} />
              {commentReplies.map(reply => (
                <McReplyCard
                  key={reply.id}
                  onClickReplyIcon={handleClickReplyIcon}
                  onDeleteReply={handleDeleteReply}
                  onUpdateEditedReply={handleUpdateReply}
                  reply={reply}
                />
              ))}
            </div>
          )}
          {
            showReplyInput && 
            <McCommentInput 
              inputId={'comment_'.concat(uuid())}
              onSubmitComment={handleSubmitReply}
              replyUsername={replyUsername}
              submitCommentLoading={submitReplyLoading}
            /> 
          }
        </div>
      </div>
    </Fragment>
  );
};

McCommentCard.propTypes = {
  className: PropTypes.string,
  comment: PropTypes.object.isRequired,
  onDeleteComment: PropTypes.func,
  onUpdateEditedComment: PropTypes.func,
  readOnly: PropTypes.bool
};

export default McCommentCard;
