import React, { useState, useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { pasteEmojiAtCaret } from 'utils/EmojiUtils';
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import Twemoji from 'react-twemoji';

import Tooltip from '@material-ui/core/Tooltip';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import Typography from '@material-ui/core/Typography';

import { MC_MAX_LENGTH_COMMENT_CONTENT } from 'mcconfig'
import useStyles from './EditMcCommentForm.style';
import './EditMcCommentForm.css';

const EditMcCommentForm = (props) => {
  const { comment, inputCount, setInputCount,  } = props;
  const classes = useStyles();
  
  useEffect(() => {
    let mounted = true;
    if(mounted && comment){
      document.getElementById('descContent').innerHTML = comment.content;
      handleCaculateInputCount()
    }
    return () => { mounted = false }
  }, [comment]);// eslint-disable-line

  /**[ Insert emoji into description text ]**/
  const emojiRef = useRef(null);
  const [, setEmoji] = useState({});
  // const [descTitle, setDescTitle] = useState('Desciption');
  const [openEmojiPopover, setOpenEmojiPopover] = useState(false);
  const handleEmojiPopverClose = () => {
    setOpenEmojiPopover(false);
  };
  const handleEmojiPicker = () => {
    if (!openEmojiPopover) {
      setOpenEmojiPopover(true);
    }else {
      setOpenEmojiPopover(false);
    }
  };
  const addEmoji = (emoji) => {
    setEmoji(emoji)
    document.getElementById('descContent').focus();
    // setDescTitle('Desciption');
    pasteEmojiAtCaret(emoji.native);
    setInputCount(inputCount + 1);
  }


  const handleCaculateInputCount = () => {//event, reason
    let div = document.getElementById('descContent');
    if(div != null){
      var textWithNBSpaceReplaced = div.innerHTML.replace(/&nbsp;/g, ' ');
      let len = textWithNBSpaceReplaced.length;
      let mojiObjs = div.getElementsByClassName('twemoji');
      if(mojiObjs != null){
        len = (len - (mojiObjs.length * 106)) + mojiObjs.length;
      }
      setInputCount(len);
    }
  };
  
  return (
    <Fragment>

      <form >
        <div className={classes.content}>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
            >
              <Twemoji options={{ className: 'twemoji'}}>
                <div 
                  className={classes.desc}
                  contentEditable="true"
                  id="descContent"
                  name="descContent"
                  onInput={handleCaculateInputCount}
                  placeholder="description"
                  suppressContentEditableWarning
                />
              </Twemoji>
              <Typography 
                className={clsx(
                  classes.inputCount, 
                  {
                    [classes.error]: (inputCount > MC_MAX_LENGTH_COMMENT_CONTENT)
                  },
                )}
                variant="body1"
              >
                {inputCount} / {MC_MAX_LENGTH_COMMENT_CONTENT}
              </Typography>

            </Grid>
         
            <Grid
              item
              xs={12}
            >
              <span
                ref={emojiRef}
              >
                <Tooltip 
                  title="Add emoji"
                >
                  <IconButton
                    edge="end"
                    onClick={handleEmojiPicker}
                  >
                    <SentimentSatisfiedOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </span>
              <Popper
                anchorEl={emojiRef.current}
                className={classes.emojiPopper}
                open={openEmojiPopover}
                transition
              >
                <ClickAwayListener onClickAway={handleEmojiPopverClose}>
                  <Picker
                    emojiTooltip
                    onSelect={addEmoji}
                    set= "twitter"
                    sheetSize={32}
                    showPreview={false}
                    showSkinTones={false}
                  />
                </ClickAwayListener>
              </Popper>

            </Grid>
          </Grid>
        </div>
      </form>
    </Fragment>
  );
};

EditMcCommentForm.propTypes = {
  comment: PropTypes.object.isRequired,
  inputCount: PropTypes.number,
  setInputCount: PropTypes.func,
};
export default EditMcCommentForm;
