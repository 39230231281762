/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy }  from 'react';
import { Redirect }     from 'react-router-dom';

import PublicLayout     from './layouts/Public';
import StaticLayout     from './layouts/Static';
import AuthLayout       from './layouts/Auth';
import DashboardLayout  from './layouts/Dashboard';

const routes = [
  {
    path: '/schools',
    component: StaticLayout,
    routes: [
      {
        path: '/schools',
        exact: true,
        component: lazy(() => import('views/McSchools'))
      },
      {
        path: '/schools/:countryProvince',
        exact: true,
        component: lazy(() => import('views/McSchoolsCountryProvince'))
      },
      {
        path: '/schools/school/:id/:username',
        exact: true,
        component: lazy(() => import('views/McSchoolProfile'))
      },
      // {
      //   component: () => <Redirect to="/errors/error-404" />
      // }
    ]
  },
  {
    path: '/public',
    component: PublicLayout,
    routes: [
      {
        path: '/public/user/:id/:schoolName',
        exact: true,
        component: lazy(() => import('views/McUserProfile'))
      },
      {
        path: '/public/school/:id/:username',
        exact: true,
        component: lazy(() => import('views/McSchoolProfile'))
      },
      // {
      //   component: () => <Redirect to="/errors/error-404" />
      // }
    ]
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/auto-login',
        exact: true,
        component: lazy(() => import('views/AutoLogin'))
      },
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('views/Register'))
      },
      {
        path: '/auth/register-success',
        exact: true,
        component: lazy(() => import('views/Register/components/RegisterSuccess'))
      },
      {
        path: '/auth/verification-success',
        exact: true,
        component: lazy(() => import('views/Register/components/VerificationSuccess'))
      },
      {
        path: '/auth/pickup-school',
        exact: true,
        component: lazy(() => import('views/Register/components/PickupSchool'))
      },
      {
        path: '/auth/validate-code',
        exact: true,
        component: lazy(() => import('views/Register/components/InputValidateCode'))
      },
      {
        path: '/auth/resend-activation',
        exact: true,
        component: lazy(() => import('views/Register/components/ResendActivationEmailForm'))
      },
      {
        path: '/auth/forget-password',
        exact: true,
        component: lazy(() => import('views/Login/components/ForgetPasswordForm'))
      },
      {
        path: '/auth/forget-password-success',
        exact: true,
        component: lazy(() => import('views/Login/components/ForgetPasswordSuccessForm'))
      },
      {
        path: '/auth/change-password',
        exact: true,
        component: lazy(() => import('views/Login/components/ChangePasswordForm'))
      },
      {
        path: '/auth/update-password-success',
        exact: true,
        component: lazy(() => import('views/Login/components/UpdatePasswordSuccess'))
      },
      // {
      //   path: '/auth/signout',
      //   exact: true,
      //   component: lazy(() => import('views/McAuthSignout'))
      // },
      // {
      //   path: '/auth/reloading',
      //   exact: true,
      //   component: lazy(() => import('views/McCleanReduxStore'))
      // },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: AuthLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/search/:searchScope/:searchType/:keywords',
        exact: true,
        component: lazy(() => import('views/McSearch'))
      },
      {
        path: '/search/:postId',
        exact: true,
        component: lazy(() => import('views/McSinglePost'))
      },
      {
        path: '/',
        exact: true,
        component: lazy(() => import('views/McHome'))
      },

      {
        path: '/posts/:postId',
        exact: true,
        component: lazy(() => import('views/McSinglePost'))
      },
      {
        path: '/shorts/:shortId',
        exact: true,
        component: lazy(() => import('views/McShortPlay'))
      },
      {
        path: '/notifications',
        exact: true,
        component: lazy(() => import('views/McNotification'))
      },
      {
        path: '/account',
        exact: true,
        component: lazy(() => import('views/McSettings'))
      },
      {
        path: '/select-schools',
        exact: true,
        component: lazy(() => import('views/McSelectSchool'))
      },
      {
        path: '/class',
        exact: true,
        component: lazy(() => import('views/McClass'))
      },
      {
        path: '/class/create-class',
        exact: true,
        component: lazy(() => import('views/CreateClass'))
      },
      {
        path: '/class/search-class',
        exact: true,
        component: lazy(() => import('views/SearchClass'))
      },
      {
        path: '/class/edit-class/:id',
        exact: true,
        component: lazy(() => import('views/EditClass'))
      },
      {
        path: '/friends',
        exact: true,
        component: lazy(() => import('views/McFriend'))
      },
      /* {
        path: '/groups',
        exact: true,
        component: lazy(() => import('views/McGroup'))
      }, */
      {
        path: '/profile',
        exact: true,
        component: lazy(() => import('views/McProfile'))
      },
      {
        path: '/profile/:tab',
        exact: true,
        component: lazy(() => import('views/McProfile'))
      },
      {
        path: '/:path',
        exact: true,
        component: lazy(() => import('views/McHome'))
      },
      {
        path: '/:path',
        exact: true,
        component: lazy(() => import('views/McHome'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;
