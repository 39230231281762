import * as actionTypes from './actionTypes';
// import { buildConvoInfo } from '../../utils/ChatUtils';

export const SetChannelsSubcribed = (flag) => ({
  type: actionTypes.SET_CHANNELS_SUBCRIBED,
  areChannelsSubcribed: flag,
});

export const SetChannels = (channels) => ({
  type: actionTypes.SET_CHANNELS,
  channels: channels,
});
export const CleanChatInfos = () => {
  return {
    type: actionTypes.CLEAN_CHAT,
  };
};


//================= [ old ] ==============================
export const AddFriendToChatBar = (friend) => {
  return {
    type: actionTypes.ADD_FRIEND_TO_CHATBAR,
    friend: friend,
  };
};
export const StartConversation = (peers) => dispatch => {
  // const convoInfo = buildConvoInfo(peers);
  // // dispatch( addFriendToChatBar(conversation.otherUser) );
  // dispatch( addNewConversation(convoInfo) );
}

export const UpdateFriendsStatus = (friendsStatus) => {
  return {
    type: actionTypes.UPDATE_FRIENDS_STATUS,
    friendsStatus: friendsStatus,
  };
};
export const AddMsgToPeerConvoInfo = (msg, peerId) => {
  return {
    type: actionTypes.ADD_MSG_TO_PEER_CONVO,
    msg: msg,
    peerId: peerId,
  };
};
export const PopulateUserFriendsToChatBar = (friends, peerIds) => {
  return {
    type: actionTypes.POPULATE_USER_FRIENDS_TO_CHATBAR,
    friends: friends,
    peerIds: peerIds,
  };
};
export const ResetUnreadToZero = (peerId) => {
  return {
    type: actionTypes.RESET_UNREAD_TO_ZERO,
    peerId: peerId,
  };
};
export const RemoveConvoInfo = (peerId) => {
  return {
    type: actionTypes.REMOVE_CONVO_INFO,
    peerId: peerId,
  };
};
export const UpdateMsgImgUrl = (urls, peerId) => {
  return {
    type: actionTypes.UPDATE_MSG_IMG_URL,
    urls: urls,
    peerId: peerId,
  };
};
export const UpdateFailedMsgImgUrl = (uuid, peerId, imgUrl) => {
  return {
    type: actionTypes.UPDATE_FAILED_MSG_IMG_URL,
    uuid: uuid,
    peerId: peerId,
    imgUrl: imgUrl,
  };
};
export const UpdateMsgSentStatus = (uuid, peerId, sentStatus) => {
  return {
    type: actionTypes.UPDATE_MSG_SENT_STATUS,
    uuid: uuid,
    peerId: peerId,
    sentStatus: sentStatus,
  };
};
export const RemoveMsgFromConvo = ( peerId, msgId) => {
  return {
    type: actionTypes.REMOVE_MSG_FROM_PEER_CONVO,
    peerId: peerId,
    msgId: msgId,
  };
};
export const CleanChatConvoMsgs = () => {
  return {
    type: actionTypes.CLEAN_CHAT_CONVO_MSGS,
  };
};
export const RemoveFriendFromChatBar = (friendId) => {
  return {
    type: actionTypes.REMOVE_FRIEND_FROM_CHATBAR,
    peerId: friendId,
  };
};
/*
export const addNewConversation = (convoInfo) => {
  return {
    type: actionTypes.ADD_NEW_CONVERSATION,
    convoInfo: convoInfo,
  };
};
 [ Create new conversation ] 
export const StartConversation = (peers) => (dispatch) => {
  const convoInfo = buildConvoInfo(peers);
  // dispatch( addFriendToChatBar(conversation.otherUser) );
  dispatch(addNewConversation(convoInfo));
};
*/
/* export const AddMsgHistory = (peerId, messages, queryDatetime, skipCount) => {
  return {
    type: actionTypes.ADD_MSG_HISTORY,
    peerId: peerId,
    messages: messages,
    queryDatetime: queryDatetime,
    skipCount: skipCount
  };
} 
export const AddFriendToChatBar = (friend) => {
  return {
    type: actionTypes.ADD_FRIEND_TO_CHATBAR,
    friend: friend,
  };
};
export const RemoveFriendFromChatBar = (friendId) => {
  return {
    type: actionTypes.REMOVE_FRIEND_FROM_CHATBAR,
    peerId: friendId,
  };
};
export const PopulateUserFriendsToChatBar = (friends, peerIds) => {
  return {
    type: actionTypes.POPULATE_USER_FRIENDS_TO_CHATBAR,
    friends: friends,
    peerIds: peerIds,
  };
};

export const UpdateFriendsStatus = (friendsStatus) => {
  return {
    type: actionTypes.UPDATE_FRIENDS_STATUS,
    friendsStatus: friendsStatus,
  };
};

export const AddMsgToPeerConvoInfo = (msg, peerId) => {
  return {
    type: actionTypes.ADD_MSG_TO_PEER_CONVO,
    msg: msg,
    peerId: peerId,
  };
};
export const UpdateMsgImgUrl = (urls, peerId) => {
  return {
    type: actionTypes.UPDATE_MSG_IMG_URL,
    urls: urls,
    peerId: peerId,
  };
};

export const ResetUnreadToZero = (peerId) => {
  return {
    type: actionTypes.RESET_UNREAD_TO_ZERO,
    peerId: peerId,
  };
};
export const UpdateMsgSentStatus = (uuid, peerId, sentStatus) => {
  return {
    type: actionTypes.UPDATE_MSG_SENT_STATUS,
    uuid: uuid,
    peerId: peerId,
    sentStatus: sentStatus,
  };
};
export const RemoveMsgFromConvo = ( peerId, msgId) => {
  return {
    type: actionTypes.REMOVE_MSG_FROM_PEER_CONVO,
    peerId: peerId,
    msgId: msgId,
  };
};
export const RemoveConvoInfo = (peerId) => {
  return {
    type: actionTypes.REMOVE_CONVO_INFO,
    peerId: peerId,
  };
};
export const UpdateFailedMsgImgUrl = (uuid, peerId, imgUrl) => {
  return {
    type: actionTypes.UPDATE_FAILED_MSG_IMG_URL,
    uuid: uuid,
    peerId: peerId,
    imgUrl: imgUrl,
  };
};
export const CleanChatConvoMsgs = () => {
  return {
    type: actionTypes.CLEAN_CHAT_CONVO_MSGS,
  };
};
*/
/* export const FetchChatCategoriesSuccess = (chatCategories) => {
  return {
    type: actionTypes.FETCH_CHAT_CATEGORIES_SUCCESS,
    chatCategories: chatCategories
  };
}
// export const addThread = (channelId, thread) => ({
//   type: actionTypes.ADD_THREAD,
//   channelId: channelId,
//   thread: thread,
// });
*/

// import * as actionTypes from './actionTypes';
// import { buildConvoInfo } from 'utils/ChatUtils';

/* export const FetchChatCategoriesSuccess = (chatCategories) => {
  return {
    type: actionTypes.FETCH_CHAT_CATEGORIES_SUCCESS,
    chatCategories: chatCategories
  };
} 
export const addNewConversation = (convoInfo) => {
  return {
    type: actionTypes.ADD_NEW_CONVERSATION,
    convoInfo: convoInfo
  };
}*/
/* [ Create new conversation ] 
export const StartConversation = (peers) => dispatch => {
  const convoInfo = buildConvoInfo(peers);
  // dispatch( addFriendToChatBar(conversation.otherUser) );
  dispatch( addNewConversation(convoInfo) );
}*/
/* export const AddMsgHistory = (peerId, messages, queryDatetime, skipCount) => {
  return {
    type: actionTypes.ADD_MSG_HISTORY,
    peerId: peerId,
    messages: messages,
    queryDatetime: queryDatetime,
    skipCount: skipCount
  };
} */
// export const AddFriendToChatBar = (friend) => {
//   return {
//     type: actionTypes.ADD_FRIEND_TO_CHATBAR,
//     friend: friend
//   };
// }
// export const RemoveFriendFromChatBar = (friendId) => {
//   return {
//     type: actionTypes.REMOVE_FRIEND_FROM_CHATBAR,
//     peerId: friendId
//   };
// }
// export const PopulateUserFriendsToChatBar = (friends, peerIds) => {
//   return {
//     type: actionTypes.POPULATE_USER_FRIENDS_TO_CHATBAR,
//     friends: friends,
//     peerIds: peerIds
//   };
// }

// export const UpdateFriendsStatus = (friendsStatus) => {
//   return {
//     type: actionTypes.UPDATE_FRIENDS_STATUS,
//     friendsStatus: friendsStatus
//   };
// }

// export const AddMsgToPeerConvoInfo = (msg, peerId) => {
//   return {
//     type: actionTypes.ADD_MSG_TO_PEER_CONVO,
//     msg: msg,
//     peerId: peerId
//   };
// }
// export const UpdateMsgImgUrl = (urls, peerId) => {
//   return {
//     type: actionTypes.UPDATE_MSG_IMG_URL,
//     urls: urls,
//     peerId: peerId
//   };
// }

// export const ResetUnreadToZero = (peerId) => {
//   return {
//     type: actionTypes.RESET_UNREAD_TO_ZERO,
//     peerId: peerId
//   };
// }
// export const UpdateMsgSentStatus = (uuid, peerId, sentStatus) => {
//   return {
//     type: actionTypes.UPDATE_MSG_SENT_STATUS,
//     uuid: uuid,
//     peerId: peerId,
//     sentStatus: sentStatus
//   };
// }
// export const RemoveMsgFromConvo = ( peerId, msgId) => {
//   return {
//     type: actionTypes.REMOVE_MSG_FROM_PEER_CONVO,
//     peerId: peerId,
//     msgId: msgId
//   };
// }
// export const RemoveConvoInfo = (peerId) => {
//   return {
//     type: actionTypes.REMOVE_CONVO_INFO,
//     peerId: peerId,
//   };
// }
// export const UpdateFailedMsgImgUrl = (uuid, peerId, imgUrl) => {
//   return {
//     type: actionTypes.UPDATE_FAILED_MSG_IMG_URL,
//     uuid: uuid,
//     peerId: peerId,
//     imgUrl: imgUrl
//   };
// }
// export const CleanChatConvoMsgs = () => {
//   return {
//     type: actionTypes.CLEAN_CHAT_CONVO_MSGS
//   };
// }