import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.up('md')]: {
      width: '40%',
      margin: theme.spacing(1)
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '70%',
      margin: theme.spacing(1)
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100%',
      maxWidth: '100%',
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {

    },
    width: '100%',
    margin: theme.spacing(1)
  },
  closeButton: {
    color: theme.palette.grey[500],
    padding: 0
  },
  title: {
    height: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    fontSize: '0.8em',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    padding: theme.spacing(0.5, 1),
  },
  actions: {
    backgroundColor: colors.grey[100],
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
    minHeight: '54px'
  },
}));
export default useStyles;