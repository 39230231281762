import * as actionTypes from './actionTypes';

export const DisplaySimpleSnacker = (message, variant) => {
  return {
    type: actionTypes.DISPLAY_SIMPLE_GLOBAL_SNACKER,
    message: message,
    variant:variant
  };
}

export const DisplayAdvancedSnacker =  (message, variant, buttonText, handleClick) => {
  return {
    type: actionTypes.DISPLAY_ADVANCED_GLOBAL_SNACKER,
    message: message,
    variant: variant,
    buttonText: buttonText,
    handleClick:handleClick,
  };
}
export const CloseSnacker = () => {
  return {
    type: actionTypes.CLOSE_SNACKER,
  };
}