/**[ 1xx stands for exception ] **/

export const MC_MAX_DAYS_AGREE_COOKIE               = 365;

export const MC_MAX_DAYS_AUTO_LOGIN_COOKIE          = 3;

export const MC_MAX_LENGTH_POST_CONTENT             = 4000;

export const MC_MAX_LENGTH_COMMENT_CONTENT          = 400;

export const MC_MAX_LENGTH_PEER_CHAT                = 1500;

export const MC_MAX_LENGTH_BLACKBOARD_CONTENT       = 10000;

export const MC_MAX_LENGTH_BIO_CONTENT              = 500;

// hide more
export const MC_DISPLAY_LENGTH_POST_CONTENT         = 500;


export const MC_MAX_COUNT_POST_IMAGE                = 6;

// export const MC_MAX_LENGTH_CHATROOM_CHAT = 4000;


/* When open peer chat box, if message length less this number, it will fetch history messages */
export const MC_PAGE_SIZE_PEER_MESSAGE              = 15;

// 1: open, 2: reported, 3, closed
export const MC_POST_STATUS_OPEN                    = 1;

export const MC_POST_STATUS_REPORTED                = 2;

export const MC_POST_STATUS_CLOSED                  = 3;

//0: pending, 1: approved, 2: denied, 3, removed
// export const MC_JOIN_CLASS_REQUEST_STATUS_PENDING = 0;

// export const MC_JOIN_CLASS_REQUEST_STATUS_APPROVED = 1;

// export const MC_JOIN_CLASS_REQUEST_STATUS_DENIED = 2;

// export const MC_JOIN_CLASS_REQUEST_STATUS_REMOVED = 3;

/* chat message type, for delete,  */
export const MC_CHAT_MSG_TYPE_HISTORY_ZERO                  = 0;

export const MC_CHAT_MSG_TYPE_INSTANT_ONE                   = 1;

export const MC_DIC_MSG_TYPE_TEXT                           = 'TXT';

export const MC_DIC_MSG_TYPE_IMAGE                          = 'IMG';

export const MC_CHAT_SENT_IMAGE_LOADING_PATH            = '/images/gif/imgpreviewing.gif';

export const MC_CHAT_SENT_IMAGE_FAILED_PATH             = '/images/gif/imgfailed.gif';

export const MC_SCHOOL_TYPE_CURRENT_ZERO                = 0;

export const MC_SCHOOL_TYPE_SUBSCRIBE_ONE               = 1;

export const MC_GENDER_FEMALE_TYPE                      = 0;
export const MC_GENDER_FEMALE_LABEL                     = 'Female';

export const MC_GENDER_MALE_TYPE                        = 1;
export const MC_GENDER_MALE_LABEL                       = 'Male';

export const MC_GENDER_OTHER_TYPE                       = 2;
export const MC_GENDER_OTHER_LABEL                      = 'Other';

export const MC_MSTATUS_SINGLE_TYPE                     = 1;
export const MC_MSTATUS_SINGLE_LABEL                    = 'Single';

export const MC_MSTATUS_MARRIED_TYPE                    = 2;
export const MC_MSTATUS_MARRIED_LABEL                   = 'Married';

export const MC_MSTATUS_OTHER_TYPE                      = 3;
export const MC_MSTATUS_OTHER_LABEL                     = 'Other';

export const MC_CLASS_JOINING_STATUS_JOINED             = 1;
export const MC_CLASS_JOINING_STATUS_REMOVED            = 3;

export const MC_CLASS_STATUS_PUBLIC                     = 0;
export const MC_CLASS_STATUS_PRIVATE                    = 1;
export const MC_CLASS_STATUS_BLOCKED                    = 2;

/* like types */
export const MC_TYPE_POST_LIKES                         = 'POST';
export const MC_TYPE_COMMENT_LIKES                      = 'COMMENT';
export const MC_TYPE_REPLY_LIKES                        = 'REPLY';

export const MC_REPORT_ITEM_POST                        = 'R_POST';

export const MC_REPORT_ITEM_COMMENT                     = 'R_COMMENT';

export const MC_REPORT_ITEM_REPLY                       = 'R_REPLY';

export const MC_REPORT_ITEM_USER                        = 'R_USER';

export const MC_SEARCH_TYPE_ALL                         = 'in All';

export const MC_SEARCH_TYPE_USER                        = ' in Users';

export const MC_SEARCH_TYPE_POST                        = ' in Posts';

export const MC_SEARCH_TYPE_USER_ONE                    = 1;

export const MC_SEARCH_TYPE_POST_TWO                    = 2;

// Welcome letter
export const MC_NOTIFICATION_TYPE_WELCOME               = 'WEL';
// user commented on your post
export const MC_NOTIFICATION_TYPE_COMMENT               = 'COM';
// new user joined class
export const MC_NOTIFICATION_TYPE_JOINCLASS             = 'JCL';
// New User joined group
export const MC_NOTIFICATION_TYPE_JOINGROUP             = 'JGR';
// Chat receive messages
export const MC_NOTIFICATION_TYPE_MESSAGE               = 'MSG';
// Chat receive messages
export const MC_NOTIFICATION_TYPE_LIKE                  = 'LIK';

// 'error', 'info', 'success', 'warning'
export const MC_SNACKER_TYPE_ERROR                      = 'error';
export const MC_SNACKER_TYPE_INFO                       = 'info';
export const MC_SNACKER_TYPE_SUCCESS                    = 'success';
export const MC_SNACKER_TYPE_WARNING                    = 'warning';

export const MC_SEARCH_SCOPE_ALL_SCHOOL                 = 0;
export const MC_SEARCH_SCOPE_MY_SCHOOL                  = 1;

export const MC_POST_CONFIRM_TYPE_DELETE                = 0;
export const MC_POST_CONFIRM_TYPE_OPEN                  = 1;
export const MC_POST_CONFIRM_TYPE_REPORT                = 2;
export const MC_POST_CONFIRM_TYPE_CLOSE                 = 3;
