// notification
// Report
export const POST_SUBMIT_REPORT_URL =
  'notification-api/mcnotifications/reports';

// Notification
// export const GET_FETCH_UNREAD_NOTIF_COUNT_URL       = 'notification-api/mcnotifications/notifications/unread-count'

// export const GET_FETCH_POPOVER_NOTIF_URL            = 'notification-api/mcnotifications/notifications'

export const GET_FETCH_NOTIF_URL =
  'notification-api/mcnotifications/notifications'; ///{skip}

export const DELETE_DELETE_NOTIF_URL =
  'notification-api/mcnotifications/notifications';

export const POST_SET_NOTIF_TO_READ_URL =
  'notification-api/mcnotifications/notifications';

export const GET_QUERY_HELPS_URL = 'notification-api/mchelpcenter/help';

// Setting Profile
export const PUT_DELETE_NOTIFICATION_URL =
  'notification-api/mcnotifications/notifications';
