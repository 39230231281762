import React, { useRef, useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import TelegramIcon from '@material-ui/icons/Telegram';
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { pasteEmojiAtCaret } from 'utils/EmojiUtils';
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import Twemoji from 'react-twemoji';

import { MC_MAX_LENGTH_COMMENT_CONTENT } from 'mcconfig'
import { McAvatar, McLoader } from 'components';
import useStyles from './McCommentInput.style';
const McCommentInput = props => {
  const {inputId, replyUsername, 
    submitCommentLoading, onSubmitComment,
    className, ...rest } = props;

  const classes = useStyles();
  const emojiRef = useRef(null);
  const [, setEmoji] = useState({});
  const [openEmojiPopover, setOpenEmojiPopover] = useState(false);
  const [inputCount, setInputCount] = useState(0);
  const {avatarUrl, fullName } = useSelector(state => state.auth.loginUserInfo);

  useEffect(() => {
    let mounted = true;
    if(mounted){
      document.getElementById(inputId).focus();
    }
    return () => { mounted = false }
  }, [])// eslint-disable-line
  useEffect(() => {
    let mounted = true;
    if(mounted){
      if(!_.isEmpty(replyUsername)){
        // setValue(replyUsername + ' ');
        let nameLength = replyUsername.length;
        let width = nameLength * 9.2;
        document.getElementById(inputId).innerHTML 
              = '<input style="width: '+width+'px; border-radius: 5px; border: none; color: #009899; padding: 5px 5px" disabled value="@'+replyUsername+'" />';
              
      }
    }
    return () => { mounted = false }
  }, [replyUsername])// eslint-disable-line

  const handleSubmitComment = () => {
    let msgContent = document.getElementById(inputId).innerHTML;
    /* Replace <br>, prevent empty content */
    var textWithBRReplaced = msgContent.replace(/<br>/gi, ' ');
    /* Replace &nbsp;, prevent empty content */
    var textWithNBSpaceReplaced = msgContent.replace(/&nbsp;/g, ' ');
    /* Handle send text messages */
    if(_.trim(textWithBRReplaced) !== '' && 
                          _.trim(textWithNBSpaceReplaced) !== ''  && 
                                    inputCount <= MC_MAX_LENGTH_COMMENT_CONTENT){
      document.getElementById(inputId).innerHTML = ''
      onSubmitComment(_.trim(textWithNBSpaceReplaced))
    }else{
      document.getElementById(inputId).focus();
    }
  }
  const handleEmojiPopverClose = () => {
    setOpenEmojiPopover(false);
  };
  const addEmoji = (emoji) => {
    setEmoji(emoji)
    document.getElementById(inputId).focus();
    // setDescTitle('Desciption');
    pasteEmojiAtCaret(emoji.native);
    setInputCount(inputCount + 1);
  }
  const handleEmojiPicker = () => {
    if (!openEmojiPopover) {
      setOpenEmojiPopover(true);
    }else {
      setOpenEmojiPopover(false);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if(!event.shiftKey){
        handleSubmitComment();
        event.preventDefault()
      }
    }
  }
  const handleCaculateInputCount = () => {//event, reason
    let div = document.getElementById(inputId);
    if(div != null){
      var textWithNBSpaceReplaced = div.innerHTML.replace(/&nbsp;/g, ' ');
      let len = textWithNBSpaceReplaced.length;
      let mojiObjs = div.getElementsByClassName('twemoji');
      if(mojiObjs != null){
        len = (len - (mojiObjs.length * 106)) + mojiObjs.length;
      }
      setInputCount(len);
    }
  };
  return (
    <Fragment>
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Grid
          container
          spacing={1}
        >
          <Grid
            classes={{
              root: classes.commentInputGrid
            }}
            item
            md={10}
            sm={10}
            xs={12}
          >
            <McAvatar 
              avatarUrl={avatarUrl}
              className={classes.avatar}
              userName={fullName}
            />
            <Paper
              className={classes.paper}
              elevation={1}
            >
              <PerfectScrollbar className={classes.scrollbar}>
                <Twemoji options={{ className: 'twemoji'}}>
                  <div 
                    className={classes.input}
                    contentEditable="true"
                    id={inputId}
                    name="msgContent"
                    onInput={handleCaculateInputCount}
                    onKeyDown={handleKeyDown}
                    placeholder="Type here..."
                    suppressContentEditableWarning
                    title="Shift + Enter to change line, Press Enter to send"
                  />
                </Twemoji>
              </PerfectScrollbar>
            </Paper>
            <Typography 
              className={clsx(
                classes.inputCount, 
                {
                  [classes.error]: (inputCount > MC_MAX_LENGTH_COMMENT_CONTENT)
                },
              )}
              variant="caption"
            >
              {inputCount}/{MC_MAX_LENGTH_COMMENT_CONTENT}
            </Typography>

          </Grid>
          <Grid
            classes={{
              root: classes.commentBtnGrid
            }}
            item
            md={2}
            sm={2}
            xs={12}
          >
            <span ref={emojiRef}>
              <IconButton
                className={classes.operateBtn}
                edge="end"
                onClick={handleEmojiPicker}
              >
                <SentimentSatisfiedOutlinedIcon />
              </IconButton>
            </span>
            <span>
              <IconButton 
                className={classes.operateBtn}
                disabled={submitCommentLoading}
                onClick={handleSubmitComment}
              >
                <McLoader loading={submitCommentLoading} />
                {!submitCommentLoading && <TelegramIcon />}
              </IconButton>
            </span>
          </Grid>
        </Grid>
        
      </div>
      <div>
        <Popper
          anchorEl={emojiRef.current}
          className={classes.emojiPopper}
          open={openEmojiPopover}
          transition
        >
          <ClickAwayListener onClickAway={handleEmojiPopverClose}>
            <Picker
              emojiTooltip
              onSelect={addEmoji}
              set= "twitter"
              sheetSize={32}
              showPreview={false}
              showSkinTones={false}
            />
          </ClickAwayListener>
        </Popper>
      </div>
    </Fragment>
  );
};

McCommentInput.propTypes = {
  className: PropTypes.string,
  inputId: PropTypes.string,
  onSubmitComment: PropTypes.func,
  replyUsername: PropTypes.string,
  submitCommentLoading: PropTypes.bool,
};

export default McCommentInput;