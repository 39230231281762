import React, {useState, 
  Fragment, 
  useEffect}                            from 'react';
import { useSelector,
  useDispatch }                         from 'react-redux';
import useRouter                        from 'utils/useRouter';
import PropTypes                        from 'prop-types';
import _                                from 'lodash';
import urlRegex                         from 'url-regex'

import IconButton                 from '@material-ui/core/IconButton';
import BorderColorOutlinedIcon    from '@material-ui/icons/BorderColorOutlined';
import NativeSelect               from '@material-ui/core/NativeSelect';

import axios                            from 'utils/mcaxios';
import { GET_QUERY_POST_URL,
  PUT_UPDATE_POST_URL }                 from 'store/rest-api';
import { ROUTER_TO_SCHOOLS_PATH,
  ROUTER_TO_HOME_PATH }                 from 'store/routerPath';
import { SUCCESS_FETCH_POST_CODE,
  SUCCESS_UPDATE_POST_CODE }            from 'store/statusCode';
import { MC_SNACKER_TYPE_WARNING,
  MC_SNACKER_TYPE_SUCCESS }             from 'mcconfig';
import {DisplaySimpleSnacker,
  SetFetchMySchoolsPostsFlag }          from 'store/actions';
import {McDialogModal}                  from 'components';
import {EditMcPostForm}                 from './components';
import useStyles                        from './EditMcPostModal.style';

const EditMcPostModal = props => {
  const { open, onClose, postId, schoolId }   = props;
  const classes                               = useStyles();
  const dispatch                              = useDispatch();
    
  const postCategories                  = useSelector(state => state.post.postCategories);
  const schoolsInfo                     = useSelector(state => state.schools.schoolsInfo);
  const [inputCount, setInputCount]     = useState(0);
  const [loading, setLoading]           = useState(false);
  const [imgLoading, setImgLoading]     = useState(false);
  const [post, setPost]                 = useState({});
  const [postImgs, setPostImgs]         = useState();
  const [removedImgIds, setRemovedImgIds]                       = useState([]);
  const [compressedFilesPreview, setCompressedFilesPreview]     = useState([]);
  const [schoolCampuses, setSchoolCampuses]                     = useState()

  /**[ Validate post form ]**/
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  useEffect(() => {
    let mounted = true;
    function fetchPost(){
      setImgLoading(true);
      axios.get(GET_QUERY_POST_URL.concat('/').concat(postId))
        .then(function (response) {
          const {status, postDoc, imgDocs} = response.data;
          if(status === SUCCESS_FETCH_POST_CODE){
            setPost(postDoc);
            if(imgDocs != null && imgDocs.length > 0){
              const imgDocsUpdated = imgDocs.map((imgDoc) => {
                imgDoc.preview = imgDoc.src;
                imgDoc.title = imgDoc.caption;
                return imgDoc;
              })
              setPostImgs([
                ...imgDocsUpdated
              ]);
            }
            setImgLoading(false);
          }
        })
        .catch(function (error) {
          setImgLoading(false);
          console.log(error);
        });
    }
    if(mounted){
      fetchPost();
    }
    return () => { mounted = false }
  }, [postId]) // eslint-disable-line
  useEffect(() => {
    let mounted = true;
    function setDefaultSchoolInfo(){
      const schoolInfo = _.find(schoolsInfo, (school) => {
        return school.id === schoolId;
      });
      if(schoolInfo){
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            school: schoolInfo.id
          },
        }));
        setSchoolCampuses([
          ...schoolInfo.schoolCampuses
        ])
      }
    }
    if(mounted){
      setDefaultSchoolInfo();
    }
    return () => { mounted = false }
  }, [post]) // eslint-disable-line

  /**[ Handle Submit post ]**/
  const handleSubmit = event => {
    event.preventDefault();
    if(_.isEmpty(formState.values.title)){
      document.getElementById('postTitle').focus();
      return;
    }

    let mediaUrl = document.getElementById('mediaUrl');
    let mediaUrlValue = '';
    if(mediaUrl){
      mediaUrlValue = document.getElementById('mediaUrl').value;
      if(!_.isEmpty(mediaUrlValue)){
        if(!urlRegex({exact: true, strict: false}).test(mediaUrlValue)){
          document.getElementById('mediaUrl').focus();
          return;
        }
      }
    }

    let descContent = document.getElementById('descContent').innerHTML;
    var textWithNBSpaceReplaced = descContent.replace(/&nbsp;/g, ' ');
    /* Handle send text messages */
    if(_.trim(textWithNBSpaceReplaced) !== ''&& inputCount <= 4000){

      const data = new FormData()
      for (var x = 0; x < compressedFilesPreview.length; x++) {
        if(compressedFilesPreview[x].file){
          data.append('file', compressedFilesPreview[x].file, compressedFilesPreview[x].file.name)
        }
      }
      data.append('removedImgIds', removedImgIds);
      data.append('schoolId', formState.values.school);
      data.append('title', formState.values.title);
      data.append('postId', post.id);
      data.append('categoryId', formState.values.category);
      data.append('campusId', formState.values.campus);
      data.append('category', getSelectedCategoryText(formState.values.category));
      data.append('campus', getSelectedCampusText(formState.values.campus));
      data.append('school', getSelectedSchoolText(formState.values.school));
      data.append('descContent', _.trim(textWithNBSpaceReplaced))
      data.append('mediaUrl', mediaUrlValue);
      // dispatch(AddPost(data));
      handleUpdatePost(data);
    }else{
      document.getElementById('descContent').focus();
    }
  };
  const handleUpdatePost = (postForm) => {
    setLoading(true)
    axios.put(PUT_UPDATE_POST_URL, postForm)
      .then( response => {
        const {status, message} = response.data;
        if(status === SUCCESS_UPDATE_POST_CODE ){
          setLoading(false);
          dispatch(DisplaySimpleSnacker(message, MC_SNACKER_TYPE_SUCCESS));
          router.history.replace(ROUTER_TO_HOME_PATH);
          dispatch(SetFetchMySchoolsPostsFlag(true));
          onClose();
        }else{
          setLoading(false);
          dispatch(DisplaySimpleSnacker(message, MC_SNACKER_TYPE_WARNING));
        }
      })
      .catch( error => {
        setLoading(false);
        console.log('error ', error);
      });
  }
  const getSelectedCategoryText = (categoryId) => {
    if(_.isEmpty(categoryId) || categoryId === 'undefined' || _.isUndefined(categoryId)){
      return '';
    }
    const category = _.find(postCategories, (category) => {
      return category.id === categoryId;
    });
    return category.name;
  }
  const getSelectedCampusText = (campusId) => {
    // schoolCampuses
    if(_.isEmpty(campusId) || campusId === 'undefined' || _.isUndefined(campusId)){
      return '';
    }
    const campus = _.find(schoolCampuses, (campus) => {
      return campus.id === campusId;
    });
    return campus.name;
  }
  const getSelectedSchoolText = (schoolId) => {
    // school
    if(_.isEmpty(schoolId) || schoolId === 'undefined' || _.isUndefined(schoolId)){
      return '';
    }
    const school = _.find(schoolsInfo, (school) => {
      return school.id === schoolId;
    });
    return school.name;
  }
  const handleRemoveImage = (imgId) => {
    setRemovedImgIds([
      ...removedImgIds,
      imgId
    ])
  }

  const handleChangeSchool = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
    // console.log(formState.values.school)
    // because it cannot populate synchronizely, so just populate manually
    let e = document.getElementById('schoolSelect');
    let schoolId = e.options[e.selectedIndex].value;
    // let schoolName = e.options[e.selectedIndex].text;

    const schoolInfo = _.find(schoolsInfo, (school) => {
      return school.id === schoolId;
    });

    setSchoolCampuses([
      ...schoolInfo.schoolCampuses
    ])
  };
  const router = useRouter();
  const handleAddMoreSchools = () => {
    router.history.push({ 
      pathname:ROUTER_TO_SCHOOLS_PATH, 
      state:{ resend : true } 
    })
  };
  return (
    <Fragment>

      <McDialogModal
        handleSubmit={handleSubmit}
        loading={loading}
        onClose={onClose}
        open={open}
        title="Edit Post"
      >
        <div className={classes.schoolDiv}>
          <div/>
          <NativeSelect
            id="schoolSelect"
            margin="dense"
            name="school"
            onChange={handleChangeSchool}  
            value={formState.values.school || ''}
          >
               
            {schoolsInfo && schoolsInfo.map(schoolInfo => (
              <option 
                key={schoolInfo.id}
                value={schoolInfo.id}
              >
                {schoolInfo.name}
              </option>

            ))}
          </NativeSelect>
          <IconButton 
            className={classes.editBtn} 
            edge="end"
            onClick={handleAddMoreSchools}
            title="Add another school"
          >
            <BorderColorOutlinedIcon />
          </IconButton>
        </div>
        <EditMcPostForm 
          compressedFilesPreview = {compressedFilesPreview}
          formState = {formState}
          imgLoading={imgLoading}
          inputCount={inputCount}
          onRemoveImage = {handleRemoveImage}
          post={post}
          postCategories={postCategories}
          postImgs={postImgs}
          schoolCampuses={schoolCampuses}
          setCompressedFilesPreview = {setCompressedFilesPreview}
          setFormState = {setFormState}
          setInputCount={setInputCount}
        />
        
      </McDialogModal>

    </Fragment>
  );
};

EditMcPostModal.propTypes = {
  onClose:  PropTypes.func,
  open:     PropTypes.bool.isRequired,
  postId:   PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired
};

export default EditMcPostModal;
