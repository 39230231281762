import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
const useStyles = makeStyles(() => ({
  root: {
    width: 350,
    maxWidth: '100%',
  },
  actions: {
    backgroundColor: colors.grey[50],
    justifyContent: 'center'
  },
  menu:{
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0
    }
  }
}));

export default useStyles;