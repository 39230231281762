import { combineReducers } from 'redux';

// import sessionReducer from './sessionReducer';
import authReducer from './authReducer';
import postReducer from './postReducer';
import schoolsReducer from './schoolsReducer';
import chatReducer from './chatReducer';
import chatroomReducer from './chatroomReducer';
import snackerReducer from './snackerReducer';
const rootReducer = combineReducers({
  // session: sessionReducer,
  auth: authReducer,
  post: postReducer,
  schools: schoolsReducer,
  chat: chatReducer,
  chatroom: chatroomReducer,
  snacker: snackerReducer,
});

export default rootReducer;
