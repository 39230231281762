import React, { 
  useState, 
  useRef, 
  useEffect, 
  Fragment }        from 'react';
import { 
  useDispatch }     from 'react-redux';
import PropTypes    from 'prop-types';
import _            from 'lodash';
import validate     from 'validate.js';
import clsx         from 'clsx';
import ReactPlayer  from 'react-player'
import urlRegex     from 'url-regex'

import MenuItem           from '@material-ui/core/MenuItem';
import ListItemIcon       from '@material-ui/core/ListItemIcon';
import ListItemText       from '@material-ui/core/ListItemText';
import Divider            from '@material-ui/core/Divider';
import Tooltip            from '@material-ui/core/Tooltip';
import Popper             from '@material-ui/core/Popper';
import ClickAwayListener  from '@material-ui/core/ClickAwayListener';
import Grid               from '@material-ui/core/Grid';
import GridList           from '@material-ui/core/GridList';
import GridListTile       from '@material-ui/core/GridListTile';
import IconButton         from '@material-ui/core/IconButton';
import AddPhotoIcon       from '@material-ui/icons/AddPhotoAlternate';
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import GridListTileBar    from '@material-ui/core/GridListTileBar';
import HighlightOffIcon   from '@material-ui/icons/HighlightOff';
import LinkOutlinedIcon   from '@material-ui/icons/LinkOutlined';
import HighlightOffOutlinedIcon       from '@material-ui/icons/HighlightOffOutlined';
import Typography         from '@material-ui/core/Typography';

import { pasteEmojiAtCaret }    from 'utils/EmojiUtils';
import 'emoji-mart/css/emoji-mart.css'
import { Picker }               from 'emoji-mart'
import Twemoji                  from 'react-twemoji';
import './AddMcPostForm.css';
import { 
  maxSelectFile, 
  checkFileSize, 
  checkMimeType,
  compressImage,
  loadingObj, }                 from 'utils/ImageUtils';
import { 
  McCategoryIcon, 
  McTextFieldUnderlined,
  McTextField, 
  McLoader}                     from 'components';
import AddMcPostFormSchema      from './AddMcPostForm.schema';
import useStyles                from './AddMcPostForm.style';
import { 
  MC_MAX_LENGTH_POST_CONTENT,
  MC_SNACKER_TYPE_WARNING, 
  MC_MAX_COUNT_POST_IMAGE }     from 'mcconfig';
import { 
  DisplaySimpleSnacker }        from 'store/actions';
let compressedFiles = [];
let previewFiles = [];

const AddMcPostForm = (props) => {
  const { 
    formState, setFormState,  
    inputCount, setInputCount,
    compressedFilesPreview, setCompressedFilesPreview,
    postCategories, schoolCampuses } = props;
  const classes = useStyles();
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  // const [openSnacker, setOpenSnacker] = useState(false);
  // const [snackerMessage, setSnackerMessage] = useState('');
  // const [snackerVariant] = useState('warning');
  const [previewFilesLoading, setPreviewFilesLoading] = useState([]);

  useEffect(() => {
    let mounted = true;
    if(mounted){
      const errors = validate(formState.values, AddMcPostFormSchema);
      setFormState(formState => ({
        ...formState,
        isValid: errors ? false : true,
        errors: errors || {}
      }));
    }
    return () => { mounted = false }
  }, [formState.values]); // eslint-disable-line
  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
  /**[ Remove preview images when popup, (keep the images may be later) ]**/
  useEffect(() => {
    return () => {
      compressedFiles = [];
      setCompressedFilesPreview(compressedFiles);
      previewFiles = [];
      setPreviewFilesLoading(previewFiles);
    }
  }, []) // eslint-disable-line
  /**[ Insert emoji into description text ]**/
  const emojiRef = useRef(null);
  const [, setEmoji] = useState({});
  // const [descTitle, setDescTitle] = useState('Desciption');
  const [openEmojiPopover, setOpenEmojiPopover] = useState(false);
  const handleEmojiPopverClose = () => {
    setOpenEmojiPopover(false);
  };
  const handleEmojiPicker = () => {
    if (!openEmojiPopover) {
      setOpenEmojiPopover(true);
    }else {
      setOpenEmojiPopover(false);
    }
  };
  const addEmoji = (emoji) => {
    setEmoji(emoji)
    document.getElementById('descContent').focus();
    // setDescTitle('Desciption');
    pasteEmojiAtCaret(emoji.native);
    setInputCount(inputCount + 1);
  }

  /**[ Preview and Compress Images ]**/
  const onChangeHandler = async event => {
    event.persist();
    //Check eligibiltiy of images
    //1, only 6 can be selected. 
    const mresult = maxSelectFile(event, compressedFiles.length, MC_MAX_COUNT_POST_IMAGE);
    if(!mresult.isLegal){
      // setSnackerMessage(mresult.msg);
      // setOpenSnacker(true);
      dispatch(DisplaySimpleSnacker(mresult.msg, MC_SNACKER_TYPE_WARNING));
      return;
    }
    //2, max 15MB for a single images
    const fresult = checkFileSize(event);
    if(!fresult.isLegal){
      // setSnackerMessage(fresult.msg);
      // setOpenSnacker(true);
      dispatch(DisplaySimpleSnacker(fresult.msg, MC_SNACKER_TYPE_WARNING));
      return;
    }
    //3, only support ['image/png', 'image/jpeg', 'image/gif']
    const cresult = checkMimeType(event);
    if(!cresult.isLegal){
      // setSnackerMessage(cresult.msg);
      // setOpenSnacker(true);
      dispatch(DisplaySimpleSnacker(cresult.msg, MC_SNACKER_TYPE_WARNING));
      return;
    }
    var files = event.target.files;
    //Show loading
    previewFiles = [];
    for (var y = 0; y < files.length; y++) {
      previewFiles.push(loadingObj);
    }
    setPreviewFilesLoading(previewFiles);

    //Compress 
    let hasIllegalImage = false;
    for (var x = 0; x < files.length; x++) {
      let fileObj = await compressImage(x, files[x]);
      if(fileObj == null){
        hasIllegalImage = true;
        break;
      }
      compressedFiles.push(fileObj);
    }
    //
    if(hasIllegalImage){
      // setSnackerMessage('Illegal image is selected.');
      // setOpenSnacker(true);
      dispatch(DisplaySimpleSnacker('Illegal image is selected.', MC_SNACKER_TYPE_WARNING));
    }
    setCompressedFilesPreview([]);
    setCompressedFilesPreview(compressedFiles);
    // eslint-disable-next-line require-atomic-updates
    previewFiles = [];
    setPreviewFilesLoading(previewFiles);
  }
  /**[ Remove Preview ]**/
  const onRemovePreview = (index) => {
    compressedFiles = _.remove(compressedFiles, (file, i)=>{
      return index !== i;
    });
    setCompressedFilesPreview([]);
    setCompressedFilesPreview(compressedFiles);

    // Set files  = null, if preview removed, it can add back, 
    // otherwise the file still exist in files. can not add back.
    fileInputRef.current.files = null;
    fileInputRef.current.value = null;
  }
  const handleAttach = () => {
    fileInputRef.current.click();
  };
  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
    // set icon and text in one line, FIXME
    document.getElementById('select-category').style.display = 'flex';
  };
  
  // const handleCloseSnacker = () => {//event, reason
  //   setOpenSnacker(false);
  // };
  const handleCaculateInputCount = () => {//event, reason
    let div = document.getElementById('descContent');
    if(div != null){
      var textWithNBSpaceReplaced = div.innerHTML.replace(/&nbsp;/g, ' ');
      let len = textWithNBSpaceReplaced.length;
      let mojiObjs = div.getElementsByClassName('twemoji');
      if(mojiObjs != null){
        len = (len - (mojiObjs.length * 106)) + mojiObjs.length;
      }
      setInputCount(len);
    }
  };

  /* For Insert Link */
  const [mediaUrl, setMediaUrl] = useState('');
  const [displayMediaUrlInput, setDisplayMediaUrlInput] = useState(false);
  const [loadingReactplayer, setLoadingReactplayer] = useState(false)
  const [reactPlayerErrorMsg, setReactPlayerErrorMsg] = useState('')

  const handleDisplayMediaUrlInput = () => {
    setDisplayMediaUrlInput(true)
  }
  const handleMediaUrlChange = (event) => {
    if(_.isEmpty(compressedFilesPreview)){
      let res = ReactPlayer.canPlay(event.target.value)
      if(res){
        setReactPlayerErrorMsg('')
        setLoadingReactplayer(true)
      }else{
        let isUrl = urlRegex({exact: true, strict: false}).test(event.target.value);
        if(isUrl){
          setReactPlayerErrorMsg('The media is not available.')
        }else{
          setReactPlayerErrorMsg('The url is not valid.')
        }
      }
    }
    setMediaUrl(event.target.value)
  }
  const handleRemoveMediaUrlInput = () => {
    setDisplayMediaUrlInput(false)
    setLoadingReactplayer(false)
    setMediaUrl('')
    setReactPlayerErrorMsg('')
  }

  const handleReactPlayerReady = () => {
    setLoadingReactplayer(false)
  }
  const handleReactPlayerError = () => {
    setLoadingReactplayer(false)
  }
  return (
    <Fragment>

      <form >
        <div className={classes.content}>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
            >
              <McTextFieldUnderlined
                error={hasError('title')}
                fullWidth
                helperText={hasError('title') ? formState.errors.title[0] : null}
                id="postTitle"
                label="Title"
                margin="dense"
                name="title"
                onChange={handleChange}
                required
                value={formState.values.title || ''}
              />
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
            >
              <McTextFieldUnderlined
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },            
                  classes: {
                    selectMenu: classes.menu
                  }
                }}

                error={hasError('category')}
                fullWidth
                helperText={hasError('category') ? formState.errors.category[0] : null}
                id="categorySelect"
                label="Select Category"
                margin="dense"
                name="category"
                onChange={handleChange}
                select
                value={formState.values.category || ''}
              >
                {postCategories && postCategories.map(category => (
                  <MenuItem
                    className={classes.menuItem}
                    key={category.id}
                    value={category.id}
                  >
                    <ListItemIcon>
                      <McCategoryIcon name={category.name}/>
                    </ListItemIcon>
                    <ListItemText primary={category.name}/>
                  </MenuItem>
                ))}
              </McTextFieldUnderlined>
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
            >
              <McTextFieldUnderlined
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },            
                  classes: {
                    selectMenu: classes.menu
                  }
                }}
                error={hasError('campus')}
                fullWidth
                helperText={hasError('campus') ? formState.errors.campus[0] : null}
                id="campusSelect"
                label="Campus"
                margin="dense"
                name="campus"
                onChange={handleChange}
                select  
                value={formState.values.campus || ''}
              >
               
                {schoolCampuses && schoolCampuses.map(campus => (
                  <MenuItem 
                    className={classes.menuItem}
                    key={campus.id}
                    value={campus.id}
                  >
                    <ListItemText primary={campus.name}/>
                  </MenuItem >
                ))}
              </McTextFieldUnderlined>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Twemoji options={{ className: 'twemoji'}}>
                <div 
                  className={classes.desc}
                  contentEditable="true"
                  id="descContent"
                  name="descContent"
                  onInput={handleCaculateInputCount}
                  placeholder="description*"
                  suppressContentEditableWarning
                />
              </Twemoji>
              <Typography 
                className={clsx(
                  classes.inputCount, 
                  {
                    [classes.error]: (inputCount > MC_MAX_LENGTH_POST_CONTENT)
                  },
                )}
                variant="body1"
              >
                {inputCount} / {MC_MAX_LENGTH_POST_CONTENT}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <div className={classes.insertMediaUrlDiv}>
                {
                  displayMediaUrlInput
                  &&
                  <div className={classes.insertMediaUrlField}>
                    <McTextField
                      error={null}
                      fullWidth
                      helperText={null}
                      id="mediaUrl"
                      label="Media Url"
                      margin="dense"
                      name="mediaUrl"
                      onChange={handleMediaUrlChange}
                      placeholder="paste media url, youtube, facebook, vimeo, twitch... "
                      value={mediaUrl}
                      variant="outlined"
                    />
                    <IconButton 
                      className={classes.removeMediaUrlIcon}
                      onClick={handleRemoveMediaUrlInput}
                    >
                      <HighlightOffOutlinedIcon 
                        className={classes.iconHover}
                        classes={{
                          root: classes.removeMediaUrlIconRoot
                        }}
                      />
                    </IconButton>
                  </div>
                }

                <div className={classes.reactPlayerDiv}>
                  <Typography
                    variant="h5"
                  >
                    {reactPlayerErrorMsg}
                  </Typography>
                   
                  <McLoader 
                    className={classes.loadingPosts}
                    loading={loadingReactplayer} 
                    size={28}
                    thickness={4}
                    type="query"
                  />
                  {
                    ReactPlayer.canPlay(mediaUrl)
                    &&
                    _.isEmpty(compressedFilesPreview)
                    &&
                    <ReactPlayer 
                      height="auto"
                      onError={handleReactPlayerError}
                      onReady={handleReactPlayerReady}
                      url={mediaUrl} 
                      width="auto"
                    />
                  }
                </div>
              </div>
              <div className={classes.imageGallery}>
                <GridList 
                  cellHeight={140} 
                  className={classes.gridList} 
                  cols={3}
                >
                      
                  {compressedFilesPreview.map((file, index) => (
                    <GridListTile 
                      cols={1}
                      key={file.preview} 
                    >
                      <img 
                        alt={file.title} 
                        src={file.preview} 
                        title={file.title} 
                      />
                          
                      <GridListTileBar
                        actionIcon={
                          <IconButton 
                            aria-label={`star ${file.title}`} 
                            className={classes.closeButtonIcon}
                            name={index} 
                            onClick={() => onRemovePreview(index)}
                            style={{ padding: '8px' }}
                          >
                            <HighlightOffIcon 
                              className={classes.iconHover}
                              classes={{
                                root: classes.closeButtonIconRoot
                              }}
                            />
                          </IconButton>
                        }
                        className={classes.imageTitleBar}
                        style={{ height: '36px' }}
                      />
                    </GridListTile>
                  ))}
                  {previewFilesLoading.map((loadingObj) => (
                    <GridListTile 
                      cols={1}
                      key={Math.random()} 
                    >
                      <div>
                        {/* <McWaitingIcon 
                          alt={loadingObj.title} 
                          className={classes.waitingIcon}
                          loading
                          width="38"
                        /> */}
                        <img 
                          alt=""
                          className={classes.loadingPreview}
                          src={loadingObj.src}
                        />
                      </div>
                    </GridListTile>
                  ))}
                </GridList>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Divider />
              <Tooltip title="Attach max 6 images(jpg, png, gif).">
                <IconButton
                  onClick={handleAttach}
                >
                  <AddPhotoIcon/>
                </IconButton>
              </Tooltip>
              <Tooltip title="Insert media url">
                <IconButton
                  onClick={handleDisplayMediaUrlInput}
                >
                  <LinkOutlinedIcon/>
                </IconButton>
              </Tooltip>
              <span
                ref={emojiRef}
              >
                <Tooltip 
                  title="Add emoji"
                >
                  <IconButton
                    onClick={handleEmojiPicker}
                  >
                    <SentimentSatisfiedOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </span>
              <Popper
                anchorEl={emojiRef.current}
                className={classes.emojiPopper}
                open={openEmojiPopover}
                transition
              >
                <ClickAwayListener onClickAway={handleEmojiPopverClose}>
                  <Picker
                    emojiTooltip
                    onSelect={addEmoji}
                    set= "twitter"
                    sheetSize={32}
                    showPreview={false}
                    showSkinTones={false}
                  />
                </ClickAwayListener>
              </Popper>
              <input
                accept="image/x-png,image/gif,image/jpeg"
                className={classes.fileInput}
                multiple 
                onChange={onChangeHandler}
                ref={fileInputRef}
                type="file"
              />
            </Grid>
          </Grid>
        </div>
      </form>
      {/* <McSnackbar 
        handleClose={handleCloseSnacker}
        message={snackerMessage}
        open={openSnacker}
        variant={snackerVariant}
      /> */}
          
    </Fragment>
  );
};

AddMcPostForm.propTypes = {
  compressedFilesPreview: PropTypes.array,
  formState: PropTypes.object.isRequired,
  inputCount: PropTypes.number,
  postCategories: PropTypes.array,
  schoolCampuses: PropTypes.array,
  setCompressedFilesPreview: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
  setInputCount: PropTypes.func,
};
export default AddMcPostForm;
