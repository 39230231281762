import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(1),
  },
  authUser: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  inner: {
    display: 'flex',
    maxWidth: 500
  },
  notAuthUser: {
    justifyContent: 'flex-end',
  },
  avatarPartner: {
    marginRight: theme.spacing(0.5)
  },
  avatarMe: {
    // marginLeft: theme.spacing(0.5)
  },
  body: {
    backgroundColor: colors.grey[200],
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    padding: '4px'
  },
  content: {
    display: 'flex',
    '&:hover': {
      '& $msgMoreOptions': {
        visibility: 'visible'
      },
    }
  },
  imageDiv: {
    display: 'block',
    minHeight: '1px',
    width: '100%',
    overflow: 'hidden',
    '&:hover': {
      cursor: 'pointer'
    },
  },
  imagePreview: {
    height: 'unset',
    width: 110,
    maxWidth: '100%',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid silver',
  },
  imagePreviewBg: {
    backgroundColor: 'white',
  },
  authUserBg: {
    backgroundColor: '#32b6b7',
    color: theme.palette.primary.contrastText
  },
  pieceMsg: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    maxWidth: '220px',
    fontSize: '15px',
    padding: '4px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    '& .twemoji': {
      width: '22px',
      marginLeft: '1px',
      marginRight: '1px',
      verticalAlign: 'middle',
    }
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  authUserMsg: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  notAuthUserMsg: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  msgMoreOptions: {
    visibility: 'hidden',
    width: theme.spacing(1.5),
    height: theme.spacing(3)
  }
}));

export default useStyles;