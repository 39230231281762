import * as actionTypes from 'store/actions/actionTypes'
import _ from 'lodash';
import { buildClassConvoInfo } from 'utils/ChatUtils';
const initialState = {
  clazzes: [],
  classMates: {},
  multiClassConvoInfo: [],
  classBar: {
    open: false,
    classId: '',
    populatedClassmates: ''
  },
  lastReceivedAt: null, // for when get new message scroll to bottom of chat box
  deletedClassId: null
};
const chatroomReducer = (state = initialState, action) => {
  switch (action.type) {
    /* [ 6 ], Populating my Clazzes */
    case actionTypes.POPULATE_MYCLAZZES_TO_CHATBAR: {
      return {
        ...state,
        clazzes: action.clazzes
      };
    }
    /* [ 6 ], Populating my Clazzes */
    case actionTypes.ADD_MYCLAZZ_TO_CHATBAR: {
      return {
        ...state,
        clazzes: [
          ...state.clazzes,
          action.clazz
        ]
      };
    }
    /* [ 6 ], Populating my Clazzes */
    case actionTypes.REMOVE_MYCLAZZ_FROM_CHATBAR: {
      const filteredClazzes = 
      _.filter(state.clazzes, function(clazz) { return clazz.classId !== action.classId; });
      return {
        ...state,
        clazzes: [
          ...filteredClazzes
        ]
      };
    }
    case actionTypes.ADD_NEW_CLASS_CONVERSATION: {

      /* If conversation exists, just change currentConvo */
      const matchedClassConvoInfo = _.find(state.multiClassConvoInfo, (classConvoInfo) => {
        return action.classConvoInfo.classId === classConvoInfo.classId;
      });

      if(!matchedClassConvoInfo){
        return {
          ...state,
          multiClassConvoInfo: [
            action.classConvoInfo,
            ...state.multiClassConvoInfo,
          ],
          currentClassConvoClassId: action.classConvoInfo.classId
        };
      }else{
        // If closed before, just reopen it
        const multiClassConvoInfoUpdated = state.multiClassConvoInfo.map((classConvoInfo) => {
          if(classConvoInfo.classId === action.classConvoInfo.classId){
            classConvoInfo.closed = false;
          }
          return classConvoInfo;
        })
        return {
          ...state,
          multiClassConvoInfo: [
            ...multiClassConvoInfoUpdated
          ],
          currentClassConvoClassId: action.classConvoInfo.classId
        };
      }
    }
    case actionTypes.POPULATE_CLASS_CLASSMATES: {
      return {
        ...state,
        classMates: {
          ...state.classMates,
          [action.classId]: [...action.classMates]
        },
        classBar: {
          ...state.classBar,
          loadedClassId: action.classId
        }
      };
    }
    case actionTypes.CLOSE_CLASS_CONVERSATION: {
      const multiClassConvoInfoUpdated = state.multiClassConvoInfo.map((classConvoInfo) => {
        if(classConvoInfo.classId === action.classId){
          classConvoInfo.closed = true;
        }
        return classConvoInfo;
      })
      return {
        ...state,
        multiClassConvoInfo: [
          ...multiClassConvoInfoUpdated
        ],
      }
    }    
    case actionTypes.UPDATE_CHATROOM_ONLINE_MEMBERS: {
      if(state.classMates[action.classId]){
        const clazzMatesUpdated = state.classMates[action.classId].map((classMate) => {
          _.forEach(action.memberIds, function(memberId) {
            if(classMate.userId === memberId){
              classMate.active = 'ONLINE'
            }
          });
          return classMate;
        })
        return {
          ...state,
          classMates: {
            ...state.classMates,
            [action.classId]: [...clazzMatesUpdated]
          },
        }
      }
      return {
        ...state,
      }
    }

    case actionTypes.CHATROOM_MEMBER_JOINED: {
      if(state.classMates[action.classId]){
        const clazzMatesUpdated = state.classMates[action.classId].map((classMate) => {
          if(classMate.userId === action.memberId){
            classMate.active = 'ONLINE'
          }
          return classMate;
        })
        return {
          ...state,
          classMates: {
            ...state.classMates,
            [action.classId]: [...clazzMatesUpdated]
          },
        }
      }
      return {
        ...state,
      }
    }
    case actionTypes.CHATROOM_MEMBER_LEFT: {
      if(state.classMates[action.classId]){
        const clazzMatesUpdated = state.classMates[action.classId].map((classMate) => {
          if(classMate.userId === action.memberId){
            classMate.active = 'OFFLINE'
          }
          return classMate;
        })
        return {
          ...state,
          classMates: {
            ...state.classMates,
            [action.classId]: [...clazzMatesUpdated]
          },
        }
      }
      return {
        ...state,
      }
    }
    case actionTypes.ADD_UNREAD_MSG_TO_CLASS_CONVO: {
      /* If conversation exists,  */
      const matchedClassConvoInfo = _.find(state.multiClassConvoInfo, (classConvoInfo) => {
        return classConvoInfo.classId === action.classId;
      });

      if(!matchedClassConvoInfo){
        const clazz = _.find( state.clazzes, (clazz) => {
          return clazz.classId === action.classId;
        });

        const newClassConvoInfo = buildClassConvoInfo(action.classId, clazz.programName);

        newClassConvoInfo.messages.push(action.msg);
        newClassConvoInfo.unread = action.unreadCount;
        return {
          ...state, 
          multiClassConvoInfo: [
            newClassConvoInfo,
            ...state.multiClassConvoInfo
          ],
          lastReceivedAt: new Date()
        }
      }else{

        const multiClassConvoInfoUpdated = state.multiClassConvoInfo.map((classConvoInfo) => {
          if(classConvoInfo.classId === action.classId){
            classConvoInfo.messages.push(action.msg);
            classConvoInfo.closed = false;
            classConvoInfo.unread = action.unreadCount;
          }
          return classConvoInfo;
        })
        return {
          ...state,        
          multiClassConvoInfo: [
            ...multiClassConvoInfoUpdated
          ],
          lastReceivedAt: new Date()
        }
      }

    }
    case actionTypes.ADD_MSG_TO_CLASS_CONVO: {
      /* If conversation exists,  */
      const matchedClassConvoInfo = _.find(state.multiClassConvoInfo, (classConvoInfo) => {
        return classConvoInfo.classId === action.classId;
      });

      if(!matchedClassConvoInfo){
        const clazz = _.find( state.clazzes, (clazz) => {
          return clazz.classId === action.classId;
        });

        const newClassConvoInfo = buildClassConvoInfo(action.classId, clazz.programName);

        newClassConvoInfo.messages.push(action.msg);
        newClassConvoInfo.unread = 1;
        return {
          ...state, 
          multiClassConvoInfo: [
            newClassConvoInfo,
            ...state.multiClassConvoInfo
          ],
          lastReceivedAt: new Date()
        }
      }else{

        const multiClassConvoInfoUpdated = state.multiClassConvoInfo.map((classConvoInfo) => {
          if(classConvoInfo.classId === action.classId){

            const matchedClassMsg = _.find(classConvoInfo.messages, (msg) => {
              return msg.msgId === action.msg.msgId;
            });
            if(_.isEmpty(matchedClassMsg)){
              classConvoInfo.messages.push(action.msg);
              classConvoInfo.closed = false;
  
              if(!action.senderFlag){
                classConvoInfo.unread += 1;
              }else{
                classConvoInfo.unread = 0;
              }
            }
          }
          return classConvoInfo;
        })
        return {
          ...state,        
          multiClassConvoInfo: [
            ...multiClassConvoInfoUpdated
          ],
          lastReceivedAt: new Date()
        }
      }

    }
    case actionTypes.RESET_CLASS_UNREAD_MSGS_TO_ZERO: {
      const multiClassConvoInfoUpdated = state.multiClassConvoInfo.map((classConvoInfo) => {
        if(classConvoInfo.classId === action.classId){
          classConvoInfo.unread = 0;
        }
        return classConvoInfo;
      })
      return {
        ...state,        
        multiClassConvoInfo: [
          ...multiClassConvoInfoUpdated
        ],
      }
    }
    case actionTypes.OPEN_CLASS_BAR: {

      return {
        ...state,        
        classBar: {
          open: true,
          classId: action.classId
        }
      }
    }
    case actionTypes.CLOSE_CLASS_BAR: {

      return {
        ...state,        
        classBar: {
          ...state.classBar,
          open: false,
        }
      }
    }
    case actionTypes.UPDATE_CLASS_MSG_IMG_URL: {
      const multiClassConvoInfoUpdated = state.multiClassConvoInfo.map((classConvoInfo) => {
        if(classConvoInfo.classId === action.classId){
          classConvoInfo.messages.map((msg) => {
            if(msg.msgId === action.uuid){
              msg.content = action.url
              msg.sentStatus = true
            }
            return msg;
          })
        }
        return classConvoInfo;
      })

      return {
        ...state,
        multiClassConvoInfo: [
          ...multiClassConvoInfoUpdated
        ],
        lastReceivedAt: new Date()
      }
    }    
    case actionTypes.UPDATE_FAILED_CLASS_MSG_IMG_URL: {
      const multiClassConvoInfoUpdated = state.multiClassConvoInfo.map((classConvoInfo) => {
        if(classConvoInfo.classId === action.classId){
          classConvoInfo.messages.map((msg) => {

            if(msg.msgId === action.uuid){
              msg.content =action.imgUrl
            }
            return msg;
          })
        }
        return classConvoInfo;
      })
      return {
        ...state,
        multiClassConvoInfo: [
          ...multiClassConvoInfoUpdated
        ],
        lastReceivedAt: new Date()
      }
    }    
    case actionTypes.CLEAN_CLASS_CONVO_MSGS: {

      return {
        clazzes: [],
        classMates: {},
        multiClassConvoInfo: [],
        classBar: {
          open: false,
          classId: '',
          populatedClassmates: ''
        },
        lastReceivedAt: null,
        deletedClassId: null
      }
    }    
    case actionTypes.REMOVE_MSG_FROM_CLASS_CONVO: {
      const multiClassConvoInfoUpdated = state.multiClassConvoInfo.map((classConvoInfo) => {
        if(classConvoInfo.classId === action.classId){
          const filteredMsgs = 
            _.filter(classConvoInfo.messages, function(message) { return message.msgId !== action.msgId; });
          classConvoInfo.messages = [...filteredMsgs];
        }
        return classConvoInfo;
      })
      return {
        ...state,        
        multiClassConvoInfo: [
          ...multiClassConvoInfoUpdated
        ]
      }

    }
    case actionTypes.REMOVE_CLASS_CONVERSATION: {
      
      const filteredMultiClassConvoInfo = _.filter(state.multiClassConvoInfo, 
        function(classConvoInfo) { return classConvoInfo.classId !== action.classId; });
      return {
        ...state,
        multiClassConvoInfo: [
          ...filteredMultiClassConvoInfo
        ],
        deletedClassId: action.classId
      }
    }  
    default: {
      return state;
    }
  }
}

export default chatroomReducer