import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { McPostCard, McButtonText, McIcon, } from 'components';
import useStyles from './McPostCardView.style'

const McPostCardView = props => {
  const { postVo, onGoBack } = props;
  const classes = useStyles();
 
  return (
    <Fragment>
      <McButtonText
        className={classes.backButton}
        color="default"
        onClick={onGoBack}
      >
        <McIcon 
          display
          name="back"
        />
                    Back
      </McButtonText>
      <McPostCard
        className={classes.post}
        key={postVo.id}
        post={postVo}
        readOnly
      />
    </Fragment>
  );
};

McPostCardView.propTypes = {
  onGoBack: PropTypes.func,
  postVo: PropTypes.object,
};

export default McPostCardView;
