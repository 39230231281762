import React, { Fragment, useEffect }                 from 'react';
import { useSelector }                                from 'react-redux';
import useRouter                                      from 'utils/useRouter';
import PropTypes                                      from 'prop-types';
import PerfectScrollbar                               from 'react-perfect-scrollbar';
import clsx                                           from 'clsx';
import _                                              from 'lodash';

import Divider                        from '@material-ui/core/Divider';
import Paper                          from '@material-ui/core/Paper';
import Hidden                         from '@material-ui/core/Hidden';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';

import { Navigation, McAvatarTxt }           from 'components';
import navigationConfig                                                       from './navigationConfig';
import { ROUTER_TO_PUBLIC_SCHOOLPROFILE_PATH }                                                      from 'store/routerPath';
import useStyles                                                              from './NavBar.style';

const NavBar = props => {
  const { openMobile, onMobileClose, className, ...rest } = props;
  const classes = useStyles();
  const router = useRouter();
  const schoolInfos = useSelector(state => state.schools.schoolsInfo);

  useEffect(() => {
    let mounted = true;
    if(mounted){
      if (openMobile) {
        onMobileClose && onMobileClose();
      }
    }
    return () => {mounted = false;}
  }, [router.location.pathname]);// eslint-disable-line

  const handleGotoSchoolPage = (name, schoolId) => () => {
    let schoolName = _.replace(name, ' ', '-');
    router.history.push({ 
      pathname:ROUTER_TO_PUBLIC_SCHOOLPROFILE_PATH + '/' + schoolId + '/' + schoolName, 
      state:{ legal : true } 
    })
  };

  const navbarContent = (
    <PerfectScrollbar>
      <Box
        sx={{
          bgcolor: '#FFFFFF',
          display: {  lg: 'flex' },
          flexDirection: 'column',
          height: '100%',
          left: 0,
          position: 'fixed',
        }}
      >
        {
         
          <div className={classes.content}>
            {/* Loop joined schools */}
            {
              !_.isEmpty(schoolInfos) 
              && 
              <div className={classes.schoolIconsDiv}>
                {
                  schoolInfos.map((schoolInfo) => {
                    return (
                      <McAvatarTxt 
                        className={classes.schoolAvatar}
                        key={schoolInfo.schoolId}
                        onClick={handleGotoSchoolPage(schoolInfo.name, schoolInfo.id)}
                        round
                        title={schoolInfo.name}
                        userName={schoolInfo.name}
                      />
                    )
                  })
                }
              </div>
            }
            {/* user profile */}
            
            <Divider className={classes.divider} />
            <nav className={classes.navigation}>
              {navigationConfig.map(list => (
                <Navigation
                  component="div"
                  key={list.title}
                  pages={list.pages}
                  title={list.title}
                />
              ))}
            </nav>
          </div>
        }
      </Box>
      <Divider className={classes.divider} />
    </PerfectScrollbar>
  );

  return (
    <Fragment>
      <Drawer
        PaperProps={{
          sx: {
            // bgcolor: 'var(--MobileNav-background)',
            // color: 'var(--MobileNav-color)',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '100%',
            width: '275px',
            zIndex: 'var(--MobileNav-zIndex)',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
          },
        }}
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}

        variant="temporary"
      >
        <div
          {...rest}
          className={clsx(classes.root, className)}
        >
          {navbarContent}
        </div>
      </Drawer>
      
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
          style={{boxShadow: 'none'}}
        >
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;