import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  search: {
    backgroundColor: 'white',
    borderRadius: '0px 5px 5px 0px',
    // flexBasis: 300,
    height: theme.spacing(4),
    // padding: theme.spacing(0, 1),
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center'
  },

  searchSelect: {
    borderRadius: '5px 0 0 5px',
    backgroundColor: '#FFFFFF',
    color: 'black',
    '& input::placeholder': {
      opacity: 1,
      color: 'grey',
      fontSize: '0.8em'
    }
  },
  searchInput: {
    backgroundColor: '#FFFFFF',
    flexGrow: 1,
    color: 'black',
    paddingLeft: theme.spacing(0.5),
    '& input::placeholder': {
      opacity: 1,
      color: 'grey',
      fontSize: '0.8em'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(0.5),
  },
  searchList: {
    width: '280px'
  },
  inputValue: {
    fontWeight: 450
  },
  searchOption: {
    fontStyle: 'italic'
  },
  searchIconBtn: {
    backgroundColor: '#eeeeee',
    height: '32px',
    borderRadius: '0 5px 5px 0',
    '&:hover': {
    },
  }
}));

export default useStyles;