import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.white
  },
  searchInput: {
    flexGrow: 1
  },
  leftTopPanel: {
    flexGrow: 1,
    maxHeight: '100%',
    position: 'relative',
    height: '300px'
  },
  leftMiddlePanel: {
    backgroundColor: '#cfebe9',
    minHeight: '40px',
    paddingLeft: '12px'
  },
  leftBottomPanel: {
    flexGrow: 1,
    maxHeight: '100%',
    position: 'relative',
    height: '390px'
  },
  blackboardTab: {
    backgroundColor: '#eff9f8',
    fontSize: '10px',
    minWidth: '124px',
    padding: '0px',
    minHeight: '55px'
  },
  notificationTab: {
    backgroundColor: '#eff9f8',
    fontSize: '12px',
    minHeight: '55px',
    padding: '0px',
    minWidth: '124px'
  },
  drawerHeader: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  programName: {
    paddingTop: '12px',
    paddingLeft: '2px'
  }
}));
export default useStyles;