const EditMcPostModalSchema = {
  title: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 150
    }
  },
  category: {
    /* presence: { allowEmpty: false, message: 'is required' }, */
  },
  campus: {
    /* presence: { allowEmpty: false, message: 'is required' }, */
  },
  descContent: {
    /* presence: { allowEmpty: false, message: 'is required' }, */
  }
};
    
export default EditMcPostModalSchema;