import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';

import rootReducer from 'store/reducers';

/* persist refresh [START]*/
import {persistStore, persistReducer} from 'redux-persist'; 
import storageSession from 'redux-persist/lib/storage/session'

const persistConfig = {
  key: 'root', // 必须有的
  storage: storageSession, // 缓存机制
  // whitelist: ['token','loggedIn', 'userInfo', 'postCategories', 'schoolInfo'] // reducer 里持久化的数据,除此外均为不持久化数据
}
const persistedReducer = persistReducer(persistConfig, rootReducer)
/* persist refresh [END]*/
const loggerMiddleware = createLogger();

export default function configureStore(preloadedState = {}) {
  // const middlewares = [thunkMiddleware, loggerMiddleware]; // loggerMiddleware

  let middlewares = [];

  // eslint-disable-next-line no-undef
  if (process.env.REACT_APP_NODE_ENV === 'dev') {
    middlewares = [thunkMiddleware, loggerMiddleware]
  } else {
    middlewares = [thunkMiddleware]
  }
  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  const store = createStore(persistedReducer, preloadedState, composedEnhancers);
  let persistor = persistStore(store);
  
  return {store, persistor};
}
