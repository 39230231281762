import React from 'react';
import clsx from 'clsx';

import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import RedeemIcon from '@material-ui/icons/Redeem';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import AssignmentReturnOutlinedIcon from '@material-ui/icons/AssignmentReturnOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';

import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
}));
const McCategoryIcon = (props) => {
  const { name, className, fontSize } = props;
  const classes = useStyles();
  let result = '';
  switch (name) {
    case 'Buy & Sells':        
      result = 
        <span 
          className={clsx(classes.root, className)}
          title="Buy & Sells"
        >
          <ShoppingBasketOutlinedIcon 
            className={classes.icon} 
            fontSize={fontSize === null ? 'default' : fontSize}
          />
        </span>
      break;
    case 'Events':        
      result = 
        <span 
          className={clsx(classes.root, className)}
          title="Events"
        >
          <EventAvailableIcon 
            className={classes.icon} 
            fontSize={fontSize === null ? 'default' : fontSize}
          />
        </span>
      break;
    case 'Exchange':        
      result = 
       <span 
         className={clsx(classes.root, className)}
         title="Exchange"
       >
         <CompareArrowsIcon 
           className={classes.icon} 
           fontSize={fontSize === null ? 'default' : fontSize}
         />
       </span>
      break;
    case 'Free':        
      result = 
        <span 
          className={clsx(classes.root, className)}
          title="Free"
        >
          <RedeemIcon 
            className={classes.icon} 
            fontSize={fontSize === null ? 'default' : fontSize}
          />
        </span>
      break;
    case 'Freshman':        
      result = 
        <span 
          className={clsx(classes.root, className)}
          title="Freshman"
        >
          <AssignmentIndOutlinedIcon 
            className={classes.icon} 
            fontSize={fontSize === null ? 'default' : fontSize}
          />
        </span>
      break;
    case 'House & Apartment':        
      result = 
        <span 
          className={clsx(classes.root, className)}
          title="House & Apartment"
        >
          <HomeWorkOutlinedIcon 
            className={classes.icon} 
            fontSize={fontSize === null ? 'default' : fontSize}
          />
        </span>
      break;
    case 'Job & Volunteers':        
      result = 
        <span 
          className={clsx(classes.root, className)}
          title="Job & Volunteers"
        >
          <WorkOutlineOutlinedIcon 
            className={classes.icon} 
            fontSize={fontSize === null ? 'default' : fontSize}
          />
        </span>
      break;
    case 'Lost & Found':        
      result = 
        <span 
          className={clsx(classes.root, className)}
          title="Lost & Found"
        >
          <AssignmentReturnOutlinedIcon 
            className={classes.icon} 
            fontSize={fontSize === null ? 'default' : fontSize}
          />
        </span>
      break;
    case 'Questions & Answers':        
      result = 
        <span 
          className={clsx(classes.root, className)}
          title="Questions & Answers" 
        >
          <LiveHelpOutlinedIcon 
            className={classes.icon} 
            fontSize={fontSize === null ? 'default' : fontSize}
          />
        </span>
      break;
    case 'Others':        
      result = 
        <span 
          className={clsx(classes.root, className)} 
          title="information"
        >
          <InfoOutlinedIcon 
            className={classes.icon} 
            fontSize={fontSize === null ? 'default' : fontSize}
          />
        </span>
      break;
    default:
      result = 
        <span 
          className={clsx(classes.root, className)}
          title="information"
        >
          <InfoOutlinedIcon 
            className={classes.icon} 
            fontSize={fontSize === null ? 'default' : fontSize}
          />
        </span>
      break;
  }
  return result;
};

McCategoryIcon.propTypes = {
  className: PropTypes.string,
  fontSize: PropTypes.string,
  name: PropTypes.string
};


export default McCategoryIcon;
