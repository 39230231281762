import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
const useStyles = makeStyles(theme => ({

  root: {
    backgroundColor: theme.palette.white
  },
  list: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  listItem: {
    padding: theme.spacing(0.25, 1),
    margin: theme.spacing(0.5),
    paddingRight: theme.spacing(3),
  },
  listItemAvatar: {
    display: 'contents'
  },
  listItemText: {
    marginRight: theme.spacing(0.5)
  },
  lastActivity: {
    whiteSpace: 'nowrap'
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
  },
  fab: {
    position: 'fixed',
    bottom: 32,
    right: 32,
    /* zIndex: theme.zIndex.drawer - 100 */
  },
  subheader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.grey[200],
    padding: theme.spacing(0, 1),
    height: theme.spacing(4),
    '&:hover': {
      cursor: 'default'
    }
  },
  reloadingButton: {
    padding: theme.spacing(0.5)
  }
}));

export default useStyles;