import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    justifyContent: 'center',
    backgroundColor: '#004b4c'
  },
  input: {
    display: 'none'
  },
  cropArea: {
    width: '68%',
    borderRadius: '5px',
    margin: theme.spacing(1),
    border: '2px dashed #4caf507a',
    // cropper-container
    '& .cropper-container': {

    }
  },
  previewOperationArea: {
    padding: theme.spacing(1),
    display: 'flex',
    backgroundColor: '#f3f3f3',
    borderRadius: '5px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '32%',
    height: '300px',
    zIndex: 100
  },
  gap: {
    flexGrow: 1
  },
  previewArea: {
    margin: '10px',
    textAlign: 'center',
  },
  previewAvatar: {
    width: '100%', height: 150, 
    border: '1px dashed #4caf507a', 
    [theme.breakpoints.down('md')]: {
      borderRadius: '2px 8px 2px 8px'
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: '10px 45px',
      margin: '10px',
    },
    overflow: 'hidden'
  },
  operation: {
    backgroundColor: '#e4e4e4',
    borderRadius: '5px',
    textAlign: 'center',
  },
  operationBtn: {
    padding: '4px'
  },
  loadingImg: {
    color:  colors.blue[500]
  }
}));

export default useStyles;