import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto'
  },
  content: {
    padding: theme.spacing(2)
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  schoolLogo: {
    width: '100px',
    borderRadius: '10%'
  },
  name: {
    padding: theme.spacing(0, 2)
  },
  avatarDiv: {
    // marginBottom:theme.spacing(3)
    display: 'flex',
    alignItems: 'center',
  }, 
  avatar: {
    border: `1px solid ${colors.grey[300]}`,
    width: '1.5em',
    height: '1.5em',
    margin:  theme.spacing(0.5)
  },
  namelink: {
    paddingTop: theme.spacing(1.5)
  },
  divider: {
  },
  navigation: {
    marginTop: theme.spacing(2),
    width: '240px',
  },
  loadingSchools: {
    color:  colors.blue[500]
  },
  signoutDiv: {
    display: 'flex',
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  signoutLink: {
    color: colors.blueGrey[800],
    padding: '10px 10px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  showMoreDiv: {
    textAlign: 'center'
  },
  showMsg: {
    padding: theme.spacing(0),
  },
  schoolIconsDiv:{
    display: 'flex',
    justifyContent: 'center',
    padding:  theme.spacing(0.5),
  },
  schoolAvatar: {
    backgroundColor: '#92babb',
    margin: 2
  }
}));

export default useStyles;