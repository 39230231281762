import axios from '../../utils/mcaxios';
import {
  GET_FETCH_NOTIF_URL,
  GET_QUERY_HELPS_URL,
  PUT_DELETE_NOTIFICATION_URL,
} from '../../store/rest-api';

const findHelps = async (skip, keywords) => {
  try {
    let result = await axios.get(
      GET_QUERY_HELPS_URL.concat('?')
        .concat('skip=')
        .concat(skip)
        .concat('&')
        .concat('keywords=')
        .concat(keywords),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const deleteNotification = async (notificationId) => {
  try {
    let result = await axios.put(
      PUT_DELETE_NOTIFICATION_URL.concat('/').concat(notificationId),
      {},
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const getUserNotifications = async (skip) => {
  try {
    let result = await axios.get(GET_FETCH_NOTIF_URL.concat('/').concat(skip));
    return result.data;
  } catch (error) { /* empty */ }
};

export const notificationService = {
  getUserNotifications,
  deleteNotification,
  findHelps,
};
