import { colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    top: 'auto',
    bottom: 0,
    backgroundColor: colors.grey[200],
    zIndex: 1
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('lg')]: {
      width: theme.spacing(75)
    },
    paddingLeft: 0,
    paddingRight: 0, 
  },
  logo: {
    width: theme.spacing(3.5),
    padding: theme.spacing(0.5, 0.5, 0.5, 0.5),
  },
  logoName: {
    color: colors.grey[600]
  },
  iconBtn: {
    color: colors.grey[600]
  },
  followDiv: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export default useStyles;