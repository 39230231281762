import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    width: 28,
    height: 28,
    margin: '0px 5px',
    borderRadius: '2px 8px 2px 8px',
  },
  round: {
    borderRadius: '50%',
    padding: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#007f99',
    },
  }
 
}));

export default useStyles;