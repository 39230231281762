import * as actionTypes from 'store/actions/actionTypes'
const initialState = {
  open: false,
  message: '',
  variant: 'info',
  buttonText: '',
  handleClick:null,
};
const snackerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DISPLAY_SIMPLE_GLOBAL_SNACKER: {
      return {
        ...state,
        open: true,
        message: action.message,
        variant: action.variant,
      };
    }
    case actionTypes.DISPLAY_ADVANCED_GLOBAL_SNACKER: {
      return {
        ...state,
        open: true,
        message: action.message,
        variant: action.variant,
        buttonText: action.buttonText,
        handleClick:action.handleClick,
      };
    }

    case actionTypes.CLOSE_SNACKER: {
      return {
        open: false,
        message: '',
        variant: 'info',
        buttonText: '',
        handleClick:null,
      };
    }
    default: {
      return state;
    }
  }
}

export default snackerReducer