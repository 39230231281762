import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
const McTextFieldUnderlined = withStyles({
  root: {
    '& label': {
      color: '#bdbdbd'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#009899',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid #009899'
    },
    '& .MuiInput-underline:before': {
      borderColor: '#8cc7c2',
    }, 
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#009899',
      },
      '&:hover fieldset': {
        borderColor: '#27999a',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#009899'
      },
    },
  }
  
})(TextField);
export default McTextFieldUnderlined;