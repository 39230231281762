import React, { Fragment, useState, useRef } from 'react';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import { NotificationsPopover } from './components';
import useStyles from './Notification.style';
import { notificationService } from 'service';

const Notification = () => {
  const classes = useStyles();
  const notificationsRef = useRef(null);

  const [unreadCount, setUnreadCount] = useState(0)
  const [openNotifications, setOpenNotifications] = useState(false);
  const [notifications, setNotifications ] = useState([]);
  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
    queryPopoverNotifs()
  };

  const queryPopoverNotifs = () => {
    notificationService
      .getUserNotifications(0)
      .then((response) => {
        const { status, notifVos, pageSize } = response;
        if (notifVos) {
          setNotifications(notifVos)
        }
      })
      .catch((error) => {
        // console.log('error ', error);
      });
  }
  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  return (
    <Fragment>
      <IconButton
        className={classes.notificationsButton}
        color="black"
        onClick={handleNotificationsOpen}
        ref={notificationsRef}
      >
        <Badge
          badgeContent={unreadCount}
          classes={{ badge: classes.notificationsBadge }}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
    </Fragment>
  )
}

export default Notification;
