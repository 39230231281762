import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    // marginBottom: theme.spacing(2), 
    backgroundColor: 'white',
    padding: theme.spacing(2),
  },
  bubble: {
    maxWidth: '94%',
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  time: {
    marginLeft: 'auto'
  },
  message: {
  },
  likedButton: {
    color: colors.red[600]
  },
  likeButton: {
  },
  likeReplyBar: {
    display: 'flex',
    alignItems: 'center'
  },
  replyButton: {
    marginLeft: theme.spacing(2),
  },
  count: {
    color: '#546e7a',
    fontSize: '14px',
  },
  showPrevious: {
    margin: '12px',
    display: 'block',
    fontSize: '14px',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  commentContent: {
    fontSize: '15px',
    padding: '8px 0px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',

    // wordWrap: 'anywhere',
    overflowWrap: 'anywhere',
    whiteSpace: 'break-spaces',
    wordWrap: 'break-word',
    wordBreak:'break-all',
    
    '& .twemoji': {
      width: '22px',
      marginLeft: '1px',
      marginRight: '1px',
      verticalAlign: 'middle',
    }
  },
  loadingMoreLink: {
    textAlign: 'center',
  },
  loadingPosts: {
    color:  colors.blue[500]
  }
}));
export default useStyles;