import axios from '../../utils/mcaxios';
import {
  PUT_UPDATE_PASSWORD_URL,
  POST_AUTO_LOGIN_BY_COOKIE_URL,
  POST_SIGNOUT_USER_URL,
  POST_SUBMIT_REPORT_URL,
  POST_WEB_SIGNOUT_USER_URL,
} from '../../store/rest-api';

const autoLogin = async (cookieToken) => {
  try {
    const result = await axios.post(POST_AUTO_LOGIN_BY_COOKIE_URL, {
      'cookie-token': cookieToken,
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const signout = async () => {
  try {
    const result = await axios.post(POST_SIGNOUT_USER_URL, {});
    return result.data;
  } catch (error) { /* empty */ }
};
const signoutWeb = async () => {
  try {
    const result = await axios.post(POST_WEB_SIGNOUT_USER_URL, {});
    return result.data;
  } catch (error) { /* empty */ }
};
const changePassword = async (passwordFields) => {
  try {
    const result = await axios.put(PUT_UPDATE_PASSWORD_URL, passwordFields);
    return result.data;
  } catch (error) { /* empty */ }
};

const submitReport = async (item, type, tempLabels, description) => {
  try {
    const result = await axios.post(POST_SUBMIT_REPORT_URL, {
      itemId: item.id,
      type,
      label: tempLabels,
      description,
      // for notification
      userId: item.createUserId,
      title: item.title,
    });
    return result.data;
  } catch (error) { /* empty */ }
};
export const authService = {
  autoLogin,
  signout,
  changePassword,
  submitReport,
  signoutWeb
};
