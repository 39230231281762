import axios from '../../utils/mcaxios';
import {
  POST_ADD_FRIEND_URL,
  GET_QUERY_USER_PROFILE,
  GET_USER_SCHOOLS_URL,
  DELETE_UNSUBSCRIBE_SCHOOL_URL,
  PUT_USER_SCHOOLS_URL,
  POST_USER_SCHOOLS_URL,
  POST_SEARCH_USER_URL,
  GET_FETCH_FRIENDS_URL,
  PUT_REMOVE_FRIEND_URL,
  GET_QUERY_MICOLLEGE_ADMIN,
  GET_QUERY_COLLEGEMATE_COUNT,
  GET_QUERY_COLLEGEMATE,
  // POST_RESTRICT_USER_URL,
  GET_RESTRICT_USER_URL,
  // POST_UNRESTRICT_USER_URL,
  POST_UPLOAD_AVATAR_URL,
  PUT_UPDATE_USER_DETAIL_URL,
  POST_DELETE_ACCOUNT_URL,
  GET_QUERY_ACCOUNT_URL,
  GET_CHECK_CHAT_PARTNER_STATUS_URL,
  GET_QUERY_BLOCK_URL,
} from '../../store/rest-api';
// import { SUCCESS_ADD_USER_FRIEND_CODE } from '../../store/statusCode';

/**When click chat icon in feed bottom bar */
const pressChat = (navigation, currentUserFsId, chatType, peerItem) => {
  const currentUserId = currentUserFsId;
  const participantUserId = peerItem.fsId;
  if (currentUserId === participantUserId) {
    return;
  }
  // const friend = {
  //   id: peerItem.userId,
  //   participantFsId: peerItem.fsId,
  //   avatarUrl: peerItem.userAvatar,
  //   fullName: peerItem.userName,
  //   // firstName: feedItem.firstName,
  //   // lastName: feedItem.lastName,
  // };
  // let channelID = '';
  const result = currentUserId.localeCompare(participantUserId);
  if (result > 0) {
    // channelID = currentUserId + participantUserId;
  } else {
    // channelID = participantUserId + currentUserId;
  }
  // const channel = {
  //   id: channelID,
  //   participants: [friend],
  //   name: peerItem.userName,
  //   channelType: chatType,
  //   status: MC_CONVERSATION_STATUS_JOINED,
  // };
  // if (chatType === CHAT_TYPE_PERSON) {
  //   navigation.navigate('PersonalChat', {
  //     channel,
  //     appStyles: AppStyles,
  //   });
  // }
};
const getUserProfile = async (userId) => {
  try {
    const result = await axios.get(
      GET_QUERY_USER_PROFILE.concat('/').concat(userId),
    );
    return result.data;
  } catch (error) { /* empty */ }
};

const getMySchools = async () => {
  try {
    const result = await axios.get(GET_USER_SCHOOLS_URL);
    return result.data;
  } catch (error) { /* empty */ }
};
const unsubscribeSchool = async (schoolId) => {
  try {
    const result = await axios.delete(DELETE_UNSUBSCRIBE_SCHOOL_URL, {
      data: {
        schoolId,
      },
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const changeMainSchool = async (schoolId) => {
  try {
    const result = await axios.put(PUT_USER_SCHOOLS_URL, {
      schoolId,
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const subscribeSchool = async (schoolId) => {
  try {
    const result = await axios.post(POST_USER_SCHOOLS_URL, {
      schoolId,
    });
    return result.data;
  } catch (error) { /* empty */ }
};
//----------------------------------------
const addFriend = async (userId, userName, userAvatar) => {
  const newFriend = {
    peerId: userId,
    peerName: userName,
    peerAvatar: userAvatar,
  };
  axios
    .post(POST_ADD_FRIEND_URL, newFriend)
    .then(() => {
      // const { status, message } = response.data;
      // callback(status, message, newFriendVo);
      // if (status === SUCCESS_ADD_USER_FRIEND_CODE) {
      //   Snackbar.show({
      //     text: McLocalized(McLocalized('Successfully added.')),
      //     duration: Snackbar.LENGTH_LONG,
      //     backgroundColor: 'green',
      //   });
      // } else {
      //   Snackbar.show({
      //     text: McLocalized(message),
      //     duration: Snackbar.LENGTH_LONG,
      //     backgroundColor: 'orange',
      //   });
      // }
    })
    .catch(() => {
      // console.log('error ', error);
    });
};

// navigate to user profile screen
const navigateToUserProfile = async (navigation, userId, username) => {
  if (userId && username) {
    navigation.navigate('UserProfile', {
      lastScreenTitle: 'Feed',
      userId,
      username,
    });
  }
};
const navigateToFeedLikeUsers = () => {
  // navigation.navigate('FeedLikeUsers', {
  //   headerTitle: McLocalized('Likes'),
  //   feedId: postId,
  //   appStyles: AppStyles,
  // });
};
const discoverSchoolmates = async (skip, searchScope, schoolId, keywords) => {
  try {
    const result = await axios.post(POST_SEARCH_USER_URL, {
      skip,
      searchScope,
      schoolId,
      keywords,
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const findFriends = async (skip, keywords) => {
  try {
    const result = await axios.get(
      GET_FETCH_FRIENDS_URL.concat('?')
        .concat('skip=')
        .concat(skip)
        .concat('&')
        .concat('keywords=')
        .concat(keywords),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const deleteFriend = async (friendUserId) => {
  try {
    const result = await axios.put(
      PUT_REMOVE_FRIEND_URL.concat('/').concat(friendUserId),
      {},
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const getMicollegeAdmin = async () => {
  try {
    const result = await axios.get(GET_QUERY_MICOLLEGE_ADMIN);
    return result.data;
  } catch (error) { /* empty */ }
};
const getCollegeMateCount = async () => {
  try {
    const result = await axios.get(GET_QUERY_COLLEGEMATE_COUNT);
    return result.data;
  } catch (error) { /* empty */ }
};
const findCollegeMates = async (skip, keywords) => {
  try {
    const result = await axios.get(
      GET_QUERY_COLLEGEMATE.concat('?')
        .concat('skip=')
        .concat(skip)
        .concat('&')
        .concat('keywords=')
        .concat(keywords),
    );
    return result.data;
  } catch (error) { /* empty */ }
};

const muteUser = async () => {
  // try {
  //   const result = await axios.post(POST_RESTRICT_USER_URL, {
  //     userFsId,
  //     restrictUserFsId,
  //     type: RESTRICT_ACCOUNT_TYPE_MUTE,
  //   });
  //   return result.data;
  // } catch (error) { /* empty */ }
};
const blockUser = async () => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  // try {
  //   const result = await axios.post(POST_RESTRICT_USER_URL, {
  //     userFsId,
  //     restrictUserFsId,
  //     type: RESTRICT_ACCOUNT_TYPE_BLOCK,
  //   });
  //   return result.data;
  // } catch (error) { /* empty */ }
};
const queryRestrictedUsers = async (type) => {
  try {
    const result = await axios.get(
      GET_RESTRICT_USER_URL.concat('?').concat('type=').concat(type),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const unmuteUser = async () => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  // try {
  //   const result = await axios.post(POST_UNRESTRICT_USER_URL, {
  //     userFsId,
  //     restrictUserFsId,
  //     type: RESTRICT_ACCOUNT_TYPE_MUTE,
  //   });
  //   return result.data;
  // } catch (error) { /* empty */ }
};
const unblockUser = async () => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  // try {
  //   const result = await axios.post(POST_UNRESTRICT_USER_URL, {
  //     userFsId,
  //     restrictUserFsId,
  //     type: RESTRICT_ACCOUNT_TYPE_BLOCK,
  //   });
  //   return result.data;
  // } catch (error) { /* empty */ }
};

const uploadAvatar = async (data) => {
  try {
    const result = await axios.post(POST_UPLOAD_AVATAR_URL, data);
    return result.data;
  } catch (error) { /* empty */ }
};
const uploadUserProfile = async (userDetail) => {
  try {
    const result = await axios.put(PUT_UPDATE_USER_DETAIL_URL, {
      ...userDetail,
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const deleteAccount = async () => {
  try {
    const result = await axios.post(POST_DELETE_ACCOUNT_URL, {});
    return result.data;
  } catch (error) { /* empty */ }
};
const getUserByFsId = async (userFsId) => {
  try {
    const result = await axios.get(
      GET_QUERY_ACCOUNT_URL.concat('?').concat('userFsId=').concat(userFsId),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const checkChatPartnerStatus = async (userFsId) => {
  try {
    const result = await axios.get(
      GET_CHECK_CHAT_PARTNER_STATUS_URL.concat('?')
        .concat('userFsId=')
        .concat(userFsId),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const getBlocks = async () => {
  try {
    const result = await axios.get(GET_QUERY_BLOCK_URL);
    return result.data;
  } catch (error) { /* empty */ }
};
export const userService = {
  addFriend,
  navigateToUserProfile,
  pressChat,
  getUserProfile,
  getMySchools,
  unsubscribeSchool,
  changeMainSchool,
  subscribeSchool,
  navigateToFeedLikeUsers,
  discoverSchoolmates,
  findFriends,
  deleteFriend,
  getMicollegeAdmin,
  getCollegeMateCount,
  findCollegeMates,
  muteUser,
  blockUser,
  queryRestrictedUsers,
  unmuteUser,
  unblockUser,
  uploadAvatar,
  uploadUserProfile,
  deleteAccount,
  getUserByFsId,
  checkChatPartnerStatus,
  getBlocks,
};
