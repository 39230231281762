import React from 'react';

import { Link as RouterLink }   from 'react-router-dom';
import PropTypes                from 'prop-types';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { List as ListIcon } from "@phosphor-icons/react/dist/ssr/List";
import Hidden                       from '@material-ui/core/Hidden';
// import { paths } from "@/paths";
// import { MobileNav } from "../mobile-nav";

const McTopBar2 = () => {

  // const auth = useSelector(state => state.auth);
  // const schoolsInfo = useSelector(state => state.schools.schoolsInfo);
  const [openNav, setOpenNav] = React.useState(false);
  console.log(openNav)
  return (
    <React.Fragment>
      <header style={{margin: 0, padding: 0, color: 'white', backgroundColor: '#007f99', height: '6%'}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', marginLeft: '5%'}}>
            <img 
              alt="micollege logo" 
              height="36px" 
              src="/assets/mc-logo-black.png" 
              width="36px" 
            />
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
              <p 
                style={{margin: 0, fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', color: 'white', fontSize: '1.25rem', padding: '5px', fontWeight: 500}}>MiCollege</p>
              <Hidden smDown>
                <p 
                  id="subtitle" 
                  style={{margin: 0, fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', fontWeight: 600, fontSize: '0.875rem', lineHeight: '1.5', color: 'white', WebkitAlignSelf: 'center', msFlexItemAlign: 'center', alignSelf: 'center', padding: '5px', paddingTop: '8px'}}>
                Connect fellow college students
                </p>
              </Hidden>
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '10%'}}>
            <a style={{margin: 0, fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', fontWeight: 600, fontSize: '0.875rem', lineHeight: '1.5', color: 'white', padding: '5px'}} href="/">Home</a>
            <a style={{margin: 0, fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', fontWeight: 600, fontSize: '0.875rem', lineHeight: '1.5', color: 'white', padding: '5px'}} href="/micollege-apps.html">Apps</a>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

McTopBar2.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default McTopBar2;
