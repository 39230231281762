/* McPostProviderController */
export const POST_CREATE_SHORT_URL = 'short-api/mcshorts/shorts'; // {AddPostFormVo}

export const GET_QUERY_USER_SCHOOLS_SHORTS_URL = 'short-api/mcshorts/shorts'; //{skip}

export const POST_CREATE_SHORT_COMMENT_URL =
  'short-api/mcshorts/shorts/comments'; //{PostCommentFormVo}

export const GET_QUERY_SHORT_COMMENTS_URL =
  'short-api/mcshorts/shorts/comments'; //{shortId}/{skip}
export const DELETE_DELETE_SHORT_COMMENT_URL = 'short-api/mcshorts/comments';
export const GET_QUERY_SINGLE_SHORT_URL = 'short-api/mcshorts/shorts/view'; //{postId}
export const POST_ADD_SHORT_VIDEO_LIKE_URL = 'short-api/mcshorts/shorts/likes'; //{postId: xxx, count: 0}
export const GET_QUERY_MY_SHORTS_URL = 'short-api/mcshorts/shorts/myshorts'; //{skip}
export const GET_QUERY_MY_SHORT_COMMENTS_URL =
  'short-api/mcshorts/comments/mycomments'; //{skip}
export const GET_QUERY_MY_LIKED_SHORT_URL = 'short-api/mcshorts/shorts/mylikes'; //{postId: xxx, count: 0}
export const DELETE_DELETE_SHORT_URL = 'short-api/mcshorts/shorts';
export const GET_QUERY_USER_PROFILE_SHORTS_URL =
  'short-api/mcshorts/shorts/user-profile'; //{formVo}

// no auth
export const GET_QUERY_SHORT_GUEST_URL = 'short-api/mcshorts/shorts/guest';
export const GET_QUERY_SHORT_COMMENT_GUEST_URL =
'short-api/mcshorts/shorts/comments/guest';
//front end
export const GET_QUERY_SINGLE_SHORT_GUEST_URL = 'short-api/mcshorts/shorts/view';