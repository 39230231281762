import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    overflowY: 'scroll',
    maxHeight: '100%',
    position: 'relative',
    height: '390px'
  },
  showMsg: {
    left: '50%',
    marginTop: '-10px',
    borderTop: 'none',
    transform: 'translate(-50%, 0)',
  },
  loadingHisMsgs: {
    color:  colors.blue[500]
  },
  dateLabel: {
    position: 'fixed',
    height: '20px',
    left: '50%',
    marginTop: '-10px',
    borderTop: 'none',
    transform: 'translate(-50%, 0)',
  }
}));
export default useStyles;