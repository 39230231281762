import React, {
  Fragment, 
  useEffect, 
  useState}         from 'react';
import { 
  useDispatch }     from 'react-redux';
import PropTypes    from 'prop-types';
import validate     from 'validate.js';
import clsx         from 'clsx';

import Grid           from '@material-ui/core/Grid';
import Paper          from '@material-ui/core/Paper';
import IconButton     from '@material-ui/core/IconButton';
import CheckIcon      from '@material-ui/icons/Check';
import CloseIcon      from '@material-ui/icons/Close';

import axios                            from 'utils/mcaxios';
import { 
  GET_QUERY_AUTOCOMPLETE_SCHOOL_URL, 
  GET_QUERY_SCHOOL_INFO_URL }           from 'store/rest-api';
import { 
  SUCCESS_QUERY_SCHOOL_CODE }           from 'store/statusCode';
import { 
  McTextFieldAutoSuggestMulti, 
  McLoader }                            from 'components';
import {
  DisplaySimpleSnacker }                from 'store/actions';
import {
  MC_SNACKER_TYPE_WARNING }             from 'mcconfig';
import useStyles                        from './ChangeSchoolForm.style';
import ChangeSchoolFormSchema           from './ChangeSchoolForm.schema';

const ChangeSchoolForm = props => {
  const { onSubmitSchool, onCancel,  className, ...rest} = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // const [snackerMessage, setSnackerMessage] = useState('');
  // const [open, setOpen] = useState(false);

  const [schoolId, setSchoolId] = useState();
  const [selectSchool, setSelectSchool] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  useEffect(() => {
    let mounted = true;
    function validateFields(){
      let errors = validate(formState.values, ChangeSchoolFormSchema());
      if(!selectSchool){
        errors  = errors == null ? {} : errors;
        errors.school = ['Please select your school.'];
      }
      setFormState(formState => ({
        ...formState,
        isValid: errors ? false : true,
        errors: errors || {}
      }));
    }
    if(mounted){
      validateFields();
    }
    return () => { mounted = false }
  }, [formState.values]);// eslint-disable-line
  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  let value = 0;
  const getSuggestionValue = (suggestion) => {
    value = 1;
    setSchoolId(suggestion.id)
    setSelectSchool(true);
    handleChangeSchool('school');
    return suggestion.name;
  }
  const handleChangeSchool = name => (event, { newValue }) => {
    event.persist();
    if(value === 1){
      setSelectSchool(true);
    }else{
      setSelectSchool(false);
    }
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [name]: newValue
      },
      touched: {
        ...formState.touched,
        [name]: true
      }
    }));
  };
   
  /**[ Handle Submit post ]**/
  const handleSubmit = event => {
    event.preventDefault();
    setLoading(true);
    axios.get(GET_QUERY_SCHOOL_INFO_URL.concat('/').concat(schoolId))
      .then(function (response) {
        const {status, message, schoolVo} = response.data;
        setLoading(false);
        if(status === SUCCESS_QUERY_SCHOOL_CODE){
          onSubmitSchool(schoolVo);
        }else{
          // setOpen(true);
          // setSnackerMessage(message);
          dispatch(DisplaySimpleSnacker(message, MC_SNACKER_TYPE_WARNING));
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  const handleCancel = event => {
    event.preventDefault();
    onCancel()
  };

  
  // const handleCloseSnacker = () => {
  //   setOpen(false);
  // };
  return (
    <Fragment>
      <Paper 
        {...rest}
        className={clsx(classes.root, className)}
      >
        <br/>
        <Grid
          item
          xs={12}
        >
          <McTextFieldAutoSuggestMulti
            formState={formState}
            getSuggestionValue={getSuggestionValue}
            handleChange={handleChangeSchool}
            hasError={hasError}
            id="changeCreateClassSchool"
            label="University / College"
            name="school"
            url={GET_QUERY_AUTOCOMPLETE_SCHOOL_URL}
          />
        </Grid>
        <br/>
        <Grid
          container
          item
          xs={12}
        >
          <Grid
            item
            xs={4}
          >
            <IconButton 
              className={classes.cancelBtn}
              onClick={handleCancel}
              title="Change school"
            >
              <CloseIcon fontSize="large"/>
            </IconButton>
          </Grid>
          <Grid
            item
            xs={4}
          />
          <Grid
            className={classes.submitGrid}
            item
            xs={4}
          >
            <IconButton 
              className={classes.submitBtn}
              disabled={!formState.isValid || loading}
              onClick={handleSubmit}
              title="Change school"
            >
              {
                !loading
                &&
                <CheckIcon fontSize="large"/>
              }
              <McLoader loading={loading} />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
      {/* <McSnackbar 
        handleClose={handleCloseSnacker}
        message={snackerMessage}
        open={open}
        variant="warning"
      /> */}
    </Fragment>
  );
};

ChangeSchoolForm.propTypes = {
  className: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmitSchool: PropTypes.func,
  schoolInfo: PropTypes.object,
};

export default ChangeSchoolForm;