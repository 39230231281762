import React, { 
  useState, 
  Fragment }            from 'react';
import { 
  Link as RouterLink }  from 'react-router-dom';
import PropTypes        from 'prop-types';
import clsx             from 'clsx';
import moment           from 'moment';

import ChatIcon           from '@material-ui/icons/Chat';
import Divider            from '@material-ui/core/Divider';
import ThumbUpAltIcon     from '@material-ui/icons/ThumbUpAlt';
import Link               from '@material-ui/core/Link';
import Typography         from '@material-ui/core/Typography';
import IconButton         from '@material-ui/core/IconButton';
import Tooltip            from '@material-ui/core/Tooltip';

import axios                            from 'utils/mcaxios';
import { McAlert, 
  McAvatar, 
  McDialogModal, 
  McLikesList,
  McCommentMoreOptions, 
  EditMcCommentModal }                  from 'components';
import { POST_ADD_REPLY_LIKE_URL, 
  DELETE_DELETE_REPLY_URL, 
  PUT_UPDATE_COMMENT_REPLY_URL,
  GET_QUERY_REPLY_LIKES_URL }           from 'store/rest-api';
import { SUCCESS_UPDATE_REPLY_CODE, 
  SUCCESS_FETCH_REPLY_LIKES_CODE,
  SUCCESS_ADD_COMMENT_REPLY_LIKE_CODE } from 'store/statusCode';
import useStyles                        from './McReplyCard.style';

const McReplyCard = props => {
  const {  reply, 
    onClickReplyIcon, onDeleteReply,
    onUpdateEditedReply,
    className, ...rest } = props;
  const classes = useStyles();
  const [liked, setLiked] = useState(false);
  const [openEditCommentModal, setOpenEditCommenttModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  
  const [message, setMessage] = useState('');

  const [replyLikesCount, setReplyLikesCount] = useState(reply.likeCount);

  const handleOpenEditModel = () => {
    setOpenEditCommenttModal(true);
  };
  const handleOpenDeleteConfirm = () => {
    setOpenConfirmModal(true);
    setMessage('Do you want to delete this comment? All its likes will be removed.');
  
  };
  const handleReplyLike = () => {
    setLiked(true);
    setReplyLikesCount(commentLikesCount => commentLikesCount + 1);
    handleLikeReply(1)
  };

  const handleReplyUnlike = () => {
    setLiked(false);
    setReplyLikesCount(commentLikesCount => commentLikesCount - 1);
    handleLikeReply(-1)
  };
  const handleLikeReply = (count) => {

    axios.post(POST_ADD_REPLY_LIKE_URL,
      {
        replyId: reply.id,
        count: count
      })
      // .then(function (response) {
      //   const {status} = response.data;
      // })
      .catch(function (error) {
        console.log(error);
      });
  }
  const handleClickReplyReplyIcon = (userName) => {
    onClickReplyIcon(userName);
  }
  const handleEditCommentModalClose = () => {

    setOpenEditCommenttModal(false);
  };
  const handleConfirm = () => {
    setOpenConfirmModal(false);

    //Request backend to remove reply and decrease comment replycount
    axios.delete(DELETE_DELETE_REPLY_URL, {
      data: {
        replyId: reply.id
      }
    }).then( () => {
      onDeleteReply()
    })
      .catch( error => {
        console.log('error ', error);
      });
  }
  const handleCloseAlert = () => {
    setOpenConfirmModal(false);
  }
  const [updateLoading, setUpdateLoading] = useState(false);
  const handleUpdateReply = (content) => {

    onUpdateEditedReply(reply.id, content);
    setUpdateLoading(true);
    axios.put(PUT_UPDATE_COMMENT_REPLY_URL, {
      replyId: reply.id,
      replyContent: content
    })
      .then( response => {

        const {status } = response.data;
        // const redirectPath = '/mcpost';
        if(status === SUCCESS_UPDATE_REPLY_CODE ){
          //onClose()
        }else{
          //
        }
        setUpdateLoading(false);
        setOpenEditCommenttModal(false);
      })
      .catch( error => {
        setOpenEditCommenttModal(false);
        setUpdateLoading(false);
        console.log('error ', error);
      });
  }

  /* handle display likes modal */
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [replyLikeList, setReplyLikeList] = useState([])
  const [likesSkip, setLikesSkip] = useState(0);
  const [replyLikeTotalCount, setReplyLikeTotalCount] = useState(0);

  const handleCloseModal = () => {
    setLoading(false)
    setOpen(false)
  }
  const handleDisplayLikes = () => {
    setOpen(true)
    fetchReplyLikes(0)
  }
  const fetchReplyLikes = (likesSkip) => {
    setLoading(true)
    axios.get(GET_QUERY_REPLY_LIKES_URL.concat('/')
      .concat(reply.id).concat('/').concat(likesSkip))
      .then(function (response) {
        const {status, replyLikeVos, pageSize, replyLikeTotalCount} = response.data;
        if(status === SUCCESS_FETCH_REPLY_LIKES_CODE){
          if(likesSkip === 0){
            setReplyLikeList([...replyLikeVos])
          }else{
            setReplyLikeList([
              ...replyLikeList,
              ...replyLikeVos,
            ])
          }
          setLikesSkip(likesSkip + parseInt(pageSize));
          setReplyLikeTotalCount(replyLikeTotalCount)
        }
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
        console.log(error);
      });
  }
  const handleFetchReplyLikes = () => {
    fetchReplyLikes(likesSkip);
  }
  const handleUnlikeReply = () => {
    axios.post(POST_ADD_REPLY_LIKE_URL,
      {
        replyId: reply.id,
        count: -1,
      })
      .then(function (response) {
        const {status} = response.data;
        if(status === SUCCESS_ADD_COMMENT_REPLY_LIKE_CODE){
          setLiked(false);
          // setCommentLikesCount(commentLikesCount => commentLikesCount - 1);
          // reduce reply count in comment section
          setReplyLikesCount(commentLikesCount => commentLikesCount - 1);
          fetchReplyLikes(0)
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <Fragment>
      <McDialogModal
        onClose={handleCloseModal}
        open={open}
        title="Comment likes"
      >
        <McLikesList 
          likeList={replyLikeList}
          loading={loading}
          onFetchMoreLikes={handleFetchReplyLikes}
          onUnlike={handleUnlikeReply}
          totalCount={replyLikeTotalCount}
        />
      </McDialogModal>
      <McAlert
        handleConfirm={handleConfirm}
        message={message}
        onClose={handleCloseAlert}
        open={openConfirmModal}
        title="Alert"
        variant="warning"
      />
      {
        openEditCommentModal
        &&
        <EditMcCommentModal 
          comment={reply}
          loading={updateLoading}
          onClose={handleEditCommentModalClose}
          onUpdateComment={handleUpdateReply}
          open={openEditCommentModal}
          type="reply"
        />
      }
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <McAvatar 
          avatarUrl={reply.createUserAvatar}
          className={classes.avatar}
          userName={reply.createUserName}
        />
        <div className={classes.bubble}>
          <div className={classes.header}>
            <Link
              color="textPrimary"
              component={RouterLink}
              to={`/user-profile/${reply.createUserId}`}
              variant="h6"
            >
              {reply.createUserName}
            </Link>
            <Typography
              className={classes.time}
              title={moment(reply.createDatetime).local().format('llll')}
              variant="body2"
            >
              {moment(reply.createDatetime).local().fromNow()}
            </Typography>
            <McCommentMoreOptions 
              comment={reply}
              onOpenDeleteConfirm={handleOpenDeleteConfirm}
              onOpenEditModel={handleOpenEditModel}
            />
          </div>
          <Divider />
          <div  
            className={classes.replyContent}
            dangerouslySetInnerHTML={{ __html:reply.content}} 
            id="replyContent"
            name="replyContent"
          />
          <div
            className={classes.likeReplyBar}
          >
            {liked ? (
              <Tooltip title="Unlike">
                <IconButton
                  className={classes.likedButton}
                  onClick={handleReplyUnlike}
                  size="small"
                >
                  <ThumbUpAltIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Click to like this reply">
                <IconButton
                  className={classes.likeButton}
                  onClick={handleReplyLike}
                  size="small"
                >
                  <ThumbUpAltIcon />
                </IconButton>
              </Tooltip>
            )}
            <Typography >
              {       
                replyLikesCount > 0
                &&
                <Tooltip title="Click to see who liked this reply">
                  <Link 
                    className={classes.count}
                    href="#" 
                    onClick={handleDisplayLikes}
                    variant="subtitle1"
                  >
                    {replyLikesCount} likes
                  </Link>
                </Tooltip>       
              }
            </Typography>

            <Tooltip 
              aria-label="Comments"
              title="Click to display / hide reply input"
            >
              <IconButton 
                aria-label="add to shopping cart"
                className={classes.replyButton}
                onClick={() => handleClickReplyReplyIcon(reply.createUserName)}
                size="small"
              >
                <ChatIcon style={{ fontSize: 22 }}/>
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <Divider className={classes.divider} />    
    </Fragment>
  );
};

McReplyCard.propTypes = {
  className: PropTypes.string,
  onClickReplyIcon: PropTypes.func,
  onDeleteReply: PropTypes.func,
  onUpdateEditedReply: PropTypes.func,
  reply: PropTypes.object.isRequired
};

export default McReplyCard;
