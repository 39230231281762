import React, { 
  useState, 
  useEffect, 
  Fragment}         from 'react';
import useRouter    from 'utils/useRouter';
import { 
  useDispatch,
  useSelector }     from 'react-redux';
import _            from 'lodash';
import PropTypes    from 'prop-types';
import Cookies      from 'js-cookie';

import axios                           from 'utils/mcaxios';
// import { McPage, McWaitingIcon }                from 'components';
import { 
  SUCCESS_AUTO_LOGIN_CODE}             from 'store/statusCode';
import { 
  ROUTER_TO_HOME_PATH }                from 'store/routerPath';
import { 
  POST_AUTO_LOGIN_BY_COOKIE_URL}       from 'store/rest-api';
import { 
  LoginUserSuccess, 
  FetchSchoolsInfoSuccess, 
  FetchPostCategoriesSuccess }         from 'store/actions';
import { 
  MC_MAX_DAYS_AUTO_LOGIN_COOKIE }      from 'mcconfig';
import { authService } from 'service';
// import useStyles                                from './AutoLogin.style';
const COOKIE_TOKEN = 'COOKIE_TOKEN';
const AutoLogin = (props) => {
  // const classes = useStyles();
  const { redirect} = props;
  const dispatch = useDispatch();
  // let mountedRef = useRef(true)
  const router = useRouter();
  const auth = useSelector(state => state.auth);
  const [verified, setVerified] = useState(false);
  const [token, setToken] = useState();
  const [userInfo, setUserInfo] = useState({});
  const [schoolsInfo, setSchoolsInfo] = useState([]);
  const [postCategoriesInfo, setPostCategoriesInfo] = useState([]);
  const [autoLogining, setAutoLogining] = useState(false);

  useEffect(() => {
    const autoLogin = (cookieToken) => {
      authService
        .autoLogin(cookieToken)
        .then((response) => {
          const {
            status,
            // message,
            token,
            userInfo,
            schoolsInfo,
            postCategoriesInfo,
          } = response;
          if (status === SUCCESS_AUTO_LOGIN_CODE) {
            // go to main stack screen
            localStorage.setItem(COOKIE_TOKEN, token);
            sessionStorage.setItem(COOKIE_TOKEN, token);
            dispatch( LoginUserSuccess( token, userInfo ) );
            dispatch( FetchSchoolsInfoSuccess(schoolsInfo));
            dispatch( FetchPostCategoriesSuccess(postCategoriesInfo) );
          } else {
            /* empty */
          }
        })
        .catch(() => {});
    };
    const coookieToken = sessionStorage.getItem(COOKIE_TOKEN);
    if (!coookieToken) {
      // console.log("not logged in", coookieToken);
      const tokenTemp = localStorage.getItem(COOKIE_TOKEN);
      if (tokenTemp) {
        autoLogin(tokenTemp);
      }
    } else { /*console.log("logged in", coookieToken);*/ }
  }, [dispatch]);
  
  return (
    <Fragment>
      {
        autoLogining
        // &&
        // <div className={classes.waitingDiv}>
        //   <McWaitingIcon 
        //     className={classes.waitingIcon}
        //     height="222"
        //     loading
        //     width="222"
        //   />
        // </div>
      }
    </Fragment>
  );
};
AutoLogin.propTypes = {
  redirect: PropTypes.string
};
export default AutoLogin;