import * as actionTypes from './actionTypes';

/* [ 1 ], Login */
export const LoginUserSuccess = (token, userInfo) => {
  return {
    type: actionTypes.LOGIN_USER_SUCCESS,
    token: token,
    userInfo: userInfo,
  };
};

export const SetNotificationTabBadgeNumber = (notifications) => ({
  type: actionTypes.SET_NOTIFICATION_TAB_BADGE_NUMBER,
  notifications: notifications,
});
export const SetFeedTabBadgeNumber = (feeds) => ({
  type: actionTypes.SET_FEED_TAB_BADGE_NUMBER,
  feeds: feeds,
});
export const SetShortTabBadgeNumber = (shorts) => ({
  type: actionTypes.SET_SHORT_TAB_BADGE_NUMBER,
  shorts: shorts,
});
export const updateAvatarSuccess = (avatarUrl) => {
  return {
    type: actionTypes.UPDATE_AVATAR_SUCCESS,
    avatarUrl: avatarUrl + '?' + new Date().getTime(),
  };
};
export const updateAvatarFailed = () => {
  return {
    type: actionTypes.UPDATE_AVATAR_FAILED,
  };
};

export const CleanUserInfos = () => {
  return {
    type: actionTypes.SIGNOUT_USER_SUCCESS,
  };
};

/* [ 1 ], Login */
export const UpdateAuthNames = (firstName, lastName) => {
  return {
    type: actionTypes.RENEW_USER_SUCCESS,
    firstName: firstName,
    lastName: lastName,
    fullName: firstName + ' ' + lastName,
  };
};
/* [ 1 ], Login */
export const UpdateAvatarUrl = (avatarUrl) => {
  return {
    type: actionTypes.UPDATE_AVATAR_SUCCESS,
    avatarUrl: avatarUrl,
  };
};

export const AgreeCookie = () => {
  return {
    type: actionTypes.AGREE_COOKIE,
  };
};

export const PopulateFsUserInfo = (fsUserInfo) => {
  return {
    type: actionTypes.POPULATE_FS_USER_INFO,
    fsUserInfo: fsUserInfo,
  };
};
//=================[ oild ]======================
export const CleanUserinfo = () => {
  return {
    type: actionTypes.SIGNOUT_USER_SUCCESS,
  };
}

/**
export const SetRestrictUsers = (
  mutedUserFsIds,
  blockedUserFsIds,
  blockerFsIds,
) => {
  return {
    type: actionTypes.SET_RESTRICT_USERS,
    mutedUserFsIds: mutedUserFsIds,
    blockedUserFsIds: blockedUserFsIds,
    blockerFsIds: blockerFsIds,
  };
}; */
// export const SetChatChannelUnreadMessageBadgeNumber = (
//   channelId,
//   badgeNumber,
// ) => ({
//   type: actionTypes.SET_CHAT_CHANNELS_BADGE_NUMBER,
//   channelId: channelId,
//   badgeNumber: badgeNumber,
// });
/* export const LoggedInAgora = (status) => {
  return {
    type: actionTypes.LOGIN_AGORA_STATUS_START,
    status: status
  };
} */
/* [  ], Update Avatar Url */
// export const UpdateAvatar = (formdata) => (dispatch) => {
// };
/* [  ], Update Avatar Url
export const Signout = () => dispatch => {
  // When user close  or refresh window, record the time for leaving chat room,
  // it's for fix the problem of class room chat no offline messages, like peer chat
  // We need to fetch the new chat room messages from db based on logout/close window/refresh window time
    {})
    .then(function (response) {
      // Clean local storage 
      // localStorage.setItem('token', null);
      localStorage.removeItem('token');
      localStorage.removeItem('cacheKeywords');

      // Set login info to empty
      dispatch((signoutUserinfo()));

      // Clean cookie
      Cookies.remove('cookie-token');
    })
    .catch(function (error) {
    });
} */

// import * as actionTypes                                 from './actionTypes';
// import { POST_UPLOAD_AVATAR_URL }                       from 'store/rest-api';
// import axios from 'utils/mcaxios';
// import { SUCCESS_UPDATE_AVATAR_CODE }                   from 'store/statusCode';

/* [ 1 ], Login */
// export const LoginUserSuccess = ( token, userInfo) => {
//   return {
//     type: actionTypes.LOGIN_USER_SUCCESS,
//     token: token,
//     userInfo: userInfo,
//   };
// }
/* export const LoggedInAgora = (status) => {
  return {
    type: actionTypes.LOGIN_AGORA_STATUS_START,
    status: status
  };
} */
/* [  ], Update Avatar Url */
// export const UpdateAvatar = (formdata) => dispatch => {

//   axios.post(POST_UPLOAD_AVATAR_URL, formdata )
//     .then(function (response) {
//       const {status, avatarUrl} = response.data;
//       if(status === SUCCESS_UPDATE_AVATAR_CODE){
//         dispatch(updateAvatarSuccess(avatarUrl));
//       }else{
//         dispatch(updateAvatarFailed());
//       }
//     })
//     .catch(function (error) {
//       dispatch(updateAvatarFailed());
//       console.log(error);
//     });
// }

// export const updateAvatarSuccess = (avatarUrl) => {
//   return {
//     type: actionTypes.UPDATE_AVATAR_SUCCESS,
//     avatarUrl: avatarUrl + '?' + new Date().getTime()
//   };
// }
// export const updateAvatarFailed = () => {
//   return {
//     type: actionTypes.UPDATE_AVATAR_FAILED,
//   };
// }

// export const CleanUserinfo = () => {
//   return {
//     type: actionTypes.SIGNOUT_USER_SUCCESS,
//   };
// }
/* [  ], Update Avatar Url
export const Signout = () => dispatch => {
  // When user close  or refresh window, record the time for leaving chat room,
  // it's for fix the problem of class room chat no offline messages, like peer chat
  // We need to fetch the new chat room messages from db based on logout/close window/refresh window time
  axios.post(POST_LOG_LEFT_CHATROOM_URL, {});
  // Remove token in backend
  // axios.post(POST_SIGNOUT_USER_URL, {} );
  axios.post(POST_SIGNOUT_USER_URL,
    {})
    .then(function (response) {
      // Clean local storage 
      // localStorage.setItem('token', null);
      localStorage.removeItem('token');
      localStorage.removeItem('cacheKeywords');

      // Set login info to empty
      dispatch((signoutUserinfo()));

      // Clean cookie
      Cookies.remove('cookie-token');
    })
    .catch(function (error) {
      console.log(error);
    });
} */
// export const AgreeCookie = () =>  {
//   return {
//     type: actionTypes.AGREE_COOKIE,
//   };
// }
// // used for first login, after selected current school, autoloading current school's posts
export const SetFetchMySchoolsPostsFlag = (flag) =>  {
  return {
    type: actionTypes.FETCH_MY_SCHOOLS_POSTS,
    flag: flag
  };
}
export const UpdateAvatar = (formdata) => dispatch => {

  // axios.post(POST_UPLOAD_AVATAR_URL, formdata )
  //   .then(function (response) {
  //     const {status, avatarUrl} = response.data;
  //     if(status === SUCCESS_UPDATE_AVATAR_CODE){
  //       dispatch(updateAvatarSuccess(avatarUrl));
  //     }else{
  //       dispatch(updateAvatarFailed());
  //     }
  //   })
  //   .catch(function (error) {
  //     dispatch(updateAvatarFailed());
  //     console.log(error);
  //   });
}