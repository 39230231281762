import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    alignItems: 'center',

  },

  inputPlaceholder: {
    height: '320px',
    maxHeight: '480px',
    padding: theme.spacing(0, 1),
    fontSize:'0.9em'
  },
  inputCount: {
    textAlign: 'right',
    paddingRight: theme.spacing(1),
  },
  error: {
    color: colors.red[500],
  }
}));

export default useStyles;