import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  chatroombar: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0.5),
    position: 'fixed',

    [theme.breakpoints.down('sm')]: {
      left: 5,
      bottom: 48
    },
    [theme.breakpoints.up('md')]: {
      bottom: 72,
      right: 80,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      left: 5,
      bottom: 48
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      left: 5,
      bottom: 48
    },
    zIndex: 500,
  },
  chatroombox: {
    zIndex: 1000,

    [theme.breakpoints.between('xs', 'sm')]: {
      top: 0,
      left: 0,
    },
    [theme.breakpoints.down('xs')]: {
      left: 0,
      top: 0,
    },
  },
  chatroomcontent: {
    minWidth: theme.spacing(45),
    width: '360px',
    [theme.breakpoints.between('xs', 'sm')]: {
      bottom: 5,
    },
    [theme.breakpoints.down('xs')]: {
      bottom: 5,
    },
  },
  chip: {
    height: theme.spacing(4.5),
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    border: '1px solid #8BC34A',
    backgroundColor: '#c5e2c9',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  avatar: {
    color: colors.green[500],
  },
  inputMaxWidth: {
    maxWidth: '250px',
  }, 

}));
export default useStyles;