import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    position: 'relative',
    width: '100%'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 10,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  suggestion: {
    display: 'block',
  },
  sectionTitle:  {
    padding: '5px',
    fontSize: '15px',
    color: '#777'
  },
}));

export default useStyles;