
// export const POST_SEARCH_POST_URL =              'es-api/mcsearches/search/posts'

// export const POST_SEARCH_USER_URL =              'es-api/mcsearches/search/users'

// export const POST_SEARCH_POST_URL =              'es-api/mcsearches/search/posts'

// export const POST_SEARCH_USER_URL =              'es-api/mcsearches/search/users'

export const POST_SEARCH_POST_URL               = 'post-api/mcposts/posts/search';

export const POST_SEARCH_USER_URL               = 'user-api/mcaccounts/search';