import axios from '../../utils/mcaxios';
import {
  GET_QUERY_USER_SCHOOLS_POSTS_URL,
  DELETE_DELETE_POST_URL,
  PUT_UPDATE_POST_STATUS_URL,
  POST_ADD_POST_LIKE_URL,
  POST_CREATE_POST_URL,
  GET_QUERY_MY_POSTS_URL,
  GET_QUERY_MY_COMMENTS_URL,
  GET_QUERY_MY_LIKED_POST_URL,
  GET_QUERY_USER_PROFILE_POSTS_URL,
  GET_QUERY_SINGLE_POST_URL,
  GET_QUERY_POST_COMMENTS_URL,
  POST_CREATE_POST_COMMENT_URL,
  DELETE_DELETE_COMMENT_URL,
  GET_QUERY_POST_LIKES_URL,
  POST_SEARCH_POST_URL,
} from '../../store/rest-api';

const createPost = async (data) => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  try {
    const result = await axios.post(POST_CREATE_POST_URL, data);
    return result.data;
  } catch (error) { /* empty */ }
};

const findMySchoolPosts = async (skip) => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  try {
    const result = await axios.get(
      GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip),
    );
    return result.data;
  } catch (error) { /* empty */ }
};

const deletePost = async (post) => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  try {
    const result = await axios.delete(DELETE_DELETE_POST_URL, {
      data: {
        postId: post.id,
      },
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const updatePostStaus = async (item, status) => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  try {
    const result = await axios.put(PUT_UPDATE_POST_STATUS_URL, {
      postId: item.id,
      status,
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const pressLikePost = async (item, isLiked) => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  try {
    const result = await axios.post(POST_ADD_POST_LIKE_URL, {
      postId: item.id,
      count: isLiked ? -1 : 1,
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const findMyPosts = async (skip) => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  try {
    const result = await axios.get(
      GET_QUERY_MY_POSTS_URL.concat('/').concat(skip),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const findMyComments = async (skip) => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  try {
    const result = await axios.get(
      GET_QUERY_MY_COMMENTS_URL.concat('/').concat(skip),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const findMyLikes = async (skip) => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  try {
    const result = await axios.get(
      GET_QUERY_MY_LIKED_POST_URL.concat('/').concat(skip),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const findUserPosts = async (userId, skip) => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  try {
    const result = await axios.get(
      GET_QUERY_USER_PROFILE_POSTS_URL.concat('/')
        .concat(userId)
        .concat('/')
        .concat(skip),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const getSinglePost = async (postId) => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  try {
    const result = await axios.get(
      GET_QUERY_SINGLE_POST_URL.concat('/').concat(postId),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const findPostComments = async (postId, skip) => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  try {
    const result = await axios.get(
      GET_QUERY_POST_COMMENTS_URL.concat('/')
        .concat(postId)
        .concat('/')
        .concat(skip),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const addPostComment = async (postId, comment) => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  try {
    const result = await axios.post(POST_CREATE_POST_COMMENT_URL, {
      postId,
      commentContent: comment,
    });
    return result.data;
  } catch (error) { /* empty */ }
};

const deletePostComment = async (commentId) => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  try {
    const result = await axios.delete(DELETE_DELETE_COMMENT_URL, {
      data: {
        commentId,
      },
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const findPostLikes = async (postId, skip) => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  try {
    const result = await axios.get(
      GET_QUERY_POST_LIKES_URL.concat('/')
        .concat(postId)
        .concat('/')
        .concat(skip),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const discoverSchoolPosts = async (skip, searchScope, schoolId, keywords) => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  try {
    const result = await axios.post(POST_SEARCH_POST_URL, {
      skip,
      searchScope,
      schoolId,
      keywords,
    });
    return result.data;
  } catch (error) { /* empty */ }
};
export const postService = {
  findMySchoolPosts,
  deletePost,
  updatePostStaus,
  pressLikePost,
  createPost,
  findMyPosts,
  findMyComments,
  findMyLikes,
  findUserPosts,
  getSinglePost,
  findPostComments,
  addPostComment,
  deletePostComment,
  findPostLikes,
  discoverSchoolPosts,
};
// findMySchoolLatestPosts,
// const findMySchoolLatestPosts = async (skip) => {
//   // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
//   try {
//     let result = await axios.get(
//       GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip),
//     );
//     return result.data;
//   } catch (error) { /* empty */ }
// };