import axios from 'axios';

const url = 'https://www.micolleger.com/';
// const url = 'http://10.2.0.141:9000/';
// const url = 'http://192.168.2.16:9000/';
const instance = axios.create({
  baseURL: url,
});
/* Do something before request is sent */
instance.interceptors.request.use(
  async (config) => {
    // console.log('===========[ NOAUTH REQUEST ]============');
    // console.log(config);
    return config;
  },
  (error) => {
    // console.log(error);
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log('===========[ NOAUTH RESPONSE ]============');
    // console.log(response.data);
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response) {
      // console.error(error);
      switch (error.response.status) {
        case 400:
          // console.log('400', error);
          break;
        case 401:
          // console.log('401', error);
          break;
        case 500:
          // console.log('500', error);
          // Snackbar.show({
          //   text: McLocalized('Service not available now, please try it later'),
          //   duration: Snackbar.LENGTH_LONG,
          //   backgroundColor: 'orange',
          // });
          break;
        case 503:
          // console.log('503', error);
          // Snackbar.show({
          //   text: McLocalized(
          //     'Service not available now, please try again later',
          //   ),
          //   duration: Snackbar.LENGTH_LONG,
          //   backgroundColor: 'orange',
          // });
          break;
        default:
          // console.log('default', error);
          break;
      }
    }
    return Promise.reject(error);
  },
);

export default instance;
