import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useRouter from 'utils/useRouter';
import _ from 'lodash';
import Cookies                            from 'js-cookie';

import axios from 'utils/mcaxios';
import { 
  POST_AUTO_LOGIN_BY_COOKIE_URL } from 'store/rest-api';
import { SUCCESS_AUTO_LOGIN_CODE } from 'store/statusCode';
import { ROUTER_TO_HOME_PATH } from 'store/routerPath';
import { MC_MAX_DAYS_AUTO_LOGIN_COOKIE }        from 'mcconfig';
import { LoginUserSuccess, 
  FetchSchoolsInfoSuccess, 
  FetchPostCategoriesSuccess } from 'store/actions';
import { McWaitingIcon } from 'components';

const McAuthGuard = () => {

  // Example of user roles: ['GUEST', 'USER', 'ADMIN'];
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const router = useRouter();

  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [token, setToken] = useState();
  const [userInfo, setUserInfo] = useState({});
  const [schoolsInfo, setSchoolsInfo] = useState([]);
  const [postCategoriesInfo, setPostCategoriesInfo] = useState([]);


  /* When visit any menu within <Dashboard>, check whether logged in. 
  * 1, if not logged in, check whether there's any token.
  * 2, if there's token, but not logged in, it's pasted url in browser
  * 3, fetch user info and put into reducer, 
  * otherwise, directed to log in page
  */
  useEffect(() => {
    let mounted = true;
    function autoLogin(cookieToken) {
      setLoading(true);
      axios.post(POST_AUTO_LOGIN_BY_COOKIE_URL,
        {
          'cookie-token': cookieToken
        }).then( response => {
        const {status, token, userInfo, schoolsInfo, postCategoriesInfo } = response.data;
        if(status === SUCCESS_AUTO_LOGIN_CODE){
          setToken(token);
          setUserInfo({...userInfo});
          setSchoolsInfo([...schoolsInfo]);
          setPostCategoriesInfo([...postCategoriesInfo]);
          Cookies.set('cookie-token', cookieToken, { expires: MC_MAX_DAYS_AUTO_LOGIN_COOKIE });
          setVerified(true);
        }else{
          // router.history.push(ROUTER_TO_LOGIN_PATH);
        }
        setLoading(false);
      }).catch( error => {
        console.log('error ', error);
        setLoading(false);
        // router.history.push(ROUTER_TO_LOGIN_PATH);
      });

    }
    if(mounted){
      const cookieToken = Cookies.get('cookie-token');
      // if (auth.loggedIn === true && !_.isEmpty(auth.token)) {
      if(!_.isEmpty(cookieToken) && !auth.loggedIn && _.isEmpty(auth.token)){
        autoLogin(cookieToken);
      }else{
        // router.history.push(ROUTER_TO_LOGIN_PATH);
      }
    }
    return () => { mounted = false }
  }, [])// eslint-disable-line

  useEffect(() => {
    let mounted = true;
    async function init() {
      localStorage.setItem('token', token);
      await initializeUserInfo(token, userInfo, schoolsInfo, postCategoriesInfo);
      router.history.replace(ROUTER_TO_HOME_PATH);
    }  
    if(mounted && verified){
      init();
    }
    return () => { mounted = false }
  }, [verified])// eslint-disable-line

  const initializeUserInfo = (token, userInfo, schoolsInfo, postCategoriesInfo) => {
    dispatch( LoginUserSuccess( token, userInfo ) );
    dispatch( FetchSchoolsInfoSuccess(schoolsInfo));
    dispatch( FetchPostCategoriesSuccess(postCategoriesInfo) );
  }

  // When user close  or refresh window, record the time, 
  // it's for fix the problem of class room chat no offline messages, like peer chat
  // We need to fetch the new chat room messages from db based on logout time
  // const handleCloseWin = () => {
  //   if (auth.loggedIn === true && !_.isEmpty(auth.token)) {
  //     axios.post(POST_LOG_LEFT_CHATROOM_URL, {});
  //   }
  // }
  return (
    <Fragment>      
      {
        loading && 
          <McWaitingIcon 
            height="38"
            loading
            width="38"
          />
      }
    </Fragment>
  )
};

McAuthGuard.propTypes = {
  // children: PropTypes.node,
  // roles: PropTypes.array.isRequired
};

McAuthGuard.defaultProps = {
  // roles: []
};

export default McAuthGuard;
