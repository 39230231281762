import React, 
{ useState, 
  useEffect, 
  Fragment }                  from 'react';
import { useSelector }        from 'react-redux';
import PropTypes              from 'prop-types';
import _                      from 'lodash';


const McAuthDisplay = props => {
  const { requireSigned, children } = props;
  const {loggedIn, token} = useSelector(state => state.auth);

  const [isDisplayCompoment, setIsDisplayCompoment] = useState(false)
  // when require sign in, only signed component will display
  // when doesn't require sign in, just display
  useEffect(() => {
    // console.log('McAuthDisplay:', token)
    let mounted = true;
    if( mounted ){
      if(requireSigned){
        if(loggedIn && !_.isEmpty(token)){    // if signed, display component
          setIsDisplayCompoment(true);
        }else{
          setIsDisplayCompoment(false);
        }
      }else{
        if(loggedIn && !_.isEmpty(token)){  // if not signed, display component
          setIsDisplayCompoment(false);
        }else{
          setIsDisplayCompoment(true);
        }
      }
    }
    return () => { mounted = false }
  }, [token])// eslint-disable-line
  return (
    <Fragment>
      {
        isDisplayCompoment
        &&
        children
      }
    </Fragment>
  )
};

McAuthDisplay.propTypes = {
  children:       PropTypes.node.isRequired,
  requireSigned:  PropTypes.bool.isRequired
};

export default McAuthDisplay;
