import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useRouter from 'utils/useRouter';
import PropTypes from 'prop-types';

import Menu from '@material-ui/core/Menu';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';

import { NotificationList, EmptyList } from './components';
import useStyles from './NotificationsPopover.style';
import { McButtonText } from 'components';
import { ROUTER_TO_NOTIFICATION_PATH } from 'store/routerPath';
const NotificationsPopover = props => {
  const { notifications, onClose, open, anchorEl} = props;
  const classes = useStyles();
  const router = useRouter();

  const handleNavToNotifications = () => {
    onClose();
    router.history.push(ROUTER_TO_NOTIFICATION_PATH);
  }
  return (
    <Menu
      anchorEl={anchorEl}
      className={classes.menu}
      keepMounted
      onClose={onClose}
      open={open}
      transformOrigin={{
        vertical: -55,
        horizontal: 'right'
      }}
    >

      <div className={classes.root}>
        <CardHeader title="Notifications" />
        <Divider />
        {notifications && notifications.length > 0 ? (
          <NotificationList 
            notifications={notifications} 
            onClick={handleNavToNotifications}
          />
        ) : (
          <EmptyList />
        )}
        <Divider />
        <CardActions className={classes.actions}>
          <McButtonText
            component={RouterLink}
            onClick={handleNavToNotifications}
            size="small"
            to="#"
          >
            See all
          </McButtonText>
        </CardActions>
      </div>
    </Menu>
    
  );
};

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  className: PropTypes.string,
  notifications: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default NotificationsPopover;
