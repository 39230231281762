import * as actionTypes from '../actions/actionTypes';
const initialState = {
  token: null,
  loggedIn: false,
  loginUserInfo: {
    id: '',
    firstName: null,
    lastName: null,
    fullName: null,
    avatarUrl: null,
    loginTimestamp: new Date().getTime(), // use for my avatar url, only load the latest avatar when first load
  },
  badgeNumber: {
    notification: [],
    feed: [],
    short: [],
  },
  cookieConsent: null,
  // mutedUsers: [],
  // blockedUsers: [],
  // blockers: [],
  fsUserInfo: {},
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SHORT_TAB_BADGE_NUMBER: {
      return {
        ...state,
        badgeNumber: {
          ...state.badgeNumber,
          short: [...action.shorts],
        },
      };
    }
    case actionTypes.SET_FEED_TAB_BADGE_NUMBER: {
      return {
        ...state,
        badgeNumber: {
          ...state.badgeNumber,
          feed: [...action.feeds],
        },
      };
    }
    case actionTypes.SET_NOTIFICATION_TAB_BADGE_NUMBER: {
      return {
        ...state,
        badgeNumber: {
          ...state.badgeNumber,
          notification: [...action.notifications],
        },
      };
    }
    /* Logged in Successfully */
    case actionTypes.LOGIN_USER_SUCCESS: {
      return {
        ...state,
        token: action.token,
        loggedIn: true,
        loginUserInfo: action.userInfo,
      };
    }
    /* Update Avatar Success */
    case actionTypes.UPDATE_AVATAR_SUCCESS: {
      return {
        ...state,
        loginUserInfo: {
          ...state.loginUserInfo,
          avatarUrl: action.avatarUrl,
        },
      };
    }
    /* Update Avatar Failed */
    case actionTypes.UPDATE_AVATAR_FAILED: {
      return {
        ...state,
        loginUserInfo: {
          ...state.loginUserInfo,
          avatarUrl: state.loginUserInfo.avatarUrl,
        },
      };
    }
    /* Update Avatar Failed */
    case actionTypes.SIGNOUT_USER_SUCCESS: {
      return {
        token: null,
        loggedIn: false,
        loginUserInfo: {
          id: '',
          firstName: null,
          lastName: null,
          fullName: null,
          avatarUrl: null,
          loginTimestamp: null,
        },
        badgeNumber: {},
        cookieConsent: null,
      };
    }
    /* Update Name */
    case actionTypes.RENEW_USER_SUCCESS: {
      return {
        ...state,
        loginUserInfo: {
          ...state.loginUserInfo,
          firstName: action.firstName,
          lastName: action.lastName,
          fullName: action.fullName,
        },
      };
    }
    /* Logged in Successfully */
    case actionTypes.AGREE_COOKIE: {
      return {
        ...state,
        cookieConsent: 'true',
      };
    }
    /* restrict users
    case actionTypes.SET_RESTRICT_USERS: {
      return {
        ...state,
        mutedUsers: [...action.mutedUserFsIds],
        blockedUsers: [...action.blockedUserFsIds],
        blockers: [...action.blockerFsIds],
      };
    }*/
    case actionTypes.POPULATE_FS_USER_INFO: {
      return {
        ...state,
        fsUserInfo: {
          ...action.fsUserInfo,
        },
      };
    }
    /* Logged in Successfully 
        case actionTypes.SET_CHAT_CHANNELS_BADGE_NUMBER: {
      let chatMessageBadges = state.badgeNumber.chatMessage;
      // temp[action.channelId] = action.badgeNumber;
      const index = chatMessageBadges.findIndex(
        (chatMessageBadge) => chatMessageBadge.id === action.channelId,
      );

      if (index === -1) {
        chatMessageBadges.push({
          id: action.channelId,
          count: action.badgeNumber,
        });
      } else {
        chatMessageBadges[index] = {
          id: action.channelId,
          count: action.badgeNumber,
        };
      }
      return {
        ...state,
        badgeNumber: {
          ...state.badgeNumber,
          chatMessage: [...chatMessageBadges],
        },
      };
    }
    case actionTypes.STORE_RTM_CLIENT: {
      return {
        ...state,
        rtm: action.rtm
      };
    }
    */
    /* Fetch roles 
    case actionTypes.FETCH_ROLES_SUCCESS: {
      return {
        ...state,
        roles: action.roles
      };
    }*/
    /* Register
    case actionTypes.REGISTER_USER_START: {
      return {
        ...state,
        registerLoading: true
      };
    }
    case actionTypes.REGISTER_USER_FAILED: {
      return {
        ...state,
        registerLoading: false,
        registerStatus: action.status,
        registerMessage: action.message,
        registerTimestamp: action.timestamp
      };
    }
    case actionTypes.REGISTER_USER_SUCCESS: {
      return {
        ...state,
        registerLoading: false,
        registerStatus: action.status,
        registerMessage: action.message,
        registerMessageAccount: action.account,
        registerMessageFname: action.fname,
        registerRedirectPath: action.redirectPath
      };
    } */
    /* Login
    case actionTypes.LOGIN_AGORA_STATUS_START: {
      return {
        ...state,
        loggedAgora: action.status
      };
    }
    case actionTypes.LOGIN_USER_START: {
      return {
        ...state,
        loginLoading: true
      };
    }
    case actionTypes.LOGIN_USER_FAILED: {
      return {
        ...state,
        loginStatus: action.status,
        loginMessage: action.message,
        loginTimestamp: action.timestamp,
        loginLoading: false,
        loginRedirectPath: null
      };
    }
 */
    /* Resend Activation Email 
    case actionTypes.RESEND_ACTIVATION_EMAIL_START: {
      return {
        ...state,
        resendActivationLoading: true
      };
    }
    case actionTypes.RESEND_ACTIVATION_EMAIL_SUCCESS: {
      return {
        ...state,
        resendActivationLoading: false,
        resendActivationStatus: action.status,
        resendActivationMessage: action.message,
        resendActivationTimestamp: action.timestamp,
      };
    }
    case actionTypes.RESEND_ACTIVATION_EMAIL_FAILED: {
      return {
        ...state,
        resendActivationLoading: false,
        resendActivationStatus: action.status,
        resendActivationMessage: action.message,
        resendActivationTimestamp: action.timestamp,
      };
    }*/
    /* Resend Activation Email 
    case actionTypes.SEND_FORGET_PASSWORD_EMAIL_START: {
      return {
        ...state,
        sendForgetPasswordEmailLoading: true
      };
    }
    case actionTypes.SEND_FORGET_PASSWORD_EMAIL_SUCCESS: {
      return {
        ...state,
        sendForgetPasswordEmailLoading: false,
        sendForgetPasswordEmailStatus: action.status,
        sendForgetPasswordEmailMessage: action.message,
        sendForgetPasswordEmailTimestamp: action.timestamp,
      };
    }
    case actionTypes.SEND_FORGET_PASSWORD_EMAIL_FAILED: {
      return {
        ...state,
        sendForgetPasswordEmailLoading: false,
        sendForgetPasswordEmailStatus: action.status,
        sendForgetPasswordEmailMessage: action.message,
        sendForgetPasswordEmailTimestamp: action.timestamp,
      };
    }*/
    /* Change Password 
    case actionTypes.CHANGE_PASSWORD_START: {
      return {
        ...state,
        changePasswordLoading: true
      };
    }
    case actionTypes.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePasswordLoading: false,
        changePasswordStatus: action.status,
        changePasswordMessage: action.message,
        changePasswordTimestamp: action.timestamp,
      };
    }
    case actionTypes.CHANGE_PASSWORD_FAILED: {
      return {
        ...state,
        changePasswordLoading: false,
        changePasswordStatus: action.status,
        changePasswordMessage: action.message,
        changePasswordTimestamp: action.timestamp,
      };
    }*/

    /* Autocomplete School 
    case actionTypes.REGISTER_AUTOCOMPLETE_SCHOOL_START: {
      return {
        ...state,
        autocompleteSchoolLoading: true
      };
    }
    case actionTypes.REGISTER_AUTOCOMPLETE_SCHOOL_SUCCESS: {
      return {
        ...state,
        autocompleteSchoolLoading: false,
        suggestions: action.suggestions,
        inputValue: action.value
      };
    }
    case actionTypes.REGISTER_AUTOCOMPLETE_SCHOOL_FAILED: {
      return {
        ...state,
        autocompleteSchoolLoading: false,
        suggestions: [],
        inputValue: null
      };
    }*/

    default: {
      return state;
    }
  }
};

export default authReducer;
