import React                            from 'react';
import PropTypes                        from 'prop-types';
import clsx                             from 'clsx';
import _                                from 'lodash';

import CheckCircleIcon      from '@material-ui/icons/CheckCircle';
import ErrorIcon            from '@material-ui/icons/Error';
import InfoIcon             from '@material-ui/icons/Info';
import CloseIcon            from '@material-ui/icons/Close';
import IconButton           from '@material-ui/core/IconButton';
import SnackbarContent      from '@material-ui/core/SnackbarContent';
import WarningIcon          from '@material-ui/icons/Warning';

import useStyles                from './McSnackbarContentWrapper.style';
import { McButtonText }         from 'components'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};
const McSnackbarContentWrapper = (props) => {
  const classes = useStyles();
  const {buttonText, handleClick, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];
  let actionButton = null;
  if(!_.isEmpty(buttonText)){
    actionButton =  
      <McButtonText 
        key="undo" 
        onClick={handleClick}
        size="small" 
      >
        {buttonText}
      </McButtonText>
  }
  return (
    <SnackbarContent
      action={[
        actionButton,
        <IconButton 
          aria-label="close" 
          color="inherit" 
          key="close" 
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      aria-describedby="client-snackbar"
      className={clsx(classes[variant], className, classes.root)}
      message={
        <span 
          className={classes.message} 
          id="client-snackbar" 
        >
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      {...other}
    />
  );
}
McSnackbarContentWrapper.propTypes = {
  buttonText: PropTypes.string,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};
export default McSnackbarContentWrapper
