import React, 
{ Fragment, 
  Suspense  }               from 'react';
import { useLocation }      from 'react-router-dom'
import { renderRoutes }     from 'react-router-config';
import PropTypes            from 'prop-types';

import { LinearProgress }       from '@material-ui/core';

import useStyles                    from './Public.style';
import { McTopBar, 
  McSnackbar }                      from 'components';
import AutoLogin                    from 'views/AutoLogin';
const Public = props => {
  const { route } = props;
  const classes = useStyles();

  return (
    <Fragment>
      <AutoLogin redirect={useLocation().pathname}/>
      <McTopBar 
        className={classes.topBar}
        onOpenNavBarMobile={null}
      />
      <div className={classes.container}>
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </main>
      </div>
      {/* <Bottombar /> */}
      <McSnackbar /> 

    </Fragment>
  );
};

Public.propTypes = {
  location: PropTypes.object,
  route: PropTypes.object
};

export default Public;
