
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const McHeader = props => {
  const { title, className,  ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography
        component="h2"
        variant="overline"
      >
        { title }
      </Typography>
    </div>
  );
};

McHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string
};

export default McHeader;
