/* McPostProviderController */
export const POST_CREATE_POST_URL               = 'post-api/mcposts/posts'; // {AddPostFormVo}

export const GET_QUERY_USER_SCHOOLS_POSTS_URL   = 'post-api/mcposts/posts'; //{skip}

export const GET_QUERY_ALL_SCHOOLS_POSTS_URL    = 'post-api/mcposts/universal-posts'; //{skip}

export const GET_QUERY_OTHER_SCHOOLS_POSTS_URL  = 'post-api/mcposts/other-posts'; //{skip}

export const GET_QUERY_MY_POSTS_URL             = 'post-api/mcposts/posts/myposts'; //{skip}

export const POST_ADD_POST_LIKE_URL             = 'post-api/mcposts/posts/likes'; //{postId: xxx, count: 0}

export const GET_QUERY_POST_COMMENTS_URL        = 'post-api/mcposts/posts/comments'; //{postId}/{skip}

export const POST_CREATE_POST_COMMENT_URL       = 'post-api/mcposts/posts/comments'; //{PostCommentFormVo}

// export const GET_QUERY_POST_IMAGES_URL          = 'post-api/mcposts/posts/images'; //{postId}

export const GET_QUERY_POST_URL                 = 'post-api/mcposts/posts/editui'; //{postId}

export const PUT_UPDATE_POST_URL                = 'post-api/mcposts/posts'; //{EditPostFormVo}

export const DELETE_DELETE_POST_URL             = 'post-api/mcposts/posts'; //{postId: post.postId}

export const PUT_UPDATE_POST_STATUS_URL         = 'post-api/mcposts/posts/status'; //{formVo}

export const GET_QUERY_USER_PROFILE_POSTS_URL   = 'post-api/mcposts/posts/user-profile'; //{formVo}


/* McPostCommentProviderController */

export const POST_ADD_COMMENT_LIKE_URL          = 'post-api/mcposts/comments/likes'; //{commentId: xxx, count: 0}

export const GET_FETCH_COMMENT_REPLY_URL        = 'post-api/mcposts/comments/replies'; //{commentId}/{skip}

export const POST_ADD_COMMENT_REPLY_URL         = 'post-api/mcposts/comments/replies'; //{commentId: comment.commentId, replyContent: value}

export const POST_ADD_REPLY_LIKE_URL            = 'post-api/mcposts/comments/replies/likes'; // { replyId: reply.replyId,count: count}

export const GET_QUERY_MY_COMMENTS_URL          = 'post-api/mcposts/comments/mycomments'; //{skip}

export const DELETE_DELETE_REPLY_URL            = 'post-api/mcposts/comments/replies'; //[replyId: reply.replyId]

export const DELETE_DELETE_COMMENT_URL          = 'post-api/mcposts/comments';

export const PUT_UPDATE_COMMENT_URL             = 'post-api/mcposts/comments';

export const PUT_UPDATE_COMMENT_REPLY_URL       = 'post-api/mcposts/comments/replies';

export const GET_QUERY_SINGLE_POST_URL          = 'post-api/mcposts/posts/view'; //{postId}   //duplicate

export const GET_QUERY_MY_REPLIES_URL           = 'post-api/mcposts/comments/myreplies'; //{postId}

export const GET_QUERY_MY_LIKED_POST_URL        = 'post-api/mcposts/posts/mylikes'; //{postId: xxx, count: 0}

export const GET_QUERY_MY_LIKED_COMMENT_URL     = 'post-api/mcposts/comments/mylikes'; //{commentId: xxx, count: 0}

export const GET_QUERY_MY_LIKED_REPLY_URL       = 'post-api/mcposts/comments/replies/mylikes';

export const GET_QUERY_POST_LIKES_URL           = 'post-api/mcposts/posts/likes'; // {postId}/{skip}

export const GET_QUERY_COMMENT_LIKES_URL        = 'post-api/mcposts/comments/likes'; // {commentId}/{skip}

export const GET_QUERY_REPLY_LIKES_URL          = 'post-api/mcposts/replies/likes';// {replyId}/{skip}

//No auth
export const GET_QUERY_POST_GUEST_URL          = 'post-api/mcposts/posts/guest';// {replyId}/{skip}

export const GET_QUERY_POST_COMMENT_GUEST_URL  = 'post-api/mcposts/posts/comments/guest';// {replyId}/{skip}

//front end
export const GET_QUERY_SINGLE_POST_GUEST_URL   = 'post-api/mcposts/posts/view';// {replyId}/{skip}