// import React from 'react';
// import { colors } from '@material-ui/core';
// import PeopleIcon from '@material-ui/icons/PeopleOutlined';
// import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
// import { McLabel } from 'components';

import HomeIcon                                 from '@material-ui/icons/HomeOutlined';
import SettingsOutlinedIcon                     from '@material-ui/icons/SettingsOutlined';
import LockOutlinedIcon                         from '@material-ui/icons/LockOutlined';
import PeopleOutlinedIcon                       from '@material-ui/icons/PeopleOutlined';
import SupervisedUserCircleOutlinedIcon         from '@material-ui/icons/SupervisedUserCircleOutlined';
import PostAddOutlinedIcon                      from '@material-ui/icons/PostAddOutlined';
import AccountBoxOutlinedIcon                   from '@material-ui/icons/AccountBoxOutlined';
import AccountBalanceOutlinedIcon               from '@material-ui/icons/AccountBalanceOutlined';

export default [
  {
    title: 'Pages',
    pages: [
      {
        title: 'Home',
        href: '/',
        icon: HomeIcon  
      },
      /* 
      {
        title: 'Categories',
        href: '/categories',
        icon: FormatListBulletedRoundedIcon,
        children: [
          {
            title: 'Ask & Answers',
            href: '/askanswers',
            icon: ContactSupportIcon
          },
          {
            title: 'Buy & Sells',
            href: '/buysells',
            icon: ShoppingBasketIcon
          },
          {
            title: 'Events',
            href: '/events',
            icon: EventAvailableIcon
          },
          {
            title: 'Exchange',
            href: '/exchange',
            icon: CompareArrowsIcon
          },
          {
            title: 'Free',
            href: '/free',
            icon: RedeemIcon
          },
          {
            title: 'House & Apartment',
            href: '/houseapts',
            icon: HomeWorkIcon
          },
          {
            title: 'Job & Volunteers',
            href: '/jobvolunteers',
            icon: WorkIcon
          },
          {
            title: 'Lost & Found',
            href: '/lostfound',
            icon: AssignmentReturnIcon
          },
          {
            title: 'Others',
            href: '/others',
            icon: InfoIcon
          }
        ]
      }, */

    ]
  },
  {
    title: 'My Campus',
    pages: [
      {
        title: 'Class',
        href: '/class',
        icon: SupervisedUserCircleOutlinedIcon,
      },
      /* {
        title: 'Groups',
        href: '/groups',
        icon: PeopleIcon,
        // eslint-disable-next-line react/display-name
        label: () => (
          <McLabel
            color={colors.red[500]}
            shape="rounded"
          >
            4
          </McLabel>
        )
      }, */
      /*
      {
        title: 'Study Space',
        href: '/roulette',
        icon: DuoIcon
      },
      {
        title: 'Roulette',
        href: '/roulette',
        icon: DuoIcon
      },
      {
        title: 'Online Courses',
        href: '/online',
        icon: DesktopWindowsOutlinedIcon
      },
      {
        title: 'Timeline',
        href: '/timeline',
        icon: SchoolIcon
      }, 
      {
        title: 'Meeting',
        href: '/meeting',
        icon: SchoolIcon
      }, 
      {
        title: 'Live',
        href: '/live',
        icon: SchoolIcon
      }, 
      */
    ]
  },
  {
    title: 'Friends',
    pages: [
      {
        title: 'Friends',
        href: '/friends',
        icon: PeopleOutlinedIcon,
      }
    ]
  },
  {
    title: 'Profile',
    pages: [
      {
        title: 'Profile',
        href: '/profile',
        icon: SettingsOutlinedIcon,
        children: [
          {
            title: 'General',
            href: '/profile/general',
            icon: AccountBoxOutlinedIcon
          },
          {
            title: 'Schools',
            href: '/profile/schools',
            icon: AccountBalanceOutlinedIcon
          },
          {
            title: 'Posts',
            href: '/profile/posts',
            icon: PostAddOutlinedIcon
          },
          {
            title: 'Shorts',
            href: '/profile/shorts',
            icon: PostAddOutlinedIcon
          },
          {
            title: 'Security',
            href: '/profile/security',
            icon: LockOutlinedIcon
          },
        ]
      }
    ]
  }
];

/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
/* import React from 'react';

import { colors } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import RedeemIcon from '@material-ui/icons/Redeem';
import WorkIcon from '@material-ui/icons/Work';
import DesktopWindowsOutlinedIcon from '@material-ui/icons/DesktopWindowsOutlined';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import DuoIcon from '@material-ui/icons/Duo';
import SchoolIcon from '@material-ui/icons/School';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import DomainIcon from '@material-ui/icons/Domain';
import InfoIcon from '@material-ui/icons/Info';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import FormatListBulletedRoundedIcon from '@material-ui/icons/FormatListBulletedRounded';
import { Label } from 'components'; */