import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  countLabel: {
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(3.5)
  },
  scrollbar: {
    maxHeight: '420px'
  },
  loadingMoreLink: {
    textAlign: 'center',
  },
  listItem: {
    paddingLeft: theme.spacing(1),
  }
}));

export default useStyles;