import React, { 
  useState, 
  useEffect, 
  useMemo, }              from 'react';
import useRouter          from 'utils/useRouter';
import PropTypes          from 'prop-types';
import moment             from 'moment';
import _                  from 'lodash';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { MapPin as MapPinIcon } from '@phosphor-icons/react/dist/ssr/MapPin';
import { Heart as HeartIcon } from '@phosphor-icons/react/dist/ssr/Heart';
import { ChatCircle as ChatCircleIcon } from '@phosphor-icons/react/dist/ssr/ChatCircle';
import { Article as ArticleIcon } from '@phosphor-icons/react/dist/ssr/Article';
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import CardActionArea from '@mui/material/CardActionArea';
import { 
  MC_DISPLAY_LENGTH_POST_CONTENT }                     from 'mcconfig'
import useStyles                            from './McPostCard.style';
import './McPostCard.css';

const McPostCard = props => {
  const { 
    post, 
    isDetail, 
    onClickPost,
    className, ...rest } = props;
  const classes = useStyles();
  const router = useRouter();
  
  const finite = React.useMemo(() => true, []);
  const closeOnPullUp = useMemo(() => true, []);
  const closeOnPullDown = useMemo(() => true, []);
  const closeOnBackdropClick = useMemo(() => true, []);
  const fade = useMemo(() => 250, []);
  const swipe = useMemo(() => 500, []);

  const [photoIndex, setPhotoIndex] = useState(-1)
  const handleClick = (imgIndex) => {
    setPhotoIndex(imgIndex);
  }
  if(!post){
    return null;
  }
  return (
    <Card sx={{ borderRadius: '5px', marginBottom: '10px', padding: '5px' }}>
      <Stack 
        spacing={2} 
        sx={{ padding: '10px 10px 0px 10px' }}
      >
        <Stack 
          direction="row" 
          spacing={1} 
          sx={{ alignItems: 'center' }}
        >
          <Avatar 
            src={post.createUserAvatar} 
            sx={{ 
              cursor: 'pointer', 
              width: 32, 
              height: 32, 
              borderRadius: '0px 10px 0px 10px' 
            }} 
          />
          <div>
            <Stack 
              direction="row" 
              spacing={0.5} 
              sx={{ alignItems: 'center' }}
            >
              <Link 
                color="text.primary" 
                variant="subtitle2"
              >
                {post.createUserName}
              </Link>
              <Typography 
                color="text.secondary" 
                variant="caption"
              >
                {
                  isDetail? moment(post.createDatetime).local().format('llll') : moment(post.createDatetime).local().fromNow()
                }
              </Typography>
            </Stack>
            <Stack 
              direction="row" 
              spacing={1} 
              sx={{ alignItems: 'center' }}
            >
              <Typography 
                color="text.primary" 
                variant="caption"
              >
                {post.schoolName}
              </Typography>
              {
                post.categoryName ? 
                  <Typography 
                    color="text.primary" 
                    variant="caption"
                  >
                |
                  </Typography> : null
              }

              <Typography 
                color="text.primary" 
                variant="caption"
              >
                {post.categoryName}
              </Typography>
            </Stack>
          </div>
        </Stack>
        {
          isDetail?  (
            <Box>
              <Typography 
                color="text.primary" 
                variant="subtitle1"
              >
                {post.title}
              </Typography>
              <Typography 
                color="text.primary" 
                variant="body1"
              >
                {post.content}
              </Typography>
            </Box>
          ): (
            <CardActionArea onClick={() => onClickPost(post.id)}>
              <Typography 
                color="text.primary" 
                variant="subtitle1"
              >
                {post.title}
              </Typography>
              <Typography 
                color="text.primary" 
                variant="body1"
              >
                {post.content}
              </Typography>
            </CardActionArea>
          )
        }
        <Lightbox
          animation={{ fade, swipe }}
          carousel={{
            finite,
          }}
          close={() => setPhotoIndex(-1)}
          controller={{ closeOnPullUp, closeOnPullDown, closeOnBackdropClick }}
          index={photoIndex}
          open={photoIndex >= 0}
          plugins={[
            Captions,
            Fullscreen,
            // Slideshow,
            Thumbnails,
            // Video,
            Zoom,
            Counter,
          ]}
          render={{
            buttonPrev: (post?.postImages) && (post?.postImages?.length <= 1) ? () => null : undefined,
            buttonNext: (post?.postImages) && (post?.postImages?.length <= 1) ? () => null : undefined,
          }}
          slides={post.postImages ? [ ...post.postImages]: []}
        />
      
        {!_.isEmpty(post.postImages) ? (
          <Gallery 
            enableImageSelection={false} 
            images={post.postImages}   
            onClick={handleClick}
          />
        ) : null}
        <Stack 
          direction="row" 
          spacing={1} 
          sx={{  alignItems: 'center', justifyContent: 'flex-start' }}
        >
          <MapPinIcon />
          <Stack 
            direction="row" 
            spacing={0.5} 
            sx={{ alignItems: 'center', justifyContent: 'space-between' }}
          >
            {!_.isEmpty(post.campusName) && (
              <Typography 
                color="text.primary" 
                variant="body2"
              >
                {_.truncate(post.campusName)},{' '}
              </Typography>
            )}
            {post.city ? 
              <Typography 
                color="text.primary" 
                variant="body2"
              >{_.truncate(post.city)}, </Typography> : null}
            {post.province ? 
              <Typography 
                color="text.primary" 
                variant="body2"
              >
                {_.truncate(post.province)},{' '}
              </Typography> : null}
            {post.countryName ? 
              <Typography 
                color="text.primary" 
                variant="body2">
                {_.truncate(post.countryName)}{' '}
              </Typography> : null}
          </Stack>
        </Stack>
        <Divider />
        <Stack 
          direction="row" 
          spacing={2} 
          sx={{ 
            alignItems: 'center', 
            justifyContent: 'space-between', 
            marginBottom: '-10px' 
          }}
        >
          <Stack 
            direction="row" 
            sx={{ alignItems: 'center' }}
          >
            <Tooltip title="Like">
              <IconButton color="text.primary">
                <HeartIcon />
              </IconButton>
            </Tooltip>
            <Typography 
              color="text.secondary" 
              variant="subtitle2"
            >
            like
            </Typography>
            <Tooltip title="Like">
              <IconButton color="text.primary">
                <ArticleIcon />
              </IconButton>
            </Tooltip>
            <Typography 
              color="text.secondary" 
              variant="subtitle2"
            >
            comment
            </Typography>
          </Stack>
          <Stack 
            direction="row" 
            sx={{ alignItems: 'center' }}
          >
            <Tooltip title="Chat">
              <IconButton color="text.primary">
                <ChatCircleIcon />
              </IconButton>
            </Tooltip>
            <Typography 
              color="text.secondary" 
              variant="subtitle2"
            >
            chat
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

McPostCard.propTypes = {
  className: PropTypes.string,
  isDetail: PropTypes.bool,
  onClickPost: PropTypes.func,
  onClosePost: PropTypes.func,
  onDeletePost: PropTypes.func,
  onOpenPost: PropTypes.func,
  post: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

export default McPostCard;
