// McAuthController
export const POST_REGISTER_USER_URL                     = 'user-api/mcaccounts/register';   // true

export const POST_LOGIN_USER_URL                        = 'user-api/mcaccounts/login';

export const POST_SIGNOUT_USER_URL                      = 'user-api/mcaccounts/signout';

export const POST_ACTIVATE_ACCOUNT_URL                  = 'user-api/mcaccounts/activation';

export const GET_RESEND_ACTIVATION_EMAIL_URL            = 'user-api/mcaccounts/resend-activation-email';

export const GET_SEND_FORGET_PASSWORD_EMAIL_URL         = 'user-api/mcaccounts/forget-password-email';

export const POST_VERIFY_FORGET_PASSWORD_CODE_URL       = 'user-api/mcaccounts/forget-password-verify';

export const POST_FORGET_PASSWORD_URL                   = 'user-api/mcaccounts/forget-password';

export const PUT_UPDATE_FORGET_PASSWORD_URL             = 'user-api/mcaccounts/update-forget-password';

export const PUT_UPDATE_PASSWORD_URL                    = 'user-api/mcaccounts/update-password';

export const POST_UPLOAD_AVATAR_URL                     = 'user-api/mcaccounts/avatar';

// McAuthController
export const GET_USER_SCHOOLS_URL                       = 'user-api/mcaccounts/user-schools';
// change current school
export const PUT_USER_SCHOOLS_URL                       = 'user-api/mcaccounts/user-schools';
// subscribe school
export const POST_USER_SCHOOLS_URL                      = 'user-api/mcaccounts/user-schools';

export const DELETE_UNSUBSCRIBE_SCHOOL_URL              = 'user-api/mcaccounts/user-schools';
// change current school
export const POST_USER_REGISTER_SCHOOL_URL              = 'user-api/mcaccounts/user-schools/register';

// export const GET_QUERY_USER_DETAIL_URL                  = 'user-api/mcaccounts/user-details';

export const PUT_UPDATE_USER_DETAIL_URL                 = 'user-api/mcaccounts/user-details';

// export const PUT_UPDATE_LOCATION_URL                    = 'user-api/mcaccounts/user-locations';

// export const PUT_UPDATE_BIO_URL                         = 'user-api/mcaccounts/user-bios';

export const GET_QUERY_EDUCATION_URL                    = 'user-api/mcaccounts/user-edus';

export const POST_ADD_EDUCATION_URL                     = 'user-api/mcaccounts/user-edus';

export const PUT_UPDATE_EDUCATION_URL                   = 'user-api/mcaccounts/user-edus';

export const DELETE_REMOVE_EDUCATION_URL                = 'user-api/mcaccounts/user-edus';

export const GET_QUERY_USER_PROFILE                     = 'user-api/mcaccounts/user-profile';

export const GET_QUERY_DICT_CLASS_TYPES                 = 'user-api/mcaccounts/dict-items/class/types';

export const GET_QUERY_DICT_ITEMS                       = 'user-api/mcaccounts/dict-items';

export const POST_AUTO_LOGIN_BY_COOKIE_URL              = 'user-api/mcaccounts/auto-login';

export const POST_CHECK_EMAIL_EXIST_URL                 = 'user-api/mcaccounts/check-email-exist';   

export const GET_QUERY_MICOLLEGE_ADMIN                  = 'user-api/mcaccounts/user-profile-admin';

export const GET_QUERY_COLLEGEMATE_COUNT                = 'user-api/mcaccounts/collegemates-count';

export const GET_QUERY_COLLEGEMATE                      = 'user-api/mcaccounts/collegemates';

export const POST_RESTRICT_USER_URL                     = 'user-api/mcaccounts/user/restrict';   // true

export const GET_RESTRICT_USER_URL                      = 'user-api/mcaccounts/user/restrict';   // true

export const POST_UNRESTRICT_USER_URL                   = 'user-api/mcaccounts/user/unrestrict';   // true

export const POST_DELETE_ACCOUNT_URL                    = 'user-api/mcaccounts/account';   // true

export const GET_QUERY_ACCOUNT_URL                      = 'user-api/mcaccounts/account';   // true

export const GET_CHECK_CHAT_PARTNER_STATUS_URL          = 'user-api/mcaccounts/user/chat-partner-status';   // true

export const GET_QUERY_BLOCK_URL                        = 'user-api/mcaccounts/user/block';   // true


//Web
export const POST_WEB_LOGIN_USER_URL                    = 'user-api/web/mcaccounts/login';
//Web
export const POST_WEB_SIGNOUT_USER_URL                  = 'user-api/web/mcaccounts/signout';