import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 280,
    overflow: 'hidden'
  },
  root: {
    backgroundColor: theme.palette.white
  },
  list: {
    padding: theme.spacing(1, 3),
    '&:hover': {
      cursor: 'pointer'
    }
  },
  listItemText: {
    marginRight: theme.spacing(1)
  },
  lastActivity: {
    whiteSpace: 'nowrap'
  },
  fab: {
    position: 'fixed',
    bottom: 32,
    right: 32,
    zIndex: 2000,
    '@media (max-width: 863px)': {
      bottom: 12,
    },
  },
  reloadingIcon: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#0096882b',
    padding: theme.spacing(0, 1),
    borderRadius: theme.spacing(0.5),
  },
  reloadingButton: {
    padding: theme.spacing(0.5)
  }
}));

export default useStyles;