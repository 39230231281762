import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.up('md')]: {
      marginTop: '-15%',
      width: '40%',
      margin: theme.spacing(1)
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '70%',
      margin: theme.spacing(1)
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100%',
      maxWidth: '100%',
      margin: theme.spacing(1),
    },
    width: '100%',
    margin: theme.spacing(1)
  },
  default: {
    color: theme.palette.primary.main,
  },
  success: {
    color: colors.green[600],

  },
  info: {
    color: colors.lightBlue[600],

  },
  warning: {
    color: colors.orange[900],

  },
  error: {
    color: theme.palette.error.main,

  },
  closeButton: {
    position: 'absolute',
    right: '4px',
    top: '4px',
    padding: 0,
    color: theme.palette.grey[500],
  },
  title: {
    backgroundColor: '#f5f5f5',
    fontSize: '0.8em',
    // fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    padding: '10px 15px'
  },
  actions: {
    backgroundColor: colors.grey[100],
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '54px'
  },
}));
export default useStyles;