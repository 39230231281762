import React, {
  useEffect, 
  Fragment,
  useState }                    from 'react';
import { useSelector }          from 'react-redux';
import { Link as RouterLink }   from 'react-router-dom';
import clsx                     from 'clsx';
import PropTypes                from 'prop-types';
import useStyles                from './McTopBar.style';
// import _                        from 'lodash';
import useRouter                from 'utils/useRouter';
import { useDispatch }                        from 'react-redux';
import AppBar                       from '@material-ui/core/AppBar';
import Toolbar                      from '@material-ui/core/Toolbar';
import Hidden                       from '@material-ui/core/Hidden';
import useMediaQuery                from '@material-ui/core/useMediaQuery';
import { useTheme }                 from '@material-ui/core';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Popover from '@mui/material/Popover';
import Divider from "@mui/material/Divider";
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Avatar from "@mui/material/Avatar";
import { User as UserIcon } from '@phosphor-icons/react/dist/ssr/User';
import { CaretDown as CaretDownIcon } from "@phosphor-icons/react/dist/ssr/CaretDown";
import { GooglePlayLogo as GooglePlayLogoIcon } from '@phosphor-icons/react/dist/ssr/GooglePlayLogo';
import { AppStoreLogo as AppStoreLogoIcon } from '@phosphor-icons/react/dist/ssr/AppStoreLogo';
import { List as ListIcon } from "@phosphor-icons/react/dist/ssr/List";

import {McAuthDisplay}            from 'components';
// import MenuIcon                             from '@material-ui/icons/Menu';
import Search                               from './Search';
import Logo                                 from './Logo';
import Notification                         from './Notification';
import { ROUTER_TO_ACCOUNT_PATH, ROUTER_TO_LOGIN_PATH }                              from 'store/routerPath';
import { AddMcPostModal }                                     from 'components';
import { authService } from 'service';
import { CleanChatInfos, CleanPostInfos, CleanSchoolInfos, CleanUserInfos } from 'store/actions';
const COOKIE_TOKEN = 'COOKIE_TOKEN';
const McTopBar = props => {
  const { onOpenNavBarMobile, className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  // const router = useRouter();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const [openAddPostModal, setOpenAddPostModal] = useState(false);

  const handleAddPostModalClose = () => {
    setOpenAddPostModal(false);
  };
  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      style={{ backgroundColor: '#ffffff', borderBottom: '1px solid #dcdfe4', padding: '2px' }}
    >
      {
        openAddPostModal
        &&
        <AddMcPostModal
          onClose={handleAddPostModalClose}
          open={openAddPostModal}
        />
      }
      <Toolbar
        className={clsx(classes.toolBar, mobileDevice ? classes.toolBarMobile : '')}
        disableGutters
        variant="dense"
      >
        <Fragment>
          <Box
            component={RouterLink}
            href={'/'}
            sx={{
              display: "inline-flex",
              textDecoration: "none",
              alignItems: "center",
            }}
          >
            {/* when in mobile mode, display the show menu icon  */}
            <Hidden lgUp>
              <IconButton
                color="#32383e"
                onClick={onOpenNavBarMobile}
                // size="large"
              >
                <ListIcon />
              </IconButton>
            </Hidden>
            <Logo />
            <Typography
              // component="span"
              sx={{
                color: "#32383e",
                fontSize: "1.25rem",
                fontWeight: 500,
                padding: "4px",
              }}
            >
              MiCollege
            </Typography>
            <Typography
              // component="span"
              sx={{
                color: "#32383e",
                fontSize: "1rem",
                fontWeight: 400,
                paddingTop: "2px",
                display: { xs: "none", md: "block" },
              }}
            >
              Connect fellow college students
            </Typography>
          </Box>
          {
            !mobileDevice
            &&
            <Search redirect={window.location.pathname}/>
          }
          <div className={classes.flexGrow} />
          {/* if not logged in  */}
          {
          
            <Fragment>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  alignItems: "center",
                  flex: "1 1 auto",
                  justifyContent: "flex-end",
                }}
              >
                <AppsPopOver />
                <UserButton />
              </Stack>
            </Fragment>
          }
        </Fragment>
      </Toolbar>
    </AppBar>
  );
};
function AppsPopOver() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <Stack
      component="ul"
      direction="row"
      spacing={1}
      sx={{ listStyle: "none", m: 0, p: 0 }}
    >
      <Box 
        component="span" 
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{ flex: "1 1 auto" }}
      >
        <Typography
          component="a"
          href="/micollege-apps.html"
          sx={{
            color: "black",
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: "28px",
          }}
        >
          {'Apps'}
        </Typography>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          color: "black",
          display: "flex",
          flex: "0 0 auto",
        }}
      >
        <CaretDownIcon         
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}  
        />
      </Box>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableRestoreFocus
        id="mouse-over-popover"
        onClose={handlePopoverClose}
        open={open}
        sx={{ pointerEvents: 'none' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <PagesPopover />
      </Popover>
    </Stack>
  );
}
function PagesPopover() {
  return (
    <Box
      sx={{ 
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'space-between',
        p: 2, 
      }}
    >
      <Stack 
        spacing={1} 
        sx={{marginRight: '1.5em'}}
      >
        <Stack sx={{display: 'flex', flexDirection: 'row',}}>
          <Box
            alt="micollege - google play" 
            component="img" 
            src="/assets/google-play.png"
            sx={{
              display: 'inline-block',
              p: '3px 6px',
              height: '42px'
            }}
          />
        </Stack>
        <Box 
          alt="micollege qr code google play" 
          component="img" 
          src="/assets/qr-android.png"
          sx={{ 
            height: {
              xs: '120px', 
              md: '200px',
              lg: '200px',
            },
            width: {
              xs: '120px', 
              md: '200px',
              lg: '200px',
            },
            // flex: '1 1 auto'
          }} 
        />
        <IconButton
          onClick={() => {
            window.open('https://play.google.com/store/apps/details?id=io.micollege.mobile.rn.android.prod&pcampaignid=web_share', '_blank');
          }}
          sx={{ 
            color: '#007f99', 
            display: { xs: 'block', md: 'block' }, 
            alignSelf: 'flex-end'
          }}
        >
          <GooglePlayLogoIcon />
        </IconButton>
      </Stack>
      <Stack spacing={1}>
        <Stack sx={{display: 'flex', flexDirection: 'row'}}>
          <Box
            alt="micollege - app store" 
            component="img" 
            src="/assets/app-store.png"          
            sx={{
              display: 'inline-block',
              p: '3px 6px',
              height: '42px'
            }}
          />
                       
        </Stack>
        <Box 
          alt="micollege qr code app store" 
          component="img" 
          src="/assets/qr-ios.png" 
          sx={{ 
            height: {
              xs: '120px', 
              md: '200px',
              lg: '200px',
            },
            width: {
              xs: '120px', 
              md: '200px',
              lg: '200px',
            },
          }} 
        />
        <IconButton
          onClick={() => {
            window.open('https://apps.apple.com/ca/app/micollege/id6450606006', '_blank');
          }}
          sx={{ 
            color: '#007f99', 
            display: { xs: 'block', md: 'block' }, 
            alignSelf: 'flex-end'
          }}
        >
          <AppStoreLogoIcon />
        </IconButton>
      </Stack>
    </Box>
  );
}
function UserButton() {
  const { loginUserInfo } = useSelector((state) => state.auth);
  const [avatarUrl, setAvatarUrl] = useState("");
  useEffect(() => {
    if (loginUserInfo) {
      setAvatarUrl(loginUserInfo.avatarUrl);
    } else {
      setAvatarUrl("");
    }
  }, [loginUserInfo]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <React.Fragment>
      {!avatarUrl && (
        <Typography
          component="a"
          href={ROUTER_TO_LOGIN_PATH}
          sx={{ display: 'flex', color: "orange" }}
        >
          Login
        </Typography>
      )}
      {avatarUrl ? (
        <React.Fragment>
          <McAuthDisplay requireSigned>
            <Notification />
          </McAuthDisplay>
          <Box
            component="button"
            onClick={handleClick}
            sx={{
              border: "none",
              background: "transparent",
              cursor: "pointer",
              p: 0,
            }}
          >
            <Avatar
              src={avatarUrl}
              sx={{ width: 32, height: 32, borderRadius: "0px 10px 0px 10px" }}
              variant="rounded"
            />
          </Box>
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            id={id}
            onClose={handleClose}
            open={Boolean(open)}
            slotProps={{ paper: { sx: { width: '280px' } } }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            <Box sx={{ p: 2 }}>
              <Typography>{loginUserInfo?.fullName}</Typography>
              <Typography 
                color="text.secondary" 
                variant="body2"
              >
                @{loginUserInfo?.uid}
              </Typography>
            </Box>
            <Divider />
            <List sx={{ p: 1 }}>
              <MenuItem 
                component={RouterLink} 
                onClose={handleClose}
                to={ROUTER_TO_ACCOUNT_PATH} 
              >
                <ListItemIcon>
                  <UserIcon />
                </ListItemIcon>
                  Account
              </MenuItem>
            </List>
            <Divider />
            <Box sx={{ p: 1 }}>
              <CustomSignOut />
            </Box>
          </Popover>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}
function CustomSignOut() {
  const dispatch = useDispatch();
  const router = useRouter();
  const handleSignOut = () => {
    authService
      .signoutWeb()
      .then( () => {
      // logout from firestore, FIXME, if logout whether still can receive notification
      /* Clean local storage */
        localStorage.removeItem(COOKIE_TOKEN);
        sessionStorage.removeItem(COOKIE_TOKEN);

        // deviceStorage.removeCookieToken();
        // const keys = deviceStorage.getAllKeys();
        dispatch(CleanUserInfos()); //userinfo
        dispatch(CleanChatInfos()); //chat
        dispatch(CleanSchoolInfos()); //school
        dispatch(CleanPostInfos()); //post

        router.refresh();
      // router.replace('/');
      })
      .catch( () => {
      // console.log(error);
      });
  }
  return (
    <MenuItem 
      component="div" 
      onClick={handleSignOut} 
      sx={{ justifyContent: 'center' }}
    >
      Sign out
    </MenuItem>
  );
}
McTopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default McTopBar;
