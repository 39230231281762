import React, { forwardRef, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper, IconButton, Typography, colors } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 16px',
    borderRadius: theme.shape.borderRadius
  },
  default: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  success: {
    backgroundColor: colors.green[600],
    color: theme.palette.white
  },
  info: {
    backgroundColor: colors.lightBlue[600],
    color: theme.palette.white
  },
  notice: {
    // backgroundColor: colors.lightGreen[400],
    backgroundColor: '#cdf0f7',
    color: '#505050'
  },
  warning: {
    backgroundColor: colors.orange[900],
    color: theme.palette.white
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  message: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0'
  },
  icon: {
    fontSize: theme.spacing(1.5),
    marginRight: theme.spacing(1),
    color: '#007f9996'
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    // paddingLeft: 16,
    // marginRight: -8
  }
}));

const icons = {
  default: <InfoIcon />,
  success: <CheckCircleIcon />,
  info: <InfoIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />,
  notice: <CheckCircleOutlineIcon />
};

const Alert = forwardRef((props, ref) => {
  const { className, icon, variant, children, closable, ...rest } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  }
  return (
    <Fragment>
      {
        open
        &&
        <Paper
          {...rest}
          className={clsx(classes.root, classes[variant], className)}
          component={Typography}
          elevation={1}
          ref={ref}
          variant="h6"
        >
          <span className={classes.icon}>{icon || icons[variant]}</span>
          <div className={classes.message}>
            {children}
          </div>
          {closable && (
            <IconButton
              className={classes.action}
              color="inherit"
              key="close"
              onClick={handleClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          )}
        </Paper>
      }
    </Fragment>
    
  );
});

Alert.displayName = 'Alert';

Alert.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  closable: PropTypes.bool,
  icon: PropTypes.node,
  variant: PropTypes.oneOf(['default', 'info', 'success', 'warning', 'error', 'notice'])
};

Alert.defaultProps = {
  variant: 'default'
};

export default Alert;
