import React, { Fragment, useState }    from 'react'
import { useSelector }                  from 'react-redux';
import PropTypes                        from 'prop-types';
import clsx                             from 'clsx';
import _                                from 'lodash';

import Avatar             from '@material-ui/core/Avatar';
import Modal              from '@material-ui/core/Modal';
import Backdrop           from '@material-ui/core/Backdrop';
import Fade               from '@material-ui/core/Fade';

import useStyles          from './McAvatar.style';

export const McAvatar = (props) => {
  const {avatarUrl, userName, title, onClick, className} = props;
  const classes = useStyles();
  const { loginTimestamp } = useSelector(state => state.auth.loginUserInfo);
  const [open, setOpen] = useState(false);
  const handleClickAvatar = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return(
    <Fragment>
      <Modal
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        aria-describedby="transition-modal-description"
        aria-labelledby="transition-modal-title"
        className={classes.modal}
        closeAfterTransition
        onClose={handleClose}
        open={open}
      >
        <Fade in={open}>
          <img 
            alt={title} 
            className={classes.img}
            src={_.isEmpty(avatarUrl) ? '/images/logos/mc-logo-sm.png' : avatarUrl + '?' + new Date().getTime()}
            title={userName} 
          />
        </Fade>
      </Modal>
      <Avatar
        className={clsx(classes.root, className)}
        onClick={onClick ? onClick : handleClickAvatar}
        src={_.isEmpty(avatarUrl) ? '/images/logos/mc-logo-sm.png' : avatarUrl  + '?' + loginTimestamp}
        title={title ? title : userName}
      />
    </Fragment>
  )   
}
McAvatar.propTypes = {
  avatarUrl: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  userName: PropTypes.string,
};
  
export default McAvatar;
