import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1)
  },
  paper: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    padding: theme.spacing(0.5, 0.5),
  },
  divider: {
    width: 1,
    height: 24
  },
  avatarMe: {
    width: '32px',
    height: '32px',
  },
  scrollbar: {
    maxHeight: '80px',
  },
  input: {
    width: '100%',
    overflow: 'auto',
    padding: '2px', 
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    fontSize: '1em',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    '&:focus': {
      backgroundColor: '#0096880a',
      outline: 'none',
    },
    '& .twemoji': {
      width: '22px',
      marginLeft: '1px',
      marginRight: '1px',
      verticalAlign: 'middle',
    }
  },
  operateBtn: {
    padding: theme.spacing(1),
  },
  emojiPopper: {
    zIndex: 1101
  },
  image: {
    width: '100px',
    height: '100px'
  },
  imageGallery: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    padding: '4px 2px',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
  }, 
  closeButtonIcon: {
    color: 'white',
  },

  closeButtonIconRoot: {
    fontSize: '20px'
  },
  iconHover: {
    '&:hover': {
      color: colors.red[400],
    },
  },
  fileInput: {
    display: 'none'
  },
  imageTitleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.3) 0%, ' +
      'rgba(0,0,0,0.08) 60%, rgba(0,0,0,0) 100%)',
  },
  waitingIcon: {
    position: 'absolute', 
    marginTop: '25%', 
    marginLeft: '40%'
  },
  imgMsg: {
    color: 'red',
    marginLeft: '16px',
    fontSize: '14px'
  },
  loadingPreview: {
    width: '100%', 
    height: '110px',
    border: '2px dashed rgba(255, 152, 0, 0.20)', 
    borderRadius: '5px'
  },
  inputCount: {
    textAlign: 'right',
    fontSize: '0.7em',
    verticalAlign: 'text-bottom',
    padding: theme.spacing(0.25),
    paddingTop: theme.spacing(0.75)
  },
  error: {
    color: colors.red[500],
  },
}));

export default useStyles;