import moment                         from 'moment';
import axios                          from 'utils/mcaxios';
import { 
  POST_SAVE_PEER_TXT_MSG_URL, 
  POST_SAVE_PEER_IMG_MSG_URL,
  POST_SAVE_CHATROOM_TXT_MESSAGE_URL,
  POST_SAVE_CHATROOM_IMG_MESSAGE_URL,
  POST_ADD_FRIEND_URL}                        from 'store/rest-api';
import uuid                                   from 'uuid';

export const buildSendChannelMsg = (
  createUserId, createUserName, createUserAvatar, 
  message, contentType, sentStatus
) => {
  const localChannelMsgObj = {
    msgId: uuid(),
    createUserId: createUserId, 
    createUserName: createUserName, 
    createUserAvatar: createUserAvatar, 
    content: message,
    contentType: contentType,
    sentStatus: sentStatus,
    createDatetime: moment.utc()
  }
  return localChannelMsgObj; 
}
export const buildReceivedChannelMsg = ( msgId, 
  createUserId, createUserName, createUserAvatar, 
  message, contentType, sentStatus, createDatetime
) => {
  const localChannelMsgObj = {
    msgId: msgId,
    createUserId: createUserId, 
    createUserName: createUserName, 
    createUserAvatar: createUserAvatar, 
    content: message,
    contentType: contentType,
    sentStatus: sentStatus,
    createDatetime: createDatetime
  }
  return localChannelMsgObj; 
}

export const buildSendPeerMsg = (
  createUserId, createUserName, createUserAvatar, 
  message, contentType, sentStatus
) => {
  const sendMsgObj = {
    msgId: uuid(),
    createUserId: createUserId, 
    createUserName: createUserName, 
    createUserAvatar: createUserAvatar, 
    content: message,
    contentType: contentType,
    sentStatus: sentStatus,
    createDatetime: moment.utc()
  }
  return sendMsgObj; 
}

export const buildConvoInfo = (peers) => {
  
  const conversation = {
    peerId:  peers.peerId,
    peerName:  peers.peerName,
    peerAvatar: peers.peerAvatar,
    messages: [],
    unread: 0,
    closed: false,
    createDatetime: moment.utc()
  }
  return conversation
}
export const buildClassConvoInfo = (classId, programName) => {
  
  const conversation = {
    classId:  classId,
    programName:  programName,
    messages: [],
    unread: 0,
    closed: false,
    createDatetime: moment.utc()
  }
  return conversation
}

export const saveFriend = async (peer, callback) => {
  /* Add Peer to Friend list if not exist */

  axios.post(POST_ADD_FRIEND_URL, peer )
    .then( response => {
      const {status, message, newFriendVo} = response.data;
      callback(status, message, newFriendVo);
    })
    .catch( error => {
      console.log('error ', error);
    });
}

export const savePeerTxtMsg = async (rtmTextMessage) => {

  axios.post(POST_SAVE_PEER_TXT_MSG_URL, 
    rtmTextMessage)
    // .then( response => {
    // })
    .catch( error => {
      console.log('error ', error);
    });
}
export const savePeerImgMsg = async (rtmImgMessage) => {

  axios.post(POST_SAVE_PEER_IMG_MSG_URL, 
    rtmImgMessage )
    // .then( response => {
    // })
    .catch( error => {
      console.log('error ', error);
    });
}

export const saveChatroomTxtMessage = async (chatroomMsg) => {
  axios.post(POST_SAVE_CHATROOM_TXT_MESSAGE_URL,
    chatroomMsg)
  // .then(response => {
  //   const {status } = response.data;
  // });
    .catch( error => {
      console.log('error ', error);
    });
}

export const saveChatroomImgMessage = async (chatroomMsg) => {
  axios.post(POST_SAVE_CHATROOM_IMG_MESSAGE_URL,
    chatroomMsg)
  // .then(response => {
  //   const {status } = response.data;
  // });
    .catch( error => {
      console.log('error ', error);
    });
}
