/* [ Class ] */
// export const GET_QUERY_CLASS_TYPES_URL                          = 'class-api/mcclasses/types'   // in use

export const POST_SAVE_CLASS_URL = 'class-api/mcclasses/classes'; // in use

export const GET_QUERY_CLASSES_URL = 'class-api/mcclasses/classes';

export const GET_QUERY_CLASS_URL = 'class-api/mcclasses/classes';

// export const PUT_UPDATE_REQUIRED_CLASS_URL =
//   'class-api/mcclasses/classes/required';

export const PUT_UPDATE_OPTIONAL_CLASS_URL =
  'class-api/mcclasses/classes/optional';

export const DELETE_DELETE_CLASSMATE_URL =
  'class-api/mcclasses/classes/classmate';

export const DELETE_DELETE_CLASSADMIN_URL =
  'class-api/mcclasses/classes/classadmin';

// export const DELETE_DELETE_CLASSREMOVED_URL =
//   'class-api/mcclasses/classes/classremoved';

// export const POST_FIND_CLASS_BY_CLASSCODE_URL =
//   'class-api/mcclasses/classes/classcode';

export const POST_FIND_CLASS_BY_PROPS_URL = 'class-api/mcclasses/classes/props';

// export const PUT_JOIN_CLASS_URL = 'class-api/mcclasses/classes';

// export const GET_QUERY_CLASSES_CHATBAR_URL =
//   'class-api/mcclasses/classes/chatbar';

export const POST_SEARCH_CLASS_BY_INPUT_URL =
  'class-api/mcclasses/classes/input';

/* [ Class Chatroom ] */
export const GET_ENTER_CLASSROOM_URL = 'class-api/mcclasses/classes/chatrooms'; // remove

export const POST_UPLOAD_CHATROOM_IMG_URL =
  'class-api/mcclasses/classes/chatrooms/img/uploads';

export const POST_SAVE_CHATROOM_TXT_MESSAGE_URL =
  'class-api/mcclasses/classes/chatrooms/txt';

export const POST_SAVE_CHATROOM_IMG_MESSAGE_URL =
  'class-api/mcclasses/classes/chatrooms/img';

export const POST_LOG_LEFT_CHATROOM_URL =
  'class-api/mcclasses/classes/chatrooms/member-left';

export const GET_QUERY_CLASSROOM_CLASSMATES_URL =
  'class-api/mcclasses/classes/chatrooms/classmates';

export const POST_CLASS_CHATROOM_HISTRORY_MESSAGE_URL =
  'class-api/mcclasses/classes/chatrooms/history-messages';

// export const LEAVE_OR_DELETE_CLASS_URL =                    'class-api/mcclasses/classes/leave-class'

// export const LEAVE_AND_DELETE_I_CREATED_CLASS_URL =         'class-api/mcclasses/classes/leave-myclass'

export const GET_CLASS_NOTIFICATIONS_URL =
  'class-api/mcclasses/classes/notifications';

export const PUT_REMOVE_CLASSMATE_URL =
  'class-api/mcclasses/classes/chatrooms/remove-classmate';

export const GET_QUERY_CLASS_BLACKBOARD_URL =
  'class-api/mcclasses/classes/blackboards';

export const PUT_UPSERT_CLASS_BLACKBOARD_URL =
  'class-api/mcclasses/classes/blackboards';

// export const DELETE_DELETE_CHATROOM_MSG_URL =
//   'class-api/mcclasses/classes/chatrooms';

// export const GET_QUERY_RECENT_CLASSES_URL =
//   'class-api/mcclasses/classes/recent';

export const GET_QUERY_JOIN_CLASS_REQ_URL = 'class-api/mcclasses/classes/request-joinclass';

export const POST_JOIN_CLASS_REQUEST_URL = 'class-api/mcclasses/classes/request-joinclass';

export const PUT_JOIN_CLASS_REQUEST_URL = 'class-api/mcclasses/classes/request-joinclass';

export const DELETE_JOIN_CLASS_REQ_RESULT_URL = 'class-api/mcclasses/classes/request-joinclass';

export const GET_QUERY_JOIN_CLASS_REQ_APPLICANT_URL = 'class-api/mcclasses/classes/request-joinclass/applicant';

// export const QUERY_JOIN_CLASS_REQ_UNREAD_COUNT_URL =        'class-api/mcclasses/classes/request-joinclass/unread-count'
export const DELETE_REMOVE_CLASSMATE_URL = 'class-api/mcclasses/classes/classmate/remove';

export const GET_QUERY_CLAZZ_URL = 'class-api/mcclasses/classes/clazz';

export const GET_CHECK_CLASSMATE_CHAT_STATUS_URL =
  'class-api/mcclasses/classes/classmate/chat-status';
