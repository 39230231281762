import React, 
{ Fragment, 
  Suspense, 
  useEffect }               from 'react';
import useRouter            from 'utils/useRouter';
import { useSelector }      from 'react-redux';
import { useLocation }      from 'react-router-dom'
import { renderRoutes }     from 'react-router-config';
import PropTypes            from 'prop-types';
import _                    from 'lodash';

import { LinearProgress }       from '@material-ui/core';
// import { Bottombar } from './components';

import useStyles                    from './Auth.style';
import { McTopBar, 
  McSnackbar }                      from 'components';
import { ROUTER_TO_HOME_PATH,
  ROUTER_TO_SIGNOUT_PATH }          from 'store/routerPath';
import AutoLogin                    from 'views/AutoLogin';
const Auth = props => {
  const { route } = props;
  const router = useRouter();
  const {loggedIn, token} = useSelector(state => state.auth);
  // if user already signed in, it cannot go to this layout again,
  // like forget password, register, login, etc...
  useEffect(() => {
    let mounted = true;
    if(mounted){
      if(props.location 
          && props.location.pathname 
            && props.location.pathname !== ROUTER_TO_SIGNOUT_PATH){
        if(loggedIn && !_.isEmpty(token)){
          router.history.replace(ROUTER_TO_HOME_PATH);
        }
      }
    }
    return () => {
      mounted = false
    }
  }, [])
  const classes = useStyles();

  return (
    <Fragment>
      <AutoLogin redirect={useLocation().pathname}/>
      {/* <McTopBar 
        className={classes.topBar}
        onOpenNavBarMobile={null}
      /> */}
      <main className={classes.content}>
        <Suspense fallback={<LinearProgress />}>
          {renderRoutes(route.routes)}
        </Suspense>
      </main>
      {/* <Bottombar /> */}
      <McSnackbar /> 
    </Fragment>
  );
};

Auth.propTypes = {
  location: PropTypes.object,
  route: PropTypes.object
};

export default Auth;
