import React, {useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {McDialogModal} from 'components';
import {EditMcCommentForm} from './components';
import { MC_MAX_LENGTH_COMMENT_CONTENT } from 'mcconfig'
const EditMcCommentModal = props => {
  // const classes = useStyles();
  const { open, onClose, onUpdateComment,
    loading, comment } = props;

  const [inputCount, setInputCount] = useState(0);

  /**[ Handle Submit post ]**/
  const handleSubmit = event => {
    event.preventDefault();
    let descContent = document.getElementById('descContent').innerHTML;
    var textWithBRReplaced = descContent.replace(/<br>/gi, ' ');
    var textWithNBSpaceReplaced = descContent.replace(/&nbsp;/g, ' ');
    /* Handle send text messages */
    if(_.trim(textWithBRReplaced) !== '' 
        && _.trim(textWithNBSpaceReplaced) !== '' 
          && inputCount <= MC_MAX_LENGTH_COMMENT_CONTENT){
      updateComment(_.trim(textWithNBSpaceReplaced));
    }else{
      document.getElementById('descContent').focus();
    }
  };
  const updateComment = (descContent) => {
    onUpdateComment(descContent)
  }
  return (
    <McDialogModal
      handleSubmit={handleSubmit}
      loading={loading}
      onClose={onClose}
      open={open}
      title="Edit Post"
    >
      <EditMcCommentForm 
        comment={comment}
        inputCount={inputCount}
        setInputCount={setInputCount}
      />
    </McDialogModal>
  );
};

EditMcCommentModal.propTypes = {
  comment: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdateComment: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

export default EditMcCommentModal;
