// auth pages
export const ROUTER_TO_LOGIN_PATH                               = '/auth/login';
export const ROUTER_TO_REGISTER_PATH                            = '/auth/register';
export const ROUTER_TO_SUCCESS_REGISTER_PATH                    = '/auth/register-success';
export const ROUTER_TO_SUCCESS_VERIFICATION_PATH                = '/auth/verification-success';
export const ROUTER_TO_VERIFICATION_CODE_PATH                   = '/auth/validate-code';
export const ROUTER_TO_PICKUP_SCHOOL_PATH                       = '/auth/pickup-school';
export const ROUTER_TO_RESEND_ACTIVATION_PATH                   = '/auth/resend-activation';
export const ROUTER_TO_RESEND_FORGET_PASSWORD_PATH              = '/auth/forget-password';
export const ROUTER_TO_SUCCESS_RESEND_FORGET_PASSWORD_PATH      = '/auth/forget-password-success';
export const ROUTER_TO_CHANGE_PASSWORD_PATH                     = '/auth/change-password';
export const ROUTER_TO_SUCCESS_UPDATE_PASSWORD_PATH             = '/auth/update-password-success';

// post pages
export const ROUTER_TO_MCPOST_PATH                              = '/mcpost';

// class pages
export const ROUTER_TO_MCCLASS_PATH                             = '/classes';
export const ROUTER_TO_CREATE_MCCLASS_PATH                      = '/classes/create-class'
export const ROUTER_TO_SEARCH_MCCLASS_PATH                      = '/classes/search-class'

// notification pages
export const ROUTER_TO_NOTIFICATION_PATH                        = '/notifications'

// settings pages
export const ROUTER_TO_SCHOOLS_PATH                             = '/profile/schools'
export const ROUTER_TO_FRIENDS_PATH                             = '/profile/friends'
export const ROUTER_TO_SETTINGS_PATH                            = '/profile/general';

// errors pages
export const ROUTER_TO_401_PATH                                 = '/errors/error-401';
export const ROUTER_TO_404_PATH                                 = '/errors/error-404';

// // school info pages
// export const ROUTER_TO_SCHOOLPROFILE_PATH                       = '/school-profile'
// // school info pages
// export const ROUTER_TO_USERPROFILE_PATH                         = '/user-profile'

export const ROUTER_TO_HOME_PATH                                = '/';

export const ROUTER_TO_SELECT_SCHOOL_PATH                       = '/select-schools';

export const ROUTER_TO_SIGNOUT_PATH                             = '/auth/signout';

export const ROUTER_TO_CLEAN_REDUX_STORE_PATH                   = '/auth/reloading';

export const ROUTER_TO_SEARCH_PATH                              = '/search';

export const ROUTER_TO_PUBLIC_SCHOOLPROFILE_PATH                = '/public/school'
// school info pages
export const ROUTER_TO_PUBLIC_USERPROFILE_PATH                  = '/public/user'

export const ROUTER_TO_PROVINCE_SCHOOLS_PATH                    = '/schools' // + /ca-ontario
export const ROUTER_TO_SHORT_PALY_PATH                           = '/shorts' // + /:id
export const ROUTER_TO_SINGLE_POST_PATH                           = '/posts' // + /:id
export const ROUTER_TO_POSTS_PATH                           = '/posts' 
export const ROUTER_TO_SHORTS_PATH                           = '/shorts' 
export const ROUTER_TO_ACCOUNT_PATH                           = '/account' 
