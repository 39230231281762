import React, {useState} from 'react'
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Paper from '@material-ui/core/Paper';


import axios from 'utils/mcaxios';
import {
  renderInputComponent,
  renderSuggestion,
  getSectionSuggestions,
  renderSectionTitle,
  shouldRenderSuggestions
} from 'utils/AutoSuggestFuncs';

import useStyles from './McTextFieldAutoSuggestMulti.style';
import { noauthService } from 'service';

const McTextFieldAutoSuggestMulti = (props) => {
  const { id, label, margin, name, url, formState, 
    handleChange, handleClearSchool,
    hasError, getSuggestionValue } = props;
  const classes = useStyles();
  /** autocomplete */
  let [suggestions, setSuggestions] = useState([]); 
  const [autocompleteLoading, setAutoCompleteLoading] = useState(false);//useSelector(state => state.auth.autocompleteSchoolLoading);
  /**Schools autocomplete */
  suggestions = (suggestions == null ? [] : suggestions);
  const handleSuggestionsFetchRequested = async ({ value }) => {
    if(_.trim(value) !== formState.values[name]){
      setAutoCompleteLoading(true);
      const schools = await noauthService.autocompleteSchoolByCountry(
        formState.values['country'],
        _.trim(value),
      )
      // axios.get(url.concat('/').concat(value))
        .then(function (response) {
          const schools = response;
          if (schools) {
            let matchedSchools = JSON.parse(JSON.stringify(schools));
            const suggestions = matchedSchools.map((school) => {
              let parsedSchool = JSON.parse(school);
              return {
                id: parsedSchool.id,
                title:
                  parsedSchool.name +
                  ', ' +
                  parsedSchool.city +
                  ', ' +
                  parsedSchool.province,
              };
            });
            setSuggestions(suggestions);
            // setAllMatchedSchools(matchedSchools);
          }
          // const suggestions = response.data;
          // if(suggestions){
          //   setSuggestions(suggestions);
          // }
          setAutoCompleteLoading(false);
        })
        .catch(function (error) {
          setAutoCompleteLoading(false);
          console.log(error);
        });
    }
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  const autosuggestProps = {
    renderInputComponent,//Use it only if you need to customize the rendering of the input.
    suggestions: suggestions, // Array These are the suggestions that will be displayed. Items can take an arbitrary shape.
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,// Will be called every time you need to recalculate suggestions.
    onSuggestionsClearRequested: handleSuggestionsClearRequested, //Will be called every time you need to set suggestions to [].
    getSuggestionValue: getSuggestionValue, //Implement it to teach Autosuggest what should be the input value when suggestion is clicked.
    renderSuggestion,//Use your imagination to define how suggestions are rendered.
    getSectionSuggestions, //when multiSection={true} Implement it to teach Autosuggest where to find the suggestions for every section.
    shouldRenderSuggestions,//When the input is focused, Autosuggest will consult this function when to render suggestions. Use it, for example, if you want to display suggestions when input value is at least 2 characters long.
    renderSectionTitle // when multiSection={true}, Use your imagination to define how section titles are rendered.
    // renderSuggestionsContainer //Use it if you want to customize things inside the suggestions container beyond rendering the suggestions themselves.
    // theme //	Use your imagination to style the Autosuggest.
  };

  const handleClearAutoComplete = () => {
    handleSuggestionsClearRequested();
    handleClearSchool(name);
  }
  return (
    <Autosuggest
      {...autosuggestProps}
      focusInputOnSuggestionClick={false}
      inputProps={{
        classes,
        error: hasError(name),
        helperText: hasError(name) ? formState.errors[name][0] : null,
        name: name,
        onChange: handleChange(name),
        value: formState.values[name] || '',
        id: id,
        label: label,
        margin: margin,
        onClearAutoComplete: handleClearAutoComplete,
        placeholder: 'Search your '.concat(name),
        loading: autocompleteLoading
      }}
      multiSection
      name={name}
      renderSuggestionsContainer={options => {
        let elementList = '';
        if (options.children) {
          elementList =
            <Paper
              {...options.containerProps}
              square
            >
              <PerfectScrollbar>
                {options.children}
              </PerfectScrollbar>
            </Paper>
        }
        return elementList;
      }}
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
        sectionTitle: classes.sectionTitle
      }}
    />
  )
}
McTextFieldAutoSuggestMulti.propTypes = {
  formState: PropTypes.object,
  getSuggestionValue : PropTypes.func, 
  handleChange: PropTypes.func, 
  handleClearSchool: PropTypes.func, 
  hasError: PropTypes.func, 
  id: PropTypes.string,  
  label: PropTypes.string,  
  margin: PropTypes.string,  
  name: PropTypes.string,  
  url: PropTypes.string,
};
export default McTextFieldAutoSuggestMulti
