import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { teal } from '@material-ui/core/colors';

const McButtonOutlined = withStyles(() => ({
  root: {
    color: '#009899',
    border: '1px solid #4db6ac',
    '&:hover': {
      border: '1px solid #009899',
      backgroundColor: teal[50]
    },
    '&$disabled': {
      border: '1px solid #b2dfdb',
      color: teal[100]
    }
  },
  disabled: {},
}))(Button);
export default McButtonOutlined;