import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
  root: {

  },
  schoolDiv: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: theme.spacing(1.5),
    fontSize: '1em'
  }
}));

export default useStyles;