import * as actionTypes from '../actions/actionTypes';
import _ from 'lodash';
const initialState = {
  schoolsInfo: [],
};
const schoolsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SCHOOL_INFO_SUCCESS: {
      return {
        ...state,
        schoolsInfo: action.schoolsInfo,
      };
    }
    /*  */
    case actionTypes.UNSUBSCRIBE_SCHOOL_SUCCESS: {
      let filteredSchools = _.remove(state.schoolsInfo, (school) => {
        return school.schoolId !== action.schoolId;
      });
      return {
        ...state,
        schoolsInfo: [...filteredSchools],
      };
    }
    case actionTypes.SUBSCRIBE_SCHOOL_SUCCESS: {
      return {
        ...state,
        schoolsInfo: [...state.schoolsInfo, action.schoolInfo],
      };
    }
    case actionTypes.CHANGE_CURRENT_SCHOOL_SUCCESS: {
      return {
        ...state,
        schoolsInfo: action.schoolsInfo,
      };
    }
    case actionTypes.REGISTER_CURRENT_SCHOOL_SUCCESS: {
      return {
        ...state,
        schoolsInfo: [...state.schoolsInfo, action.schoolInfo],
      };
    }
    case actionTypes.CLEAN_SCHOOL_INFOS: {
      return {
        schoolsInfo: [],
      };
    }
    default: {
      return state;
    }
  }
}

export default schoolsReducer;
