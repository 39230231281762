import * as actionTypes from './actionTypes';

export const UpdateSingleFeedPost = (feedItem) => {
  return {
    type: actionTypes.UPDATE_SINGLE_FEED_POST,
    feedItem: feedItem,
  };
};

export const DeleteFeedPost = (feedId) => {
  return {
    type: actionTypes.DELETE_SINGLE_FEED_POST,
    feedId: feedId,
  };
};
export const DeleteFeedPostsByUserId = (userId) => {
  return {
    type: actionTypes.DELETE_FEED_POSTS_BY_USERID,
    userId: userId,
  };
};
export const SetFeedStatus = (feedId, status) => {
  return {
    type: actionTypes.SET_SINGLE_FEED_POST_STATUS,
    feedId: feedId,
    status: status,
  };
};
export const FetchPostCategoriesSuccess = (postCategories) => {
  return {
    type: actionTypes.FETCH_POST_CATEGORIES_SUCCESS,
    postCategories: postCategories,
  };
};
// when first load feed(home) screen
export const SetMainFeedPosts = (mainFeedPosts, nextSkip) => {
  return {
    type: actionTypes.SET_MAIN_FEED_POSTS,
    mainFeedPosts: mainFeedPosts,
    nextSkip: nextSkip,
  };
};
// when swipe down feed(home) screen, get the latest posts
export const ResetMainFeedPosts = (mainFeedPosts, nextSkip) => {
  return {
    type: actionTypes.RESET_MAIN_FEED_POSTS,
    mainFeedPosts: mainFeedPosts,
    nextSkip: nextSkip,
  };
};

// in MainStackNavigator, initialize my liked posts
export const SetPostLike = (newPostLikes) => {
  return {
    type: actionTypes.SET_POST_LIKES,
    postLikes: newPostLikes,
  };
};
// after I press like, update post likeCount in redux.
export const SetPostLatestLikesCount = (feedId, latestCount) => {
  return {
    type: actionTypes.SET_POST_LATEST_LIKES_COUNT,
    feedId: feedId,
    latestCount: latestCount,
  };
};

export const CleanPostInfos = () => {
  return {
    type: actionTypes.CLEAN_POST,
  };
};

// import * as actionTypes from './actionTypes';

// export const FetchPostCategoriesSuccess = (postCategories) => {
//   return {
//     type: actionTypes.FETCH_POST_CATEGORIES_SUCCESS,
//     postCategories: postCategories
//   };
// }
