import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {  teal, grey } from '@material-ui/core/colors';
import { styled } from '@mui/material/styles';
const McButton = styled(Button)({
  color: 'white', 
  backgroundColor: '#007f99', 
  borderColor: '#007f99',
  '&:hover': {
    backgroundColor: '#007f99ba',
    color: '#fff',
    borderColor: '#007f99'
  },
  // boxShadow: 'none',
  // textTransform: 'none',
  // fontSize: 16,
  // padding: '6px 12px',
  // border: '1px solid',
  // lineHeight: 1.5,
  // backgroundColor: '#0063cc',
  // borderColor: '#0063cc',
  // fontFamily: [
  //   '-apple-system',
  //   'BlinkMacSystemFont',
  //   '"Segoe UI"',
  //   'Roboto',
  //   '"Helvetica Neue"',
  //   'Arial',
  //   'sans-serif',
  //   '"Apple Color Emoji"',
  //   '"Segoe UI Emoji"',
  //   '"Segoe UI Symbol"',
  // ].join(','),
  // '&:hover': {
  //   backgroundColor: '#0069d9',
  //   borderColor: '#0062cc',
  //   boxShadow: 'none',
  // },
  // '&:active': {
  //   boxShadow: 'none',
  //   backgroundColor: '#0062cc',
  //   borderColor: '#005cbf',
  // },
  // '&:focus': {
  //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  // },
});
// const  McButton = withStyles(theme => ({
//   root: {
//     color: theme.palette.getContrastText(teal[400]),
//     backgroundColor: teal[300],
//     '&:hover': {
//       backgroundColor: '#009899',
//     },
//     '&$disabled': {
//       backgroundColor: teal[100],
//       color: grey[50]
//     },
//     border: 'none'
//   },
//   disabled: {},
// }))(Button);
export default McButton;