import * as actionTypes from './actionTypes';
import { buildClassConvoInfo } from 'utils/ChatUtils';

export const PopulateClazzesToChatBar = (myClasses) => {
  return {
    type: actionTypes.POPULATE_MYCLAZZES_TO_CHATBAR,
    clazzes: myClasses,
  };
}
export const AddMyClazzToChatBar = (clazz) => {
  return {
    type: actionTypes.ADD_MYCLAZZ_TO_CHATBAR,
    clazz: clazz,
  };
}
export const DeleteMyClazzFromChatBar = (classId) => {
  return {
    type: actionTypes.REMOVE_MYCLAZZ_FROM_CHATBAR,
    classId: classId,
  };
}
/* [ Create new conversation ] */
export const StartClassConversation = (classId, programName) => {

  const classConvoInfo = buildClassConvoInfo(classId, programName);
  // dispatch( addFriendToChatBar(conversation.otherUser) );
  return {
    type: actionTypes.ADD_NEW_CLASS_CONVERSATION,
    classConvoInfo: classConvoInfo
  };
}
/* [ Populate classmate for a class, only request for one time ] */
export const PopulateClassmates = (classId, classMates) => {
  return {
    type: actionTypes.POPULATE_CLASS_CLASSMATES,
    classId: classId,
    classMates: classMates
  };
}
/* [ Just close the conversion, not remove ] */
export const CloseClassConvoInfo = (classId) => {
  return {
    type: actionTypes.CLOSE_CLASS_CONVERSATION,
    classId: classId,
  };
}
export const UpdateChatroomOnlineMembers = (classId, memberIds) => {
  return {
    type: actionTypes.UPDATE_CHATROOM_ONLINE_MEMBERS,
    classId: classId,
    memberIds: memberIds
  };
}

export const ChatroomMemberJoined = (classId, memberId) => {
  return {
    type: actionTypes.CHATROOM_MEMBER_JOINED,
    classId: classId,
    memberId: memberId
  };
}
export const ChatroomMemberLeft = (classId, memberId) => {
  return {
    type: actionTypes.CHATROOM_MEMBER_LEFT,
    classId: classId,
    memberId: memberId
  };
}
export const AddMsgToClassConvoInfo = (msg, classId, senderFlag) => {
  return {
    type: actionTypes.ADD_MSG_TO_CLASS_CONVO,
    msg: msg,
    classId: classId, 
    senderFlag: senderFlag
  };
}
export const AddUnreadMsgToClassConvoInfo = (msg, classId, unreadCount) => {
  return {
    type: actionTypes.ADD_UNREAD_MSG_TO_CLASS_CONVO,
    msg: msg,
    classId: classId, 
    unreadCount: unreadCount
  };
}

export const ResetClassUnreadMsgToZero = (classId) => {
  return {
    type: actionTypes.RESET_CLASS_UNREAD_MSGS_TO_ZERO,
    classId: classId
  };
}

export const OpenClassBar = (classId) => {
  return {
    type: actionTypes.OPEN_CLASS_BAR,
    classId: classId,
  };
}

export const CloseClassBar = () => {
  return {
    type: actionTypes.CLOSE_CLASS_BAR,
  };
}

export const UpdateClassMsgImgUrl = (uuid, url, classId) => {
  return {
    type: actionTypes.UPDATE_CLASS_MSG_IMG_URL,
    uuid: uuid,
    url: url,
    classId: classId
  };
}

export const UpdateFailedClassMsgImgUrl = (uuid, classId, imgUrl) => {
  return {
    type: actionTypes.UPDATE_FAILED_CLASS_MSG_IMG_URL,
    uuid: uuid,
    classId: classId, 
    imgUrl: imgUrl
  };
}
export const CleanClassConvoMsgs = () => {
  return {
    type: actionTypes.CLEAN_CLASS_CONVO_MSGS,
  };
}
export const RemoveMsgFromClassConvo = ( classId, msgId) => {
  return {
    type: actionTypes.REMOVE_MSG_FROM_CLASS_CONVO,
    classId: classId,
    msgId: msgId
  };
}
export const DeleteMyClazzFromChatBoxBar = ( classId) => {
  return {
    type: actionTypes.REMOVE_CLASS_CONVERSATION,
    classId: classId,
  };
}