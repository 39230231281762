import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles(theme => ({
  notificationPanel: {
    flexGrow: 1,
    maxHeight: '100%',
    position: 'relative',
    height: '245px',
  },
  notificationContent: {
    padding: theme.spacing(1),
    paddingTop: '0px',
    height: '225px'
  },
  divider: {
    marginBottom: theme.spacing(1)
  },
  showMsg: {
    left: '50%',
    marginTop: '-10px',
    borderTop: 'none',
    transform: 'translate(-50%, 0)',
    padding: theme.spacing(1)
  },
}));
export default useStyles;