import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { colors } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles(theme => ({
  submitting: {
    marginRight: theme.spacing(1),
    color: 'coral',
  },
  querying: {
    marginRight: theme.spacing(1),
    color:  colors.blue[500]
  },
  loadingDiv: {
    textAlign: 'center',
  },
}));

const McLoader = props => {
  const { loading, type, className, size, thickness } = props;
  const classes = useStyles();
  let result = '';
  if(loading){
    result =     
    <div className={classes.loadingDiv}>
      <CircularProgress 
        className={clsx(
          className,
          classes.submitting,
          {
            [classes.querying] : (type === 'query'), 
          }
        )}
        size={size == null ? 20 : size} 
        thickness={thickness == null ? 5 : thickness}
      />
    </div>
  }
  return result;
};
McLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
  type: PropTypes.string
};

export default McLoader;
