import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
  imageGallery: {
    display: 'block',
    minHeight: '1px',
    width: '100%',
    overflow: 'hidden'
  },
  imageDiv: {
    borderRadius: theme.spacing(1.5),
  },
  thumbnail: {
    '&:hover': {
      cursor: 'pointer'
    },
    '& .MuiGridListTile-tile': {
      borderRadius: theme.spacing(0.75)
    }
  }
}));
export default useStyles;