import * as actionTypes from './actionTypes';

export const FetchSchoolsInfoSuccess = (schoolsInfo) => {
  return {
    type: actionTypes.FETCH_SCHOOL_INFO_SUCCESS,
    schoolsInfo: schoolsInfo,
  };
};
export const UnsubscribeSchool = (schoolId) => {
  return {
    type: actionTypes.UNSUBSCRIBE_SCHOOL_SUCCESS,
    schoolId: schoolId,
  };
};
export const SubscribeSchool = (schoolInfo) => {
  return {
    type: actionTypes.SUBSCRIBE_SCHOOL_SUCCESS,
    schoolInfo: schoolInfo,
  };
};
export const ChangeCurrentSchool = (schoolsInfo) => {
  return {
    type: actionTypes.CHANGE_CURRENT_SCHOOL_SUCCESS,
    schoolsInfo: schoolsInfo,
  };
};
export const RegisterCurrentSchool = (schoolInfo) => {
  return {
    type: actionTypes.REGISTER_CURRENT_SCHOOL_SUCCESS,
    schoolInfo: schoolInfo,
  };
};
export const CleanSchoolInfos = () => {
  return {
    type: actionTypes.CLEAN_SCHOOL_INFOS,
  };
};

// import * as actionTypes from './actionTypes';

// export const FetchSchoolsInfoSuccess = (schoolsInfo) => {
//   return {
//     type: actionTypes.FETCH_SCHOOL_INFO_SUCCESS,
//     schoolsInfo: schoolsInfo
//   };
// }
// export const UnsubscribeSchool = ( schoolId) => {
//   return {
//     type: actionTypes.UNSUBSCRIBE_SCHOOL_SUCCESS,
//     schoolId: schoolId
//   };
// }
// export const SubscribeSchool = ( schoolInfo) => {
//   return {
//     type: actionTypes.SUBSCRIBE_SCHOOL_SUCCESS,
//     schoolInfo: schoolInfo
//   };
// }
// export const ChangeCurrentSchool = ( schoolsInfo) => {
//   return {
//     type: actionTypes.CHANGE_CURRENT_SCHOOL_SUCCESS,
//     schoolsInfo: schoolsInfo
//   };
// }
// export const RegisterCurrentSchool = ( schoolInfo) => {
//   return {
//     type: actionTypes.REGISTER_CURRENT_SCHOOL_SUCCESS,
//     schoolInfo: schoolInfo
//   };
// }
// export const CleanSchoolInfos = () => {
//   return {
//     type: actionTypes.CLEAN_SCHOOL_INFOS
//   };
// }