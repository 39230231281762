import gradients from 'utils/gradients';

export const getRandomAvatarBgColor = (bgColor) => {
  const classes = {
    avatarBlue: {
      backgroundImage: gradients.blue
    },
    avatarGreen: {
      backgroundImage: gradients.green
    },
    avatarOrange: {
      backgroundImage: gradients.orange
    },
    avatarIndigo: {
      backgroundImage: gradients.indigo
    },
    avatarPurple: {
      backgroundImage: gradients.purple
    },
    avatarPink: {
      backgroundImage: gradients.pink
    },
    avatarLime: {
      backgroundImage: gradients.lime
    },
    avatarLightBlue: {
      backgroundImage: gradients.lightBlue
    },
  };
  return classes[bgColor];
};

export const getAvatarBgColorsName = () => {
  let avatarBgColors = 
  ['avatarBlue', 'avatarGreen', 
    'avatarOrange', 'avatarIndigo',
    'avatarPurple', 'avatarPink', 'avatarLime',
    'avatarLightBlue'
  ]
  return avatarBgColors[Math.floor(Math.random()*avatarBgColors.length)];
}

