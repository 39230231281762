import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  paper: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    padding: theme.spacing(0.5, 0.5),
    maxWidth: '80%',
  },
  scrollbar: {
    maxHeight: '80px',
  },
  input: {
    width: '100%',
    overflow: 'auto',
    padding: '2px', 
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    fontSize: '1em',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    '&:focus': {
      backgroundColor: '#0096880a',
      outline: 'none',
    },
    '& .twemoji': {
      width: '22px',
      marginLeft: '1px',
      marginRight: '1px',
      verticalAlign: 'middle',
    }
  },
  divider: {
    width: 1,
    height: 24
  },
  fileInput: {
    display: 'none'
  },
  sendBtn: {
    padding: '4px'
  },
  inputCount: {
    textAlign: 'right',
    fontSize: '0.7em',
    verticalAlign: 'text-bottom',
    padding: theme.spacing(0.25),
    paddingTop: theme.spacing(0.75)
  },
  error: {
    color: colors.red[500],
  },
  commentInputGrid: {
    display: 'flex',
    alignItems: 'center'
  },
  commentBtnGrid: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
}));
export default useStyles;