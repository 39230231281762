import React                                      from 'react';
import { useDispatch }                            from 'react-redux';
import { useSelector }                            from 'react-redux';
import PropTypes                                  from 'prop-types';
import _                                          from 'lodash';
import clsx from 'clsx';

import Tooltip                from '@material-ui/core/Tooltip';
import Link                   from '@material-ui/core/Link';

import { DisplaySimpleSnacker }                   from 'store/actions';
import { MC_SNACKER_TYPE_WARNING }                from 'mcconfig';
import useStyles                                  from './McAuthLink.style';

const McAuthLink = props => {
  const {className, variant, title, onClick, children, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const {loggedIn, token} = useSelector(state => state.auth);

  const handleClickLink = () => {
    if(loggedIn && !_.isEmpty(token)){
      onClick();
    }else{
      dispatch(DisplaySimpleSnacker('Sign in please', MC_SNACKER_TYPE_WARNING));
    }
  }
  return (
    <Tooltip 
      title={title} 
    >
      <Link 
        {...rest}
        className={clsx(classes.root, className)}
        href="#" 
        onClick={handleClickLink}
        variant={variant}
      >
        {children}
      </Link>
    </Tooltip>
  )
};

McAuthLink.propTypes = {
  children:       PropTypes.node,
  className:      PropTypes.string,
  onClick:        PropTypes.func,
  title:          PropTypes.string,
  variant:        PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2', 'button', 'caption', 'overline']),
};

export default McAuthLink;
