import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    
  },
  bubble: {
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'white'
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  time: {
    marginLeft: 'auto'
  },
  message: {
  },
  likedButton: {
    color: colors.red[600]
  },
  likeButton: {
  },
  likeReplyBar: {
    display: 'flex',
    alignItems: 'center'
  },
  replyButton: {
    marginLeft: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  replyContent: {
    fontSize: '15px',
    padding: '8px 0px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',

    // wordWrap: 'anywhere',
    overflowWrap: 'anywhere',
    whiteSpace: 'break-spaces',
    wordWrap: 'break-word',
    wordBreak:'break-all',
    
    '& .twemoji': {
      width: '22px',
      marginLeft: '1px',
      marginRight: '1px',
      verticalAlign: 'middle',
    }
  },
}));
export default useStyles;