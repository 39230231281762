import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: '#007f99'

    // /* #007f99 009899 */
    // backgroundColor: '#159957',
    // backgroundImage: 'linear-gradient(120deg, #0b72c3, #4CAF50)',
    // background-image: linear-gradient(150deg, #0b72c3, #4CAF50, #980794);
  },
  flexGrow: {
    flexGrow: 1
  },
  toolBar: {
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '15px',
      paddingRight: '15px'
    },
    width: '100%',
    paddingLeft: 0,
    paddingRight: '5px'
  },
  toolBarMobile: {
    minHeight: theme.spacing(3.5),
    height: theme.spacing(4.75),
  },
  signIn: {
    color: 'orange',
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(0.5),
  },
  register: {
    color: 'orange',
    whiteSpace: 'nowrap',
  },
  createPost:{
    color: 'orange',
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    paddingRight: '10px',
    backgroundColor: '#2d92a7',
    [theme.breakpoints.down('sm')]: {
      minWidth:'32px',
      paddingRight:'2px'
    },
    '&:hover': {
      backgroundColor: 'white'
    },
  }
}));

export default useStyles;