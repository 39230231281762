import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';

import useStyles from './McAlert.style';
import { McButton, McButtonText } from 'components';
// eslint-disable-next-line react/display-name
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide 
    direction="down" 
    ref={ref} 
    {...props}
    // eslint-disable-next-line react/jsx-closing-bracket-location
  />;
});

const McAlert= (props) => {
  const { title, onClose, variant, handleConfirm, open, message, children } = props;
  const classes = useStyles();
  const icons = {
    default: <InfoIcon className={classes.default}/>,
    success: <CheckCircleIcon className={classes.success}/>,
    info: <InfoIcon className={classes.info}/>,
    warning: <WarningIcon className={classes.warning}/>,
    error: <ErrorIcon className={classes.error}/>
  };
  return (
    <Dialog
      TransitionComponent={Transition}
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      classes={{
        paper: classes.paper
      }}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      onClose={onClose}
      open={open}
    >
      <DialogTitle 
        className={classes.title}
        disableTypography 
      >
        <Typography variant="body2">
          {title}
        </Typography>
        {onClose ? (
          <IconButton 
            aria-label="close" 
            className={classes.closeButton} 
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <div className={classes.icon}>{icons[variant]}</div>
        <Typography variant="body1">
          {message}
        </Typography>
        {children}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <McButtonText
          onClick={onClose}
        >
          Cancel
        </McButtonText>
        <McButton
          className={classes.submitButton}
          onClick={handleConfirm}
        >
          Submit
        </McButton>
      </DialogActions>
    </Dialog>
  );
}
McAlert.propTypes = {
  children: PropTypes.node,
  handleConfirm: PropTypes.func, 
  message: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'info', 'success', 'warning', 'error'])
};
McAlert.defaultProps = {
  variant: 'default'
};
export default McAlert