import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {  teal } from '@material-ui/core/colors';

const McButtonText = withStyles(() => ({
  root: {
    color: '#009899',
    '&:hover': {
      backgroundColor: teal[50]
    },
    '&$disabled': {
      color: teal[100]
    }
  },
  disabled: {},
}))(Button);
export default McButtonText;