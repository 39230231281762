import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.white,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  user: {
    flexShrink: 0,
    flexGrow: 1
  },
  activity: {
    display: 'flex',
    alignItems: 'center'
  },
  statusBullet: {
    marginRight: theme.spacing(1)
  },
  avatarTxt: {
    width: '25px',
    height: '25px',
    fontSize: '1.0em'
  }
}));
export default useStyles;