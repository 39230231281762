import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import imageCompression from 'browser-image-compression';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import CachedIcon from '@material-ui/icons/Cached';

import { UpdateAvatar } from 'store/actions';
import { McDialogModal, McLoader} from 'components';
import useStyles from './UploadAvatarModal.style';

const UploadAvatarModal = (props) => {
  const {onClose, open } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [preViewLoading, setPreviewLoading] = useState(false);
  const {avatarUrl} = useSelector(state => state.auth.loginUserInfo);
  const [src, setSrc] = useState('/images/logos/mc-logo-green.png');
  const cropper = React.createRef(null);
  const fileInputRef = useRef(null);
  useEffect(() => {
    let mounted = true;
    if(mounted){
      setLoading(false);
      onClose();
    }
    return () => { mounted = false }
  }, [avatarUrl])// eslint-disable-line
  const handleAttach = () => {
    fileInputRef.current.click();
  }
  const onChange = async (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if(files.length > 0){
      setPreviewLoading(true);
      const reader = new FileReader();
      reader.onload = () => {
        setSrc(reader.result);
      };
      let options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      // Compress image to less than 1 MB, after 
      const compressedFile = await imageCompression(files[0], options);

      // If after compress, it still larger than orginal, using orginal
      if(compressedFile.size < files[0].size){

        reader.readAsDataURL(compressedFile);
      }else{

        reader.readAsDataURL(files[0]);
      }
      setPreviewLoading(false);
    }
  }

  const handleSubmit = () => {

    if (typeof cropper.current.getCroppedCanvas() === 'undefined') {
      return;
    }
    // setCropResult(cropper.current.getCroppedCanvas().toDataURL());
    const data = new FormData();
    data.append('file', cropper.current.getCroppedCanvas().toDataURL('image/jpeg'));

    uploadAvatar(data);
  }
  const uploadAvatar = (formdata) => {
    setLoading(true);
    dispatch(UpdateAvatar(formdata));
  }
  const rotateLeft = () => {
    cropper.current.rotate(-5);
  }
  const rotateRight = () => {

    cropper.current.rotate(5)
  }
  const swapHori = () => {
    const {scaleX} = cropper.current.getData(true);
    if(scaleX === 1) {
      cropper.current.scaleX(-1);
    }else{
      cropper.current.scaleX(1);
    }
  }
  const swapVert = () => {
    const {scaleY} = cropper.current.getData(true);
    if(scaleY === 1){
      cropper.current.scaleY(-1);
    }else{
      cropper.current.scaleY(1);
    }
  }
  const reset = () => {
    cropper.current.reset();
  }
  const handleClose = () => {
    setLoading(false);
    onClose();
  }
  return (
    <Fragment>
      <form>
        <input 
          accept="image/x-png,image/gif,image/jpeg"
          className={classes.input} 
          id="icon-button-file" 
          onChange={onChange} 
          ref={fileInputRef}
          type="file" 
        />
      </form>
      <McDialogModal
        handleSubmit={handleSubmit}
        loading={loading}
        onClose={handleClose}
        open={open}
        title="Change Avatar"
      >
        <div
          className={classes.root}
        >
          <div
            className={classes.cropArea}
          >
            <Cropper
              aspectRatio={1 / 1}
              guides
              minContainerWidth={150}
              preview=".img-preview"
              ref={cropper}
              src={src}
              style={{ height: 300, width: '100%' }}
              viewMode={0}
            />
          </div>
          <div className={classes.previewOperationArea}>
            <div className={classes.previewArea}>
              
              <div className={classes.gap}>

                             
                <IconButton 
                  aria-label="upload picture" 
                  color="primary" 
                  component="span"
                  disabled={preViewLoading}
                  onClick={handleAttach}
                >
                  { preViewLoading && 
                    <McLoader 
                      className={classes.loadingImg}
                      loading={preViewLoading} 
                      size={28}
                      thickness={4}
                    />
                  }
                  { !preViewLoading && 
                    <PhotoCamera />
                  }
                </IconButton>
              </div>
              <div 
                className={clsx('img-preview', classes.previewAvatar)}
              />
            </div>
            <div className={classes.operation}>
              <IconButton 
                className={classes.operationBtn}
                color="primary" 
                onClick={rotateLeft}
                title="Rotate left"
              >
                <RotateLeftIcon />  
              </IconButton>
              <IconButton 
                className={classes.operationBtn}
                color="primary" 
                onClick={rotateRight}
                title="Rotate right"
              >
                <RotateRightIcon />
              </IconButton>
              <IconButton 
                className={classes.operationBtn}
                color="primary" 
                onClick={swapHori}
                title="Swap horizontally"
              >
                <SwapHorizIcon />
              </IconButton>
              <IconButton 
                className={classes.operationBtn}
                color="primary" 
                onClick={swapVert}
                title="Swap vertically"
              >
                <SwapVertIcon />
              </IconButton>
              <IconButton 
                className={classes.operationBtn}
                color="primary" 
                onClick={reset}
                title="reset"
              >
                <CachedIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </McDialogModal>
    </Fragment>
  )
}
UploadAvatarModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};
  
export default UploadAvatarModal
