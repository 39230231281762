import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {},
  avatarBox: {
    color: colors.blue,
    textAlign: 'center'
  },
  avatar: {
    backgroundColor: '#4CAF50', 
    color: 'white',
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  content: {
    padding: theme.spacing(2),
    paddingTop: 0
  },
  divider: {
    width: 1,
    height: 24
  },
  cardAction: {
    backgroundColor: colors.grey[100],
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  applyButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  classCodeText: {
    padding: '10px'
  }
}));
export default useStyles;