import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(1, 2),
    margin: '0 auto'
  },
  desc: {
    height: 120, 
    overflow: 'auto',
    padding: 5, 
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: '1px solid #4db6ac',
    fontSize: '1em',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    '&:focus': {
      backgroundColor: '#0096880a',
      borderBottom: '2px solid #4db6ac',
      outline: 'none',
    }
  },
  gridList: {
    width: '100%'
  }, 

  imageGallery: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  closeButtonIcon: {
    color: 'white',
  },

  closeButtonIconRoot: {
    fontSize: '20px'
  },
  removeMediaUrlIconRoot: {
    fontSize: '30px'
  },
  iconHover: {
    '&:hover': {
      color: colors.red[400],
    },
  },
  fileInput: {
    display: 'none'
  },
  imageTitleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.3) 0%, ' +
      'rgba(0,0,0,0.08) 60%, rgba(0,0,0,0) 100%)',
  },
  actions: {
    backgroundColor: colors.grey[100],
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end'
  },

  waitingIcon: {
    position: 'absolute', 
    marginTop: '25%', 
    marginLeft: '40%'
  },
  loadingPreview: {
    width: '60%', 
    border: '2px dashed rgba(255, 152, 0, 0.20)', 
    borderRadius: '5px'
  },
  emojiPopper: {
    zIndex: 1500
  },
  menu: {
    display: 'flex',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    '& .MuiListItemIcon-root': {
      
    }
  },
  menuItem: {
    paddingLeft: theme.spacing(1),
  },
  inputCount: {
    textAlign: 'right',
    paddingRight: theme.spacing(1),
  },
  error: {
    color: colors.red[500],
  },
  insertMediaUrlField: {
    display: 'flex'
  },
  reactPlayerDiv: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default useStyles;