import React, { 
  useEffect, 
  Fragment, 
  useState, 
  useRef }              from 'react';
import useRouter        from 'utils/useRouter';
import { useSelector }  from 'react-redux';
import PropTypes        from 'prop-types';
import _                from 'lodash';

// import IconButton           from '@material-ui/core/IconButton';
import Tooltip              from '@material-ui/core/Tooltip';
import Input                from '@material-ui/core/Input';
import Popper               from '@material-ui/core/Popper';
// import Paper                from '@material-ui/core/Paper';
import ListItemIcon         from '@material-ui/core/ListItemIcon';
import ListItemText         from '@material-ui/core/ListItemText';
import List                 from '@material-ui/core/List';
import ListItem             from '@material-ui/core/ListItem';
import ClickAwayListener    from '@material-ui/core/ClickAwayListener';
import Typography           from '@material-ui/core/Typography';
// import SearchIcon           from '@material-ui/icons/Search';
import NativeSelect         from '@material-ui/core/NativeSelect';
// import InputBase            from '@material-ui/core/InputBase';

import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";


import { MC_SEARCH_TYPE_USER, 
  MC_SEARCH_TYPE_POST,
  MC_SEARCH_SCOPE_ALL_SCHOOL,
  MC_SEARCH_SCOPE_MY_SCHOOL }         from 'mcconfig'
import {  
  ROUTER_TO_SEARCH_PATH }             from 'store/routerPath';
import useStyles                      from './Search.style';
import { withStyles }                 from '@material-ui/core/styles';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: '5px 0 0 5px',
    position: 'relative',
    backgroundColor: '#eeeeee',//theme.palette.background.default,
    // border: '1px solid #ced4da',
    fontSize: theme.spacing(1.5),
    // padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    height: '22px',
    width: '82px',
    padding: 5
  },
}))(InputBase);
const popularSearches = [ MC_SEARCH_TYPE_POST,  MC_SEARCH_TYPE_USER];
const Search = (props) => {
  const { redirect} = props;
  const classes = useStyles();
  const router = useRouter();
  const searchRef = useRef(null);
  const schoolInfos                               = useSelector(state => state.schools.schoolsInfo);
  const {loggedIn, token}                         = useSelector(state => state.auth);
  const [keywords, setKeywords]                   = useState('');
  const [searchScope, setSearchScope]             = useState();
  const [openSearchPopover, setOpenSearchPopover] = useState(false);

  /* Store search keywords, if refresh browser, reload the keywords to input box */
  useEffect(() => {
    let mounted = true
    if(mounted){
      if(loggedIn && !_.isEmpty(token)){
        setSearchScope(MC_SEARCH_SCOPE_MY_SCHOOL);
      }else{
        setSearchScope(MC_SEARCH_SCOPE_ALL_SCHOOL);
      }
    }
    return () => {
      mounted = false
    }
  }, [loggedIn])// eslint-disable-line
  // Clean search input content, when path changed
  useEffect(() => {
    let mounted = true
    if(mounted){
      // console.log('redirect', redirect)
      if(!_.startsWith(redirect, ROUTER_TO_SEARCH_PATH) ){
        setKeywords('');
      }
    }
    return () => {
      mounted = false
    }
  }, [redirect])

  const handleSearchChange = event => {
    localStorage.setItem('cacheKeywords', event.target.value);
    setKeywords(event.target.value);
    if (_.trim(event.target.value)) {
      if (!openSearchPopover) {
        setOpenSearchPopover(true);
      }
    } else {
      setOpenSearchPopover(false);
    }
  };
  const handleSearch = () => {
    setOpenSearchPopover(false);
    if(keywords != null && !_.isEmpty(_.trim(keywords))){
      // console.log(searchScope, keywords)
      router.history.push({ 
        pathname: ROUTER_TO_SEARCH_PATH + '/' + searchScope + '/' + MC_SEARCH_TYPE_POST + '/' + keywords, 
      })
    }else{
      // set focus to input
    }
  };
  const handleSearchSelectedType = (searchType)=> () => {
    setOpenSearchPopover(false);
    router.history.push({ 
      pathname: ROUTER_TO_SEARCH_PATH + '/' + searchScope + '/' + searchType + '/' + keywords, 
    })
  };
  const handleSearchPopverClose = () => {
    setOpenSearchPopover(false);
  };
  const handleChange = (event) => {
    setSearchScope(event.target.value);
  };
  return (
    <Fragment>
      {/* <FormControl className={classes.searchSelect}> */}
      {/* <NativeSelect
        className={classes.searchSelect}
        id="demo-customized-select-native"
        input={<BootstrapInput />}
        onChange={handleChange}
        value={searchScope}
        variant="outlined"
      >
        {
          loggedIn 
          && 
          !_.isEmpty(token)
          &&
          !_.isEmpty(schoolInfos) 
          &&
          <option value={MC_SEARCH_SCOPE_MY_SCHOOL}>My Schools</option>
        }
        <option value={MC_SEARCH_SCOPE_ALL_SCHOOL}>All Schools</option>
      </NativeSelect> */}
      {/* <div
        className={classes.search}
        ref={searchRef}
      > */}
      <Box
        sx={{
          display: { lg: "inline-flex", xs: "none" },
          flex: "1 1 auto",
          flexDirection: "column",
          pl: { lg: "8%" },
        }}
      >

        <Paper
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            // width: 450,
            backgroundColor: "#f1f1f4",
            boxShadow: 'none',
            borderRadius: '10px'
          }}
        >
          <InputBase
            inputProps={{ "aria-label": "search MiCollege" }}
            placeholder="Search MiCollege"
            sx={{ ml: 1, flex: 1, p: "4px" }}
          />
          <IconButton type="button"  aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </Box>

      {/* </div> */}
      {/* <Popper
        anchorEl={searchRef.current}
        className={classes.searchPopper}
        open={openSearchPopover}
        transition
      >
        <ClickAwayListener onClickAway={handleSearchPopverClose}>
          <Paper
            className={classes.searchPopperContent}
            elevation={3}
          >
            <List
              className={classes.searchList}
            >
              {popularSearches.map(option => (
                <ListItem
                  button
                  key={option}
                  onClick={handleSearchSelectedType(option)}
                >
                  <ListItemIcon>
                    <SearchIcon />
                  </ListItemIcon>
                  <ListItemText 
                    primary={
                      <React.Fragment>
                        <Typography
                          className={classes.inputValue}
                          color="textPrimary"
                          component="span"
                          variant="subtitle1"
                        >
                          {keywords}
                        </Typography>
                        <Typography
                          className={classes.searchOption}
                          color="textPrimary"
                          component="span"
                          variant="body2"
                        >
                          {option}
                        </Typography>
                      </React.Fragment>
                    } 
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper> */}
    </Fragment>
  )
}
Search.propTypes = {
  redirect: PropTypes.string
};
export default Search
