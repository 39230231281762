import React from 'react';
import uuid from 'uuid/v1';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import _ from 'lodash';

import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

import { McTextField } from 'components';
const flagPath = {  path: '/images/countryFlags/' };
// //Use it only if you need to customize the rendering of the input.
export const renderInputComponent = (inputProps) => {
  const { loading, onClearAutoComplete,  classes, inputRef = () => {}, ref, ...other } = inputProps;
  const handleOnclick = () => {
    var popup = document.getElementById('react-autowhatever-1');
    if(popup){
      popup.style.display = 'block'
    }
  }
  return (
    <McTextField
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
        endAdornment: (
          <InputAdornment position="end">
            {loading && <CircularProgress size={24}/>}
            {/* {
              !loading && 
              <SearchIcon 
                size={24} 
                style={{color: '#546e7a'}}
              />
            } */}
            {
              !loading && 
              <ClearOutlinedIcon 
                onClick={onClearAutoComplete}
                size={24} 
                style={{
                  color: '#546e7a',
                  cursor: 'pointer'
                }}
              />
            }
          </InputAdornment>
        ),
      }}
      fullWidth
      {...other}
      onBlur={handleOnclick}
      variant="outlined"
    />
  );
};
//Use your imagination to define how suggestions are rendered.
export const renderSuggestion = (suggestion, { query, isHighlighted }) => {
  const matches = match(suggestion.title, query);
  // const parts = parse(suggestion.name, matches);
  // let localName = suggestion.name === suggestion.localName ? '' : suggestion.localName;
  let localName = '';
  if(!_.isEmpty(suggestion.localName) && !_.isEqual(suggestion.name, suggestion.localName)){
    localName = ' (' + suggestion.localName + ')'
  }
  const parts = parse( suggestion.title, matches);
  return (
    <MenuItem 
      component="div"
      key={'menu_item_'.concat(suggestion.id)}
      selected={isHighlighted} 
      style={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'flex-start', 
        flexWrap: 'wrap'
      }}
      title={suggestion.name}
    >
      <div key={'div_item'.concat(suggestion.id)}>
        {parts.map(part => (
          <span 
            key={uuid()}
            style={{ fontWeight: part.highlight ? 500 : 400 }}
          >
            {part.text}
          </span>
        ))}
      </div>
      {
        localName
        && 
        <Typography
          color="textSecondary"
          title={suggestion.city}
          variant="body2"
        >
          {suggestion.localName}
        </Typography>
      }
      {/* <Typography
        color="textSecondary"
        component="div"
        style={{ overflowWrap: 'anywhere',
          whiteSpace: 'break-spaces',
          wordWrap: 'break-word',
          wordBreak:'break-all'}}
        title={suggestion.city.concat(suggestion.province === '' ? '' : ', ' + suggestion.province)}
        variant="subtitle2"
      >
        {suggestion.city} {_.isEmpty(suggestion.province) ? '' : ', ' + suggestion.province}
      </Typography> */}
    </MenuItem>
  );
};

// when multiSection={true}, Use your imagination to define how section titles are rendered.
export const renderSectionTitle = (section) => {
  return (
    <span style={{display: 'flex'}}>
      <img
        alt="contry flag"
        src={flagPath.path + section.country + '.png'}
        style={{width: '25px', height: '20px', marginRight: '6px', border: '1px solid #d4d4d4', borderRadius: '3px'}}
      />
      <Typography
        color="textSecondary"
        variant="subtitle2"
      >
        {section.country}          
      </Typography>
    </span>
  );
}
//when multiSection={true} Implement it to teach Autosuggest where to find the suggestions for every section.
export const getSectionSuggestions = (section) => {
  return section.schools;
}
//When the input is focused, Autosuggest will consult this function when to render suggestions. Use it, for example, if you want to display suggestions when input value is at least 2 characters long.
export const shouldRenderSuggestions = (value) => {
  return value.trim().length > 2;
}

/*const escapeRegexCharacters = (str) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
// Array These are the suggestions that will be displayed. Items can take an arbitrary shape.
 export const getSuggestions = (value, countrySchools) => {
  const escapedValue = escapeRegexCharacters(value.trim());
  
  if (escapedValue === '') {
    return [];
  }

  const regex = new RegExp('^' + escapedValue, 'i');

  return countrySchools
    .map(section => {
      return {
        country: section.country,
        schools: section.schools.filter(language => regex.test(language.name))
      };
    })
    .filter(section => section.schools.length > 0);
} */