import axios from 'axios';

import { USER_NOT_EXIST_IN_REDIS_CODE } from '../store/statusCode';

const COOKIE_TOKEN = 'COOKIE_TOKEN';
const url = 'https://www.micolleger.com/';
// const url = 'http://10.2.0.141:9000/';
// const url = 'http://192.168.2.16:9000/';
const instance = axios.create({
  baseURL: url,
});
/* Do something before request is sent */
instance.interceptors.request.use(
  async (config) => {
    // const token = AsyncStorage.getItem('token');
    // const token = '';//await deviceStorage.getCookieToken();
    const token = localStorage.getItem(COOKIE_TOKEN);
    // console.log('===========[ AUTH REQUEST ]============');
    // console.log(config);
    // if there's token fetch user's info, notification, classmates etc
    if (token) {
      config.headers['Authorization'] = token;
      return config;
    } 
    // console.log('Operation canceled by the user.');
    throw new axios.Cancel('Operation canceled by the user.');
  },
  (error) => {
    // console.log(error);
    // Do something with request error
    return Promise.reject(error);
  },
);
// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log('===========[ AUTH RESPONSE ]============');
    // console.log(response.data);
    if (response && response.data) {
      const { status } = response.data; //message
      if (status === USER_NOT_EXIST_IN_REDIS_CODE) {
        // Snackbar.show({
        //   text: McLocalized(
        //     'Invalid session, your account may have logged in different device. Please log in again.',
        //   ),
        //   duration: Snackbar.LENGTH_LONG,
        //   backgroundColor: 'orange',
        //   // action: {
        //   //   text: 'Sign out',
        //   //   textColor: 'green',
        //   //   onPress: () => {
        //   //     console.log(dispatch);
        //   //   },
        //   // },
        // });
      }
    }
    return response;
  },
  (error) => {
    // const dispatch = useDispatch();
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response) {
      // console.error('error.response', error);
      switch (error.response.status) {
        // 400: empty token, 401: token expired,
        case 400:
          // console.log('400', error);
          // Snackbar.show({
          //   text: McLocalized('Something went wrong, please try again later'),
          //   duration: Snackbar.LENGTH_LONG,
          //   backgroundColor: 'orange',
          // });
          break;
        case 401:
          // console.log('401', error);
          break;
        case 500:
          // console.log('500', error);
          // Snackbar.show({
          //   text: McLocalized('Service not available now, please try it later'),
          //   duration: Snackbar.LENGTH_LONG,
          //   backgroundColor: 'orange',
          // });
          break;
        case 503:
          // console.log('503', error);
          // Snackbar.show({
          //   text: McLocalized(
          //     'Service not available now, please try again later',
          //   ),
          //   duration: Snackbar.LENGTH_LONG,
          //   backgroundColor: 'orange',
          // });
          break;
        default:
          // console.log('default', error);
          break;
      }
    }
    return Promise.reject(error);
  },
);

export default instance;
