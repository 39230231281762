import imageCompression from 'browser-image-compression';
import _ from 'lodash';
import { MC_CHAT_SENT_IMAGE_LOADING_PATH } from 'mcconfig'
export const loadingObj = {
  title: 'loading',
  src: MC_CHAT_SENT_IMAGE_LOADING_PATH
};
export const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true
}
export const checkMimeType = (event) => {
  let result = {isLegal: true, msg: ''};
  //getting file object
  let files = event.target.files
  //define message container
  let err = []
  // list allow mime type
  const types = ['image/png', 'image/jpeg', 'image/gif']
  // loop access array
  for (var x = 0; x < files.length; x++) {
    // compare file type find doesn't matach
    if(!_.includes(types, files[x].type)){
    // if (types.every(type => files[x].type !== type)) {
      // create error message and assign to container   
      err[x] = files[x].type + ' is not a supported format\n';
    }
  }
  if(err.length > 0){
    event.target.value = null
    result.isLegal = false;
    result.msg = 'Only support image types: image/png, image/jpeg, image/gif.';
    return result;
  }

  return result;
}
export const maxSelectFile = (event, selectedNum, maxNum) => {
  let result = {isLegal: true, msg: ''};
  let files = event.target.files
  if ((files.length + selectedNum) > maxNum) {
    let msg = 'Only ' + maxNum + ' images can be uploaded once.';//for a post, ' + (maxNum - selectedNum) + ' images left.
    event.target.value = null
    // toast.warn(msg)
    result.isLegal = false;
    result.msg = msg;
    return result;
  }
  return result;
}
export const checkFileSize = (event) => {
  let result = {isLegal: true, msg: ''};
  let files = event.target.files
  let size = 15728640 // 15MB
  let err = [];
  for (var x = 0; x < files.length; x++) {
    if (files[x].size > size) {
      err[x] = files[x].type + 'is too large, please pick a smaller file\n';
    }
  }
  console.error('error', err);
  if(err.length > 0){
    event.target.value = null
    result.isLegal = false;
    result.msg = 'Maximiam file size for a single image is 15MB.';
    return result;
  }
  return result;
}

/**[ CompressImage, if compressed size greater than original, use original ]**/
export const compressImage = async (x, file) => {
  try {
    const fileObj = {
      loaded: false,
      title: '',
      preview: null,
      file: null
    };
    const compressedFile = await imageCompression(file, options);
    if(compressedFile.size > file.size){
      fileObj.file = file;
    }else{
      fileObj.file = compressedFile;
    }
    fileObj.loaded = true;
    fileObj.title = file.name;
    fileObj.preview =  URL.createObjectURL(fileObj.file);
    return fileObj;

  } catch (error) {
    console.log(error);
    return null;
  }
}
