import axiosnoauth from '../../utils/mcaxiosnoauth';
import {
  POST_LOGIN_USER_URL,
  POST_WEB_LOGIN_USER_URL,
  POST_CHECK_EMAIL_EXIST_URL,
  POST_REGISTER_USER_URL,
  POST_ACTIVATE_ACCOUNT_URL,
  GET_RESEND_ACTIVATION_EMAIL_URL,
  GET_SEND_FORGET_PASSWORD_EMAIL_URL,
  POST_VERIFY_FORGET_PASSWORD_CODE_URL,
  PUT_UPDATE_FORGET_PASSWORD_URL,
  POST_AUTOCOMPLETE_SCHOOL_BY_COUNTRY_URL,
  POST_SEARCH_SCHOOL_BY_COUNTRY_URL,
  GET_QUERY_POST_GUEST_URL,
  GET_QUERY_SINGLE_SHORT_GUEST_URL,
  GET_QUERY_SHORT_GUEST_URL,
  GET_QUERY_SINGLE_POST_GUEST_URL,
  GET_QUERY_POST_COMMENT_GUEST_URL,
  GET_QUERY_SHORT_COMMENT_GUEST_URL,
} from '../../store/rest-api';

const login = async (email, password) => {
  try {
    const result = await axiosnoauth.post(POST_LOGIN_USER_URL, {
      email,
      password,
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const checkEmailExist = async (email) => {
  try {
    const result = await axiosnoauth.post(POST_CHECK_EMAIL_EXIST_URL, {
      email,
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const sendCode = async (
  firstName,
  lastName,
  email,
  password,
  avatarImg,
  recaptcha,
  schoolId,
  policy,
  publicIpAddress,
) => {
  try {
    const result = await axiosnoauth.post(POST_REGISTER_USER_URL, {
      firstName,
      lastName,
      email,
      password,
      avatarImg, //userDetails.photoFile.base64,
      recaptcha,
      schoolId,
      policy,
      publicIpAddress,
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const activateAccount = async (token, sixDigits) => {
  try {
    const result = await axiosnoauth.post(POST_ACTIVATE_ACCOUNT_URL, {
      token,
      sixDigits,
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const resendActivateCode = async (email) => {
  try {
    const result = await axiosnoauth.get(
      GET_RESEND_ACTIVATION_EMAIL_URL.concat('/').concat(email),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const sendForgetPassword = async (email) => {
  try {
    const result = await axiosnoauth.get(
      GET_SEND_FORGET_PASSWORD_EMAIL_URL.concat('/').concat(email),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const verifyChangeForgetPasswordCode = async (token, sixDigits) => {
  try {
    const result = await axiosnoauth.put(POST_VERIFY_FORGET_PASSWORD_CODE_URL, {
      token,
      sixDigits,
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const updateForgetPassword = async (
  newPassword,
  confirmNewPassword,
  changePasswordTokenKey,
) => {
  try {
    const result = await axiosnoauth.put(PUT_UPDATE_FORGET_PASSWORD_URL, {
      newPassword,
      confirmPassword: confirmNewPassword,
      token: changePasswordTokenKey,
    });
    return result.data;
  } catch (error) { /* empty */ }
};

const autocompleteSchoolByCountry = async (country, keywords) => {
  try {
    const result = await axiosnoauth.post(
      POST_AUTOCOMPLETE_SCHOOL_BY_COUNTRY_URL,
      {
        country,
        input: keywords,
      },
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const searchSchoolByCountry = async (country, keywords, skip) => {
  try {
    const result = await axiosnoauth.post(POST_SEARCH_SCHOOL_BY_COUNTRY_URL, {
      country,
      input: keywords,
      skip: skip.toString(),
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const findSchoolPostsAsGuest = async (skip) => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  try {
    const result = await axiosnoauth.get(
      GET_QUERY_POST_GUEST_URL.concat('/').concat(skip),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const findPostCommentsAsGuest = async (postId, skip) => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  try {
    const result = await axiosnoauth.get(
      GET_QUERY_POST_COMMENT_GUEST_URL.concat('/')
        .concat(postId)
        .concat('/')
        .concat(skip),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const findSchoolShortsAsGuest = async (skip) => {
  try {
    const result = await axiosnoauth.get(
      GET_QUERY_SHORT_GUEST_URL.concat('/').concat(skip),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const findShortCommentsAsGuest = async (shortId, skip) => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  try {
    const result = await axiosnoauth.get(
      GET_QUERY_SHORT_COMMENT_GUEST_URL.concat('/')
        .concat(shortId)
        .concat('/')
        .concat(skip),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const getSinglePost = async (postId) => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  try {
    const result = await axiosnoauth.get(
      GET_QUERY_SINGLE_POST_GUEST_URL.concat('/').concat(postId),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const getSingleShortVideo = async (shortVideoId) => {
  // return axios.get(GET_QUERY_USER_SCHOOLS_POSTS_URL.concat('/').concat(skip));
  try {
    const result = await axiosnoauth.get(
      GET_QUERY_SINGLE_SHORT_GUEST_URL.concat('/').concat(shortVideoId),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const loginWeb = async (email, password) => {
  try {
    const result = await axiosnoauth.post(POST_WEB_LOGIN_USER_URL, {
      email,
      password,
    });
    return result.data;
  } catch (error) { /* empty */ }
};
export const noauthService = {
  loginWeb,
  login,
  checkEmailExist,
  sendCode,
  activateAccount,
  resendActivateCode,
  sendForgetPassword,
  verifyChangeForgetPasswordCode,
  updateForgetPassword,
  autocompleteSchoolByCountry,
  searchSchoolByCountry,
  findSchoolPostsAsGuest,
  findPostCommentsAsGuest,
  findSchoolShortsAsGuest,
  findShortCommentsAsGuest,
  getSinglePost,
  getSingleShortVideo,
};
