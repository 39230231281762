import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  chatboxbar: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0.5),
    position: 'fixed',

    [theme.breakpoints.down('sm')]: {
      left: 5,
      bottom: 5
    },
    [theme.breakpoints.up('md')]: {
      bottom: 28,
      right: 80,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      left: 5,
      bottom: 5
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      left: 5,
      bottom: 5
    },
    zIndex: 500,
  },
  chatbox: {
    zIndex: 1000,

    [theme.breakpoints.between('xs', 'sm')]: {
      top: 0,
      left: 0,
    },
    [theme.breakpoints.down('xs')]: {
      left: 0,
      top: 0,
    },
  },
  chatboxcontent: {
    minWidth: theme.spacing(45),
    width: '360px',
    [theme.breakpoints.between('xs', 'sm')]: {
      bottom: 5,
    },
    [theme.breakpoints.down('xs')]: {
      bottom: 5,
    },
  },
  avatar: {
    fontSize: '1.0em',
    borderRadius: '50px'
  },
  chip: {
    height: '38px',
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5),
    border: '1px solid #009899',
    backgroundColor: '#94d4d4',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  inputMaxWidth: {
    maxWidth: '250px',
  }
}));

export default useStyles;

// [theme.breakpoints.down('sm')]: {
//   border: '1px solid red',
//   left: 5,
//   bottom: 5
// },
// [theme.breakpoints.up('md')]: {
//   border: '1px solid yellow',

// },
// [theme.breakpoints.between('sm', 'md')]: {
//   border: '1px solid blue',
//   left: 5,
//   bottom: 5
// },