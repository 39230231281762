import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import useStyles from './McClassDetailForm.style';
const McClassDetailForm = (props) => {
  const { clazz } = props;
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.description}>
        <Typography
          color="textSecondary"
          variant="subtitle2"
        >
          {clazz.description}
        </Typography>
      </div>
      <Divider style={{ margin: '8px 0px' }} />
      <div className={classes.details}>
        <Grid
          alignItems="center"
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography variant="h6">{clazz.startDate}</Typography>
            <Typography variant="body2">Start Date</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">{clazz.campus}</Typography>
            <Typography variant="body2">Campus</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">{clazz.studyForm}</Typography>
            <Typography variant="body2">Study Form</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">{clazz.length} {clazz.lengthType}</Typography>
            <Typography variant="body2">Length</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h6">{clazz.credentialType}</Typography>
            <Typography variant="body2">Credential</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">{clazz.schoolDept}</Typography>
            <Typography variant="body2">School or Department</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">{clazz.sectionName}</Typography>
            <Typography variant="body2">Class/Section Name</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">{clazz.endDate}</Typography>
            <Typography variant="body2">End Date</Typography>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
}
McClassDetailForm.propTypes = {
  clazz: PropTypes.object.isRequired,
};

export default McClassDetailForm