import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    
  },
  postHeader: {
    borderBottom: '1px solid #f1f1f1',
    padding: '16px 12px 0px 12px',
  },
  postHeaderClosed: {
    backgroundColor: colors.orange[200]
  },
  subheader: {
    display: 'flex',
    alignItems: 'center'
  },
  subtitle: {
    padding:'4px'
  },
  accessTimeIcon: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
    height: 14,
    width: 14,
    marginRight: 2
  },
  postTitle: {
    padding: theme.spacing(1, 0, 0, 0)
  },
  content: {
    paddingTop: 0
  },
  message: {
    marginBottom: theme.spacing(2)
  },
  mediaArea: {
    marginBottom: theme.spacing(2)
  },
  media: {
    height: 400,
    backgroundPosition: 'initial'
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  vdivider: {
    width: 1,
    height: 10, 
    backgroundColor: 'grey'
  },
  imageGallery: {
    display: 'block',
    minHeight: '1px',
    width: '100%',
    overflow: 'auto'
  },
  postContent: {
    // height: 140, 
    // overflow: 'auto',
    // padding: 5, 
    // borderTop: 'none',
    // borderLeft: 'none',
    // borderRight: 'none',
    // borderBottom: '1px solid #4db6ac',
    fontSize: '15px',
    padding: theme.spacing(1, 0),
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',

    // wordWrap: 'anywhere',
    overflowWrap: 'anywhere',
    whiteSpace: 'break-spaces',
    wordWrap: 'break-word',
    wordBreak:'break-all',

    '& .twemoji': {
      width: '22px',
      marginLeft: '1px',
      marginRight: '1px',
      verticalAlign: 'middle',
    }
  },
  showPrevious: {
    margin: '12px',
    display: 'block',
    fontSize: '14px',
  },
  categoryIcon: {
    color: '#607d8b',
    fontSize: '14px',
  },
  avatar: {
  },
  loadingMoreLink: {
    textAlign: 'center',
  },
  loadingPosts: {
    color:  colors.blue[500]
  },
  seeMoreLink: {
    margin: theme.spacing(0.5, 0),
    cursor: 'pointer',
    display: 'contents'
  },
  mediaUrl: {
    margin: theme.spacing(1, 0),
    fontSize: '14px',
  },
}));

export default useStyles;