import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1)
  },
  chip: {
    margin: theme.spacing(0.5)
  },

}));
export default useStyles;