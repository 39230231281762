import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.white,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  user: {
    flexShrink: 0,
    flexGrow: 1
  },
  activity: {
    display: 'flex',
    alignItems: 'center'
  },
}));
export default useStyles;