import React                                from 'react'
import PropTypes                            from 'prop-types';
import clsx                                 from 'clsx';

import Avatar                       from '@material-ui/core/Avatar';
import Tooltip                      from '@material-ui/core/Tooltip';

import useStyles                              from './McAvatarTxt.style';
import getInitials                            from 'utils/getInitials';
import { getRandomAvatarBgColor }             from 'utils/AvatarBgColorUtils';

export const McAvatarTxt = (props) => {
  const {avatarBgColor, className, userName, round, onClick, title} = props;
  const classes = useStyles();
  return(
    <Tooltip title={title ? title : userName}>

      <Avatar 
        alt="avatar"
        className={clsx(classes.root, 
          className,
          {
            [classes.round]: (round === true)
          },
        )}
        onClick={onClick}
        style={getRandomAvatarBgColor(avatarBgColor)}
      >
        {getInitials(userName)}
      </Avatar>
    </Tooltip> 
  )
}
McAvatarTxt.propTypes = {
  avatarBgColor: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  round: PropTypes.bool,
  title: PropTypes.string,
  userName: PropTypes.string.isRequired,
};
  
export default McAvatarTxt;
