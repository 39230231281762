import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
const useStyles = makeStyles(() => ({
  notificationsButton: {
  },
  notificationsBadge: {
    backgroundColor: colors.deepOrange[600]
  },
}));

export default useStyles;