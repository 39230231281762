import React, 
{ Fragment, 
  Suspense  }               from 'react';
// import { useLocation }      from 'react-router-dom'
import { renderRoutes }     from 'react-router-config';
import PropTypes            from 'prop-types';

import { LinearProgress }       from '@material-ui/core';

import useStyles                    from './Static.style';
import { 
  McSnackbar, 
  McTopBar2}                      from 'components';
// import AutoLogin                    from 'views/AutoLogin';
const Static = props => {
  const { route } = props;
  const classes = useStyles();

  return (
    <Fragment>
      {/* <AutoLogin redirect={useLocation().pathname}/> */}
      <McTopBar2
        className={classes.topBar}
        onOpenNavBarMobile={null}
      />
      <div className={classes.container}>
        <main >
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </main>
      </div>
      {/* <Bottombar /> */}
      <McSnackbar /> 

    </Fragment>
  );
};

Static.propTypes = {
  location: PropTypes.object,
  route: PropTypes.object
};

export default Static;
