import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    width: 32,
    height: 32,
    margin: '0px 0px',
    borderRadius: '2px 8px 2px 8px',
    '&:hover': {
      cursor: 'pointer'
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  img: {
    borderRadius: theme.spacing(2),
  }
}));

export default useStyles;