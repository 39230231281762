/* auth */
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const UPDATE_AVATAR_SUCCESS = 'UPDATE_AVATAR_SUCCESS';
export const UPDATE_AVATAR_FAILED = 'UPDATE_AVATAR_FAILED';
// export const NEW_POSTS_FROM_MY_SCHOOLS_NOTIFICATION =
//   'NEW_POSTS_FROM_MY_SCHOOLS_NOTIFICATION';
export const RENEW_USER_SUCCESS = 'RENEW_USER_SUCCESS';
export const CLEAN_POST = 'CLEAN_POST';

// export const STORE_RTM_CLIENT                   = 'STORE_RTM_CLIENT';

/* chat peer */
export const ADD_NEW_CONVERSATION = 'ADD_NEW_CONVERSATION';
export const ADD_FRIEND_TO_CHATBAR = 'ADD_FRIEND_TO_CHATBAR';
export const REMOVE_FRIEND_FROM_CHATBAR = 'REMOVE_FRIEND_FROM_CHATBAR';
export const REMOVE_MSG_FROM_PEER_CONVO = 'REMOVE_MSG_FROM_PEER_CONVO';
export const POPULATE_USER_FRIENDS_TO_CHATBAR =
  'POPULATE_USER_FRIENDS_TO_CHATBAR';
export const UPDATE_FRIENDS_STATUS = 'UPDATE_FRIENDS_STATUS';
export const ADD_MSG_TO_PEER_CONVO = 'ADD_MSG_TO_PEER_CONVO';
export const UPDATE_MSG_IMG_URL = 'UPDATE_MSG_IMG_URL';
export const UPDATE_FAILED_MSG_IMG_URL = 'UPDATE_FAILED_MSG_IMG_URL';
export const RESET_UNREAD_TO_ZERO = 'RESET_UNREAD_TO_ZERO';
export const UPDATE_MSG_SENT_STATUS = 'UPDATE_MSG_SENT_STATUS';
export const REMOVE_CONVO_INFO = 'REMOVE_CONVO_INFO';

/* post */
export const FETCH_POST_CATEGORIES_SUCCESS = 'FETCH_POST_CATEGORIES_SUCCESS';
export const SET_MAIN_FEED_POSTS = 'SET_MAIN_FEED_POSTS';
export const RESET_MAIN_FEED_POSTS = 'RESET_MAIN_FEED_POSTS';
export const SET_SINGLE_FEED_POST_STATUS = 'SET_SINGLE_FEED_POST_STATUS';
export const DELETE_SINGLE_FEED_POST = 'DELETE_SINGLE_FEED_POST';
export const DELETE_FEED_POSTS_BY_USERID = 'DELETE_FEED_POSTS_BY_USERID';
export const UPDATE_SINGLE_FEED_POST = 'UPDATE_SINGLE_FEED_POST';
export const SET_POST_LIKES = 'SET_POST_LIKES';
export const SET_POST_LATEST_LIKES_COUNT = 'SET_POST_LATEST_LIKES_COUNT';
/** short */
export const SET_MAIN_FEED_SHORTS = 'SET_MAIN_FEED_SHORTS';
export const RESET_MAIN_FEED_SHORTS = 'RESET_MAIN_FEED_SHORTS';
export const UPDATE_SINGLE_SHORT_VIDEO = 'UPDATE_SINGLE_SHORT_VIDEO';
export const SET_SHORT_LIKES = 'SET_SHORT_LIKES';
export const SET_SHORT_VIDEO_LATEST_LIKES_COUNT =
  'SET_SHORT_VIDEO_LATEST_LIKES_COUNT';
/* school */
export const FETCH_SCHOOL_INFO_SUCCESS = 'FETCH_SCHOOL_INFO_SUCCESS';
export const UNSUBSCRIBE_SCHOOL_SUCCESS = 'UNSUBSCRIBE_SCHOOL_SUCCESS';
export const SUBSCRIBE_SCHOOL_SUCCESS = 'SUBSCRIBE_SCHOOL_SUCCESS';
export const CHANGE_CURRENT_SCHOOL_SUCCESS = 'CHANGE_CURRENT_SCHOOL_SUCCESS';
export const REGISTER_CURRENT_SCHOOL_SUCCESS =
  'REGISTER_CURRENT_SCHOOL_SUCCESS';

export const AGREE_COOKIE = 'AGREE_COOKIE';

/* chat room */
export const POPULATE_MYCLAZZES_TO_CHATBAR = 'POPULATE_MYCLAZZES_TO_CHATBAR';
export const ADD_MYCLAZZ_TO_CHATBAR = 'ADD_MYCLAZZ_TO_CHATBAR';
export const REMOVE_MYCLAZZ_FROM_CHATBAR = 'REMOVE_MYCLAZZ_FROM_CHATBAR';
export const ADD_NEW_CLASS_CONVERSATION = 'ADD_NEW_CLASS_CONVERSATION';
export const POPULATE_CLASS_CLASSMATES = 'POPULATE_CLASS_CLASSMATES';
export const CLOSE_CLASS_CONVERSATION = 'CLOSE_CLASS_CONVERSATION';
export const UPDATE_CHATROOM_ONLINE_MEMBERS = 'UPDATE_CHATROOM_ONLINE_MEMBERS';
export const CHATROOM_MEMBER_JOINED = 'CHATROOM_MEMBER_JOINED';
export const CHATROOM_MEMBER_LEFT = 'CHATROOM_MEMBER_LEFT';
export const ADD_MSG_TO_CLASS_CONVO = 'ADD_MSG_TO_CLASS_CONVO';
export const RESET_CLASS_UNREAD_MSGS_TO_ZERO =
  'RESET_CLASS_UNREAD_MSGS_TO_ZERO';
export const OPEN_CLASS_BAR = 'OPEN_CLASS_BAR';
export const CLOSE_CLASS_BAR = 'CLOSE_CLASS_BAR';
export const UPDATE_CLASS_MSG_IMG_URL = 'UPDATE_CLASS_MSG_IMG_URL';
export const UPDATE_FAILED_CLASS_MSG_IMG_URL =
  'UPDATE_FAILED_CLASS_MSG_IMG_URL';
export const ADD_UNREAD_MSG_TO_CLASS_CONVO = 'ADD_UNREAD_MSG_TO_CLASS_CONVO';
export const CLEAN_CLASS_CONVO_MSGS = 'CLEAN_CLASS_CONVO_MSGS';
export const REMOVE_MSG_FROM_CLASS_CONVO = 'REMOVE_MSG_FROM_CLASS_CONVO';
export const REMOVE_CLASS_CONVERSATION = 'REMOVE_CLASS_CONVERSATION';
export const CLEAN_CHAT_CONVO_MSGS = 'CLEAN_CHAT_CONVO_MSGS';
export const CLEAN_SCHOOL_INFOS = 'CLEAN_SCHOOL_INFOS';
export const CLEAN_CHAT = 'CLEAN_CHAT';
// global message snacker
export const DISPLAY_SIMPLE_GLOBAL_SNACKER = 'DISPLAY_SIMPLE_GLOBAL_SNACKER';
export const DISPLAY_ADVANCED_GLOBAL_SNACKER =
  'DISPLAY_ADVANCED_GLOBAL_SNACKER';
export const CLOSE_SNACKER = 'CLOSE_SNACKER';

export const SET_CHANNELS = 'SET_CHANNELS';
export const SET_CHANNELS_SUBCRIBED = 'SET_CHANNELS_SUBCRIBED';
// export const SET_CHAT_CHANNELS_BADGE_NUMBER = 'SET_CHAT_CHANNELS_BADGE_NUMBER';
export const SET_FEED_TAB_BADGE_NUMBER = 'SET_FEED_TAB_BADGE_NUMBER';
export const SET_SHORT_TAB_BADGE_NUMBER = 'SET_SHORT_TAB_BADGE_NUMBER';
export const SET_NOTIFICATION_TAB_BADGE_NUMBER =
  'SET_NOTIFICATION_TAB_BADGE_NUMBER';
// export const SET_RESTRICT_USERS = 'SET_RESTRICT_USERS';
export const POPULATE_FS_USER_INFO = 'POPULATE_FS_USER_INFO';

//Guest
export const SET_IS_DISPLAY_AUTH_MODAL = 'SET_IS_DISPLAY_AUTH_MODAL';

/* auth */
// export const LOGIN_USER_SUCCESS                 = 'LOGIN_USER_SUCCESS';
// export const SIGNOUT_USER_SUCCESS               = 'SIGNOUT_USER_SUCCESS';
// export const UPDATE_AVATAR_SUCCESS              = 'UPDATE_AVATAR_SUCCESS';
// export const UPDATE_AVATAR_FAILED               = 'UPDATE_AVATAR_FAILED';
export const FETCH_MY_SCHOOLS_POSTS             = 'FETCH_MY_SCHOOLS_POSTS';


// export const STORE_RTM_CLIENT                   = 'STORE_RTM_CLIENT';

/* chat peer */
// export const ADD_NEW_CONVERSATION               = 'ADD_NEW_CONVERSATION';
// export const ADD_FRIEND_TO_CHATBAR              = 'ADD_FRIEND_TO_CHATBAR';
// export const REMOVE_FRIEND_FROM_CHATBAR         = 'REMOVE_FRIEND_FROM_CHATBAR';
// export const REMOVE_MSG_FROM_PEER_CONVO         = 'REMOVE_MSG_FROM_PEER_CONVO';
// export const POPULATE_USER_FRIENDS_TO_CHATBAR   = 'POPULATE_USER_FRIENDS_TO_CHATBAR';
// export const UPDATE_FRIENDS_STATUS              = 'UPDATE_FRIENDS_STATUS';
// export const ADD_MSG_TO_PEER_CONVO              = 'ADD_MSG_TO_PEER_CONVO';
// export const UPDATE_MSG_IMG_URL                 = 'UPDATE_MSG_IMG_URL';
// export const UPDATE_FAILED_MSG_IMG_URL          = 'UPDATE_FAILED_MSG_IMG_URL';
// export const RESET_UNREAD_TO_ZERO               = 'RESET_UNREAD_TO_ZERO';
// export const UPDATE_MSG_SENT_STATUS             = 'UPDATE_MSG_SENT_STATUS';
// export const REMOVE_CONVO_INFO                  = 'REMOVE_CONVO_INFO';

/* post */
// export const FETCH_POST_CATEGORIES_SUCCESS      = 'FETCH_POST_CATEGORIES_SUCCESS';

/* school */
// export const FETCH_SCHOOL_INFO_SUCCESS          = 'FETCH_SCHOOL_INFO_SUCCESS';
// export const UNSUBSCRIBE_SCHOOL_SUCCESS         = 'UNSUBSCRIBE_SCHOOL_SUCCESS';
// export const SUBSCRIBE_SCHOOL_SUCCESS           = 'SUBSCRIBE_SCHOOL_SUCCESS';
// export const CHANGE_CURRENT_SCHOOL_SUCCESS      = 'CHANGE_CURRENT_SCHOOL_SUCCESS';
// export const REGISTER_CURRENT_SCHOOL_SUCCESS    = 'REGISTER_CURRENT_SCHOOL_SUCCESS';

// export const AGREE_COOKIE = 'AGREE_COOKIE';

/* chat room */
// export const POPULATE_MYCLAZZES_TO_CHATBAR      = 'POPULATE_MYCLAZZES_TO_CHATBAR';
// export const ADD_MYCLAZZ_TO_CHATBAR             = 'ADD_MYCLAZZ_TO_CHATBAR';
// export const REMOVE_MYCLAZZ_FROM_CHATBAR        = 'REMOVE_MYCLAZZ_FROM_CHATBAR';
// export const ADD_NEW_CLASS_CONVERSATION         = 'ADD_NEW_CLASS_CONVERSATION';
// export const POPULATE_CLASS_CLASSMATES          = 'POPULATE_CLASS_CLASSMATES';
// export const CLOSE_CLASS_CONVERSATION           = 'CLOSE_CLASS_CONVERSATION';
// export const UPDATE_CHATROOM_ONLINE_MEMBERS     = 'UPDATE_CHATROOM_ONLINE_MEMBERS';
// export const CHATROOM_MEMBER_JOINED             = 'CHATROOM_MEMBER_JOINED';
// export const CHATROOM_MEMBER_LEFT               = 'CHATROOM_MEMBER_LEFT';
// export const ADD_MSG_TO_CLASS_CONVO             = 'ADD_MSG_TO_CLASS_CONVO';
// export const RESET_CLASS_UNREAD_MSGS_TO_ZERO    = 'RESET_CLASS_UNREAD_MSGS_TO_ZERO';
// export const OPEN_CLASS_BAR                     = 'OPEN_CLASS_BAR';
// export const CLOSE_CLASS_BAR                    = 'CLOSE_CLASS_BAR';
// export const UPDATE_CLASS_MSG_IMG_URL           = 'UPDATE_CLASS_MSG_IMG_URL';
// export const UPDATE_FAILED_CLASS_MSG_IMG_URL    = 'UPDATE_FAILED_CLASS_MSG_IMG_URL';
// export const ADD_UNREAD_MSG_TO_CLASS_CONVO      = 'ADD_UNREAD_MSG_TO_CLASS_CONVO';
// export const CLEAN_CLASS_CONVO_MSGS             = 'CLEAN_CLASS_CONVO_MSGS';
// export const REMOVE_MSG_FROM_CLASS_CONVO        = 'REMOVE_MSG_FROM_CLASS_CONVO';
// export const REMOVE_CLASS_CONVERSATION          = 'REMOVE_CLASS_CONVERSATION';
// export const CLEAN_CHAT_CONVO_MSGS              = 'CLEAN_CHAT_CONVO_MSGS';
// export const CLEAN_SCHOOL_INFOS                 = 'CLEAN_SCHOOL_INFOS';

// global message snacker 
// export const DISPLAY_SIMPLE_GLOBAL_SNACKER      = 'DISPLAY_SIMPLE_GLOBAL_SNACKER';
// export const DISPLAY_ADVANCED_GLOBAL_SNACKER    = 'DISPLAY_ADVANCED_GLOBAL_SNACKER';
// export const CLOSE_SNACKER                      = 'CLOSE_SNACKER';


// export const INITIALIZE_CHATROOM_PROPS = 'INITIALIZE_CHATROOM_PROPS';
// export const UPDATE_CHATROOM_MEMBER_COUNT = 'UPDATE_CHATROOM_MEMBER_COUNT';


// export const REGISTER_USER_START = 'REGISTER_USER_START';
// export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
// export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';

// export const REGISTER_AUTOCOMPLETE_SCHOOL_START = 'REGISTER_AUTOCOMPLETE_SCHOOL_START';
// export const REGISTER_AUTOCOMPLETE_SCHOOL_FAILED = 'REGISTER_AUTOCOMPLETE_SCHOOL_FAILED';
// export const REGISTER_AUTOCOMPLETE_SCHOOL_SUCCESS = 'REGISTER_AUTOCOMPLETE_SCHOOL_SUCCESS';

// export const RESEND_ACTIVATION_EMAIL_START = 'RESEND_ACTIVATION_EMAIL_START';
// export const RESEND_ACTIVATION_EMAIL_FAILED = 'RESEND_ACTIVATION_EMAIL_FAILED';
// export const RESEND_ACTIVATION_EMAIL_SUCCESS = 'RESEND_ACTIVATION_EMAIL_SUCCESS';

// export const SEND_FORGET_PASSWORD_EMAIL_START = 'SEND_FORGET_PASSWORD_EMAIL_START';
// export const SEND_FORGET_PASSWORD_EMAIL_FAILED = 'SEND_FORGET_PASSWORD_EMAIL_FAILED';
// export const SEND_FORGET_PASSWORD_EMAIL_SUCCESS = 'SEND_FORGET_PASSWORD_EMAIL_SUCCESS';

// export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
// export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
// export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';

// export const LOGIN_USER_START = 'LOGIN_USER_START';
// export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';

// export const ADD_POST_START = 'ADD_POST_START';
// export const ADD_POST_FAILED = 'ADD_POST_FAILED';
// export const ADD_POST_SUCCESS = 'ADD_POST_SUCCESS';

// export const FETCH_POSTS_START = 'FETCH_POSTS_START';
// export const FETCH_POSTS_FAILED = 'FETCH_POSTS_FAILED';
// export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';

// export const FETCH_CHAT_CATEGORIES_SUCCESS = 'FETCH_CHAT_CATEGORIES_SUCCESS';
// export const ADD_MSG_HISTORY = 'ADD_MSG_HISTORY';

// export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
// export const LOGIN_AGORA_STATUS_START = 'LOGIN_AGORA_STATUS_START';
// export const SESSION_LOGIN = 'SESSION_LOGIN';
// export const SESSION_LOGOUT = 'SESSION_LOGOUT';