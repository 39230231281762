import * as actionTypes from '../actions/actionTypes';
import _ from 'lodash';
import { buildConvoInfo } from '../../utils/ChatUtils';

const initialState = {
  // micollege.io
  // peerIds: [],
  // friends: [],
  // groups: [],
  // chatCategories: [],
  // multiConvoInfo: [],
  // lastReceivedAt: null,
  // original
  channels: null,
  // threads: [], // [{channelId: 'xxx', messages: [{}]]
  areChannelsSubcribed: false,
};
const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CHANNELS_SUBCRIBED:
      return {
        ...state,
        areChannelsSubcribed: action.areChannelsSubcribed,
      };
    // set chat channels to redux
    case actionTypes.SET_CHANNELS:
      return {
        ...state,
        channels: [...action.channels],
      };
    case actionTypes.CLEAN_CHAT: {
      return {
        channels: null,
        areChannelsSubcribed: false,
      };
    }
    /**
    // delete one message
    case actionTypes.REMOVE_THREAD:
      return {
        ...state,
        channels: [...action.channels],
      };
    case 'LOG_OUT':
      return initialState;

    case actionTypes.ADD_NEW_CONVERSATION: {
      // If conversation exists, just change currentConvo 
      const matchedConvoInfo = _.find(state.multiConvoInfo, (convoInfo) => {
        return action.convoInfo.peerId === convoInfo.peerId;
      });
      if (!matchedConvoInfo) {
        return {
          ...state,
          multiConvoInfo: [action.convoInfo, ...state.multiConvoInfo],
        };
      } else {
        // If closed before, just reopen it
        const multiConvoInfoUpdated = state.multiConvoInfo.map((convoInfo) => {
          if (convoInfo.peerId === action.convoInfo.peerId) {
            convoInfo.closed = false;
          }
          return convoInfo;
        });
        return {
          ...state,
          multiConvoInfo: [...multiConvoInfoUpdated],
        };
      }
    }

    case actionTypes.ADD_FRIEND_TO_CHATBAR: {
      return {
        ...state,
        friends: [...state.friends, action.friend],
        peerIds: [...state.peerIds, action.friend.peerId],
      };
    }
    case actionTypes.REMOVE_FRIEND_FROM_CHATBAR: {
      const filteredfriends = _.filter(state.friends, function (friend) {
        return friend.peerId !== action.peerId;
      });
      const filteredPeerIds = _.filter(state.peerIds, function (peerId) {
        return peerId !== action.peerId;
      });
      return {
        ...state,
        friends: [...filteredfriends],
        peerIds: [...filteredPeerIds],
      };
    }
    case actionTypes.REMOVE_MSG_FROM_PEER_CONVO: {
      const multiConvoInfoUpdated = state.multiConvoInfo.map((convoInfo) => {
        if (convoInfo.peerId === action.peerId) {
          const filteredMsgs = _.filter(convoInfo.messages, function (message) {
            return message.msgId !== action.msgId;
          });
          convoInfo.messages = [...filteredMsgs];
        }
        return convoInfo;
      });
      return {
        ...state,
        multiConvoInfo: [...multiConvoInfoUpdated],
      };
    }
    case actionTypes.POPULATE_USER_FRIENDS_TO_CHATBAR: {
      return {
        ...state,
        friends: [...action.friends],
        peerIds: [...action.peerIds],
      };
    }
    case actionTypes.UPDATE_FRIENDS_STATUS: {
      const friendsUpdated = state.friends.map((friend) => {
        _.forEach(action.friendsStatus, function (value, key) {
          if (friend.peerId === key) {
            friend.active = value;
          }
        });
        return friend;
      });
      return {
        ...state,
        friends: [...friendsUpdated],
      };
    }
    case actionTypes.ADD_MSG_TO_PEER_CONVO: {
      // If conversation exists,
      const matchedConvoInfo = _.find(state.multiConvoInfo, (convoInfo) => {
        return action.peerId === convoInfo.peerId;
      });
      // It's for when receive first message from peer,
      if (!matchedConvoInfo) {
        const peer = {
          peerId: action.peerId,
          peerName: action.msg.createUserName,
          peerAvatar: action.msg.createUserAvatar,
        };
        const matchedConvoInfo = buildConvoInfo(peer);
        matchedConvoInfo.messages.push(action.msg);
        matchedConvoInfo.unread = 1;
        return {
          ...state,
          multiConvoInfo: [matchedConvoInfo, ...state.multiConvoInfo],
          lastReceivedAt: new Date(),
        };
        // It's from conversation is built,
        // It's for receiver and sender
      } else {
        const multiConvoInfoUpdated = state.multiConvoInfo.map((convoInfo) => {
          if (convoInfo.peerId === action.peerId) {
            // prevent listening twice and receive dupulicated messages
            const matchedMsg = _.find(convoInfo.messages, (msg) => {
              return msg.msgId === action.msg.msgId;
            });
            if (_.isEmpty(matchedMsg)) {
              convoInfo.messages.push(action.msg);
              convoInfo.closed = false;

              if (action.msg.createUserId === convoInfo.peerId) {
                convoInfo.unread += 1; // It's for receiver, message created by sender
              } else {
                convoInfo.unread = 0; // it's for sender, message I created
              }
            }
          }
          return convoInfo;
        });
        return {
          ...state,
          multiConvoInfo: [...multiConvoInfoUpdated],
          lastReceivedAt: new Date(),
        };
      }
    }
    case actionTypes.UPDATE_MSG_IMG_URL: {
      const multiConvoInfoUpdated = state.multiConvoInfo.map((convoInfo) => {
        if (convoInfo.peerId === action.peerId) {
          convoInfo.messages.map((msg) => {
            const urlObj = action.urls.find((url) => url.uuid === msg.msgId);
            if (urlObj) {
              msg.content = urlObj.imgUrl;
            }
            return msg;
          });
        }
        return convoInfo;
      });
      return {
        ...state,
        multiConvoInfo: [...multiConvoInfoUpdated],
        lastReceivedAt: new Date(),
      };
    }

    case actionTypes.RESET_UNREAD_TO_ZERO: {
      const multiConvoInfoUpdated = state.multiConvoInfo.map((convoInfo) => {
        if (convoInfo.peerId === action.peerId) {
          convoInfo.unread = 0;
        }
        return convoInfo;
      });
      return {
        ...state,
        multiConvoInfo: [...multiConvoInfoUpdated],
      };
    }
    case actionTypes.UPDATE_MSG_SENT_STATUS: {
      const multiConvoInfoUpdated = state.multiConvoInfo.map((convoInfo) => {
        if (convoInfo.peerId === action.peerId) {
          convoInfo.messages.map((msg) => {
            if (msg.msgId === action.uuid) {
              msg.sentStatus = action.sentStatus;
            }
            return msg;
          });
        }
        return convoInfo;
      });
      return {
        ...state,
        multiConvoInfo: [...multiConvoInfoUpdated],
      };
    }

    case actionTypes.REMOVE_CONVO_INFO: {
      const multiConvoInfoUpdated = state.multiConvoInfo.map((convoInfo) => {
        if (convoInfo.peerId === action.peerId) {
          convoInfo.closed = true;
        }
        return convoInfo;
      });

      return {
        ...state,
        multiConvoInfo: [...multiConvoInfoUpdated],
      };
    }

    case actionTypes.UPDATE_FAILED_MSG_IMG_URL: {
      const multiConvoInfoUpdated = state.multiConvoInfo.map((convoInfo) => {
        if (convoInfo.peerId === action.peerId) {
          convoInfo.messages.map((msg) => {
            if (msg.msgId === action.uuid) {
              msg.content = action.imgUrl;
            }
            return msg;
          });
        }
        return convoInfo;
      });
      return {
        ...state,
        multiConvoInfo: [...multiConvoInfoUpdated],
      };
    }
    case actionTypes.CLEAN_CHAT_CONVO_MSGS: {
      return {
        peerIds: [],
        friends: [],
        groups: [],
        chatCategories: [],
        multiConvoInfo: [],
        lastReceivedAt: null,
      };
    }*/
    default: {
      return state;
    }
  }
};

export default chatReducer;

// const onRemoteMessageReceived = (type, channelId, message) => {
//   switch (type) {
//     case 'added':
//       dispatch(addThread(channelId, message));
//       break;
//     case 'modified':
//       break;
//     case 'removed':
//       break;
//   }
// };

// add one message
//  case actionTypes.ADD_THREAD: {
//   /* If conversation exists,  */
//   const matchedThread = _.find(state.threads, (thread) => {
//     return action.channelId === thread.channelId;
//   });
//   // It's for when receive first message from peer,
//   if (!matchedThread) {
//     const threadTemp = {
//       channelId: action.channelId,
//       messages: [],
//     };
//     threadTemp.messages.push(action.thread);
//     return {
//       ...state,
//       threads: [threadTemp, ...state.threads],
//     };
//   } else {
//     const matchedThreadUpdated = state.threads.map((thread) => {
//       if (action.channelId === thread.channelId) {
//         thread.messages.push(action.thread);
//       }
//       return thread;
//     });
//     return {
//       ...state,
//       threads: [...matchedThreadUpdated],
//     };
//   }
// }
