import React            from 'react'
import PropTypes        from 'prop-types';
import clsx             from 'clsx';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
}));
const McWaitingIcon = (props) => {
  const { className, loading, ...rest } = props;
  const classes = useStyles();
  let result = '';
  if(loading){
    result =  
      <div
        className={clsx(classes.root, className)}
      >
        <img 
          {...rest}
          alt=""
          src="/images/gif/mcloader.gif"
        />
      </div>
  }
  return result;
}
McWaitingIcon.propTypes = {
  className: PropTypes.string,
};
export default McWaitingIcon
