import React, {useState, Fragment, useEffect}             from 'react';
import { useSelector, useDispatch }                       from 'react-redux';
import useRouter                                          from 'utils/useRouter';
import PropTypes                                          from 'prop-types';
import _                                                  from 'lodash';
import urlRegex                                           from 'url-regex'

import IconButton                                           from '@material-ui/core/IconButton';
import BorderColorOutlinedIcon                              from '@material-ui/icons/BorderColorOutlined';
import NativeSelect                                         from '@material-ui/core/NativeSelect';

import axios                                        from 'utils/mcaxios';
import { POST_CREATE_POST_URL }                     from 'store/rest-api';
import { ROUTER_TO_SCHOOLS_PATH,
  ROUTER_TO_HOME_PATH }                             from 'store/routerPath';
import { SUCCESS_ADD_POST_CODE }                    from 'store/statusCode';
import { MC_MAX_LENGTH_POST_CONTENT,
  MC_SNACKER_TYPE_WARNING,
  MC_SNACKER_TYPE_SUCCESS }                         from 'mcconfig'
import { DisplaySimpleSnacker,
  SetFetchMySchoolsPostsFlag }                      from 'store/actions';
import { McDialogModal, Alert }                     from 'components';
import AddMcPostForm                                from './components/AddMcPostForm';

import useStyles                                    from './AddMcPostModal.style';
const AddMcPostModal = props => {
  
  const { open, onClose } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const postCategories = useSelector(state => state.post.postCategories);
  const schoolsInfo = useSelector(state => state.schools.schoolsInfo);
  const [inputCount, setInputCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [compressedFilesPreview, setCompressedFilesPreview] = useState([]);

  const [schoolCampuses, setSchoolCampuses] = useState()
  useEffect(() => {
    let mounted = true;
    if(mounted){
      setSchoolCampuses([
        ...schoolsInfo[0].schoolCampuses 
      ]
      )
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          school: schoolsInfo[0].id
        },
      }));
    }
    return () => {
      mounted = false;
    }
  }, [])// eslint-disable-line
  /**[ Validate post form ]**/
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  /**[ Handle Submit post ]**/
  const handleSubmit = event => {
    event.preventDefault();
    // title cannot be empty
    if(_.isEmpty(formState.values.title)){
      document.getElementById('postTitle').focus();
      return;
    }
    //
    let mediaUrl = document.getElementById('mediaUrl');
    let mediaUrlValue = '';
    if(mediaUrl){
      // console.log('media url', mediaUrl);
      mediaUrlValue = document.getElementById('mediaUrl').value;
      if(!_.isEmpty(mediaUrlValue)){
        if(!urlRegex({exact: true, strict: false}).test(mediaUrlValue)){
          document.getElementById('mediaUrl').focus();
          return;
        }
      }
    }
    let descContent = document.getElementById('descContent').innerHTML;
    var textWithNBSpaceReplaced = descContent.replace(/&nbsp;/g, ' ');
    /* Description cannot be empty */
    if(_.trim(textWithNBSpaceReplaced) !== '' && inputCount <= MC_MAX_LENGTH_POST_CONTENT){
      const data = new FormData()
      for (var x = 0; x < compressedFilesPreview.length; x++) {
        data.append('file', compressedFilesPreview[x].file, compressedFilesPreview[x].file.name)
      }
      data.append('title', formState.values.title);
      data.append('schoolId', formState.values.school);
      data.append('categoryId', formState.values.category);
      data.append('campusId', formState.values.campus);
      data.append('category', getSelectedCategoryText(formState.values.category));
      data.append('campus', getSelectedCampusText(formState.values.campus));
      data.append('school', getSelectedSchoolText(formState.values.school));
      data.append('descContent', _.trim(textWithNBSpaceReplaced))
      data.append('mediaUrl', mediaUrlValue);
      // dispatch(AddPost(data));
      savePost(data);
    }else{
      document.getElementById('descContent').focus();
    }
  };
  const savePost = (postForm) => {
    setLoading(true)

    axios.post(POST_CREATE_POST_URL, postForm)
      .then( response => {
        const {status, message} = response.data;
        if(status === SUCCESS_ADD_POST_CODE ){
          setLoading(false);
          dispatch(DisplaySimpleSnacker(message, MC_SNACKER_TYPE_SUCCESS));
          router.history.replace(ROUTER_TO_HOME_PATH);
          dispatch(SetFetchMySchoolsPostsFlag(true));
          onClose();
        }else{
          setLoading(false);
          dispatch(DisplaySimpleSnacker(message, MC_SNACKER_TYPE_WARNING));
        }
      })
      .catch( error => {
        setLoading(false);
        console.log('error ', error);
      });
  }
  const getSelectedCategoryText = (categoryId) => {
    if(_.isEmpty(categoryId) || categoryId === 'undefined'  || _.isUndefined(categoryId)){
      return '';
    }
    const category = _.find(postCategories, (category) => {
      return category.id === categoryId;
    });
    return category.name;
  }
  const getSelectedCampusText = (campusId) => {
    // schoolCampuses
    if(_.isEmpty(campusId) || campusId === 'undefined' || _.isUndefined(campusId)){
      return '';
    }
    const campus = _.find(schoolCampuses, (campus) => {
      return campus.id === campusId;
    });
    return campus.name;
  }
  const getSelectedSchoolText = (schoolId) => {
    // school
    if(_.isEmpty(schoolId) || schoolId === 'undefined' || _.isUndefined(schoolId)){
      return '';
    }
    const school = _.find(schoolsInfo, (school) => {
      return school.id === schoolId;
    });
    return school.name;
  }
  const handleCloseModal = () => {
    onClose()
  }

  const handleChangeSchool = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
    // console.log(formState.values.school)
    // because it cannot populate synchronizely, so just populate manually
    let e = document.getElementById('schoolSelect');
    let schoolId = e.options[e.selectedIndex].value;
    // let schoolName = e.options[e.selectedIndex].text;
    // console.log(schoolId, schoolName)

    const schoolInfo = _.find(schoolsInfo, (school) => {
      return school.id === schoolId;
    });
    setSchoolCampuses([
      ...schoolInfo.schoolCampuses
    ])
  };
  const router = useRouter();
  const handleAddMoreSchools = () => {
    onClose();
    router.history.push({ 
      pathname:ROUTER_TO_SCHOOLS_PATH, 
      state:{ resend : true } 
    })
  };
  return (
    <Fragment>

      <McDialogModal
        handleSubmit={handleSubmit}
        loading={loading}
        onClose={handleCloseModal}
        open={open}
        title="Create Post"
      > 
        <div className={classes.schoolDiv}>
          <div/>
          <NativeSelect
            id="schoolSelect"
            margin="dense"
            name="school"
            onChange={handleChangeSchool}  
            value={formState.values.school || ''}
          >
               
            {schoolsInfo && schoolsInfo.map(schoolInfo => (
              <option 
                key={schoolInfo.id}
                value={schoolInfo.id}
              >
                {schoolInfo.name}
              </option>

            ))}
          </NativeSelect>
          <IconButton 
            className={classes.editBtn} 
            edge="end"
            onClick={handleAddMoreSchools}
            title="Add another school"
          >
            <BorderColorOutlinedIcon />
          </IconButton>
        </div>
        <AddMcPostForm 
          compressedFilesPreview = {compressedFilesPreview}
          formState = {formState}
          inputCount={inputCount}
          postCategories={postCategories}
          schoolCampuses={schoolCampuses}
          setCompressedFilesPreview = {setCompressedFilesPreview}
          setFormState = {setFormState}
          setInputCount={setInputCount}
        />
        <Alert 
          message="Your post is visible to those users who registered or subscribed the school you selected."
          variant="notice"
        />
      </McDialogModal>
    </Fragment>
  );
};

AddMcPostModal.propTypes = {
  fetchPostsInfo: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default AddMcPostModal;
