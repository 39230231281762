import React                                        from 'react';
import { useSelector, useDispatch }                 from 'react-redux';
import PropTypes                                    from 'prop-types';

import Snackbar                           from '@material-ui/core/Snackbar';

import MySnackbarContentWrapper                   from './McSnackbarContentWrapper';
import { CloseSnacker }                           from 'store/actions';

const McSnackbar = (props) => {
  const { className, ...other } = props;
  const dispatch = useDispatch();
  const {open, 
    message, 
    variant,
    buttonText, 
    handleClick }                            = useSelector(state => state.snacker);
  const handleClickBtn = () => {
    handleClick();
    dispatch(CloseSnacker());
  }
  const handleClose = () => {
    dispatch(CloseSnacker());
  }
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={10000}
      className={className}
      onClose={handleClose}
      open={open}
      {...other}
    >
      <MySnackbarContentWrapper
        buttonText={buttonText}
        handleClick={handleClickBtn}
        message={message}
        onClose={handleClose}
        variant={variant}
      />
    </Snackbar>
  );
}
McSnackbar.propTypes = {
  // buttonText: PropTypes.string,
  className: PropTypes.string,
  // displayActionButton: PropTypes.bool,
  // durationLength: PropTypes.number,
  // handleClick: PropTypes.func,
  // message: PropTypes.string,
  // open: PropTypes.bool,
  // variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};
export default McSnackbar
