import React, { 
  Fragment, 
  useRef, 
  useState, 
  memo }                from 'react';
import { useSelector }  from 'react-redux';
import PropTypes        from 'prop-types';

import ListItemIcon         from '@material-ui/core/ListItemIcon';
import ListItemText         from '@material-ui/core/ListItemText';
import Tooltip              from '@material-ui/core/Tooltip';
import IconButton           from '@material-ui/core/IconButton';
import Menu                 from '@material-ui/core/Menu';
import MenuItem             from '@material-ui/core/MenuItem';
import MoreIcon             from '@material-ui/icons/MoreVert';
import CreateOutlinedIcon   from '@material-ui/icons/CreateOutlined';
import FlagIcon             from '@material-ui/icons/Flag';
import DeleteOutlineIcon    from '@material-ui/icons/DeleteOutline';

import useStyles            from './McCommentMoreOptions.style';

const McCommentMoreOptions = props => {
  const { onOpenDeleteConfirm, onOpenEditModel, comment } = props;

  const classes = useStyles();
  const moreRef = useRef(null);

  const [openMenu, setOpenMenu] = useState(false);
  const { agoraUid } = useSelector(state => state.auth.loginUserInfo);
  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };
  const handleOpenEditModel = () => {
    setOpenMenu(false);
    onOpenEditModel();
  };
  const handleOpenDeleteConfirm = () => {
    setOpenMenu(false);
    onOpenDeleteConfirm();
  };
  return (
    <Fragment>
      <Tooltip title="More options">
        <IconButton
          onClick={handleMenuOpen}
          ref={moreRef}
          size="small"
        >
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: -45,
          horizontal: 'right'
        }}
      >
        {
          comment.createUserId !== agoraUid
          &&
          <MenuItem className={classes.postMenu}>
            <ListItemIcon>
              <FlagIcon />
            </ListItemIcon>
            <ListItemText primary="Report" />
          </MenuItem>
        }
        
        {
          comment.createUserId === agoraUid
          &&
          <MenuItem 
            className={classes.postMenu}
            onClick={handleOpenEditModel}
          >
            <ListItemIcon>
              <CreateOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Edit" />
          </MenuItem>
        }
        {
          comment.createUserId === agoraUid
          &&
          <MenuItem 
            className={classes.postMenu} 
            onClick={handleOpenDeleteConfirm}
          >
            <ListItemIcon>
              <DeleteOutlineIcon color="error"/>
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </MenuItem>
        }
      </Menu>
    </Fragment>
  );
};

McCommentMoreOptions.propTypes = {
  comment: PropTypes.object,
  onOpenDeleteConfirm: PropTypes.func,
  onOpenEditModel: PropTypes.func,
};

export default memo(McCommentMoreOptions);
