import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core';

import {McIcon, McLoader, McButton} from 'components';
import useStyles from './McDialogModal.style';
/**[ Display dialog from slide down ]**/
// eslint-disable-next-line react/display-name
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide 
    direction="down" 
    ref={ref} 
    {...props}
    // eslint-disable-next-line react/jsx-closing-bracket-location
  />;
});
const McDialogModal = (props) => {
  const { className, title, loading, onClose, handleSubmit, open, children } = props;
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm')); 
  return (
    <Dialog
      TransitionComponent={Transition}
      classes={{
        paper: classes.paper,
        paperScrollBody: classes.paperScrollBody
      }}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth={mobileDevice ? 'xs' : 'lg'}
      onClose={onClose}
      open={open}
      scroll="paper"
    >   
      <MuiDialogTitle 
        className={classes.title}
        disableTypography 
      >
        <Typography variant="body2">
          {title}
        </Typography>
        {onClose ? (
          <IconButton 
            aria-label="close" 
            className={classes.closeButton} 
            disabled={loading}
            onClick={onClose}
            
          >
            <CloseIcon fontSize="medium"/>
          </IconButton>
        ) : null}
      </MuiDialogTitle>
      <div className={clsx(classes.root, className)}>
        {children}
      </div>
      <div className={classes.actions}>
        {
          handleSubmit &&
          <McButton
            className={classes.submitButton}
            disabled={loading}
            onClick={handleSubmit}
          >
            <McLoader loading={loading} />
            <McIcon 
              display={!loading} 
              name="telegram"
            />
                  Submit
          </McButton>
        }
      </div>
    </Dialog>
  )
}
McDialogModal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  handleSubmit: PropTypes.func, 
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};
export default McDialogModal
