import { withStyles } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';
import {TextField } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';
const McTextField = styled(TextField)({
  '& label': {
    color: '#bdbdbd'
  },
  "& .MuiInputLabel-root": {
    '&.Mui-focused': {
      color: '#007f99'
    },
    '&.Mui-error': {
      color: 'red'
    }
  },
  "& .MuiInputBase-input": { padding: '14px 14px' },

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8cc7c2',
    },
    '&:hover fieldset': {
      borderColor: '#27999a',
    },
    '& label.Mui-focused': {
      color: '#007f99',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#007f99'
    }
  },

  // "&.Mui-focused": {
  //   borderColor: '#007f99',
  //   borderWidth: "2px",
  //   '--Input-focusedHighlight': 'none',
  // },
  // '&:hover': {
  //   borderColor: '#007f99'
  // },
  
  // '& label.Mui-focused': {
  //   color: 'green',
  // },
  // '& .MuiInput-underline:after': {
  //   borderBottomColor: 'green',
  // },
  // '& .MuiOutlinedInput-root': {
  //   '& fieldset': {
  //     borderColor: 'red',
  //   },
  //   '&:hover fieldset': {
  //     borderColor: 'yellow',
  //   },
  //   '&.Mui-focused fieldset': {
  //     borderColor: 'green',
  //   },
  // },
});
// const McTextField = withStyles({
//   root: {
//     '& label': {
//       color: '#bdbdbd'
//     },
//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//         borderColor: '#8cc7c2',
//       },
//       '&:hover fieldset': {
//         borderColor: '#27999a',
//       },
//       '& label.Mui-focused': {
//         color: '#009899',
        
//       },
//       '&.Mui-focused fieldset': {
//         borderColor: '#009899'
//       }
//     },

//     "&.Mui-focused": {
//       borderColor: '#007f99',
//       borderWidth: "2px",
//       '--Input-focusedHighlight': 'none',
//     },
//     '&:hover': {
//       borderColor: '#007f99'
//     },
//   },

// })(TextField);
export default McTextField;