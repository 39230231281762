/**[ 2xx stands for success ] **/
export const SUCCESS_OPERATED_CODE = 2000; //, "Successfully operated."),

export const USER_LOGIN_SUCCESS_CODE = 200;

export const VALID_REGISTRATION_EMAIL_CODE = 201;

export const SUCCESS_ACTIVATE_ACCOUNT_CODE = 202;

export const SUCCESS_REGISTER_ACCOUNT_CODE = 203;

export const SUCCESS_RESEND_ACTIVATION_EMAIL_CODE = 204;

export const SUCCESS_SEND_CHANGE_PASSWORD_EMAIL_CODE = 205;

export const SUCCESS_CHANGE_PASSWORD_CODE = 206;

export const SUCCESS_ADD_POST_CODE = 207;

export const SUCCESS_FETCH_POSTS_CODE = 208;

export const SUCCESS_ADD_POST_COMMENT_CODE = 209;

export const SUCCESS_FETCH_POST_COMMENT_CODE = 210;

export const SUCCESS_ADD_POST_LIKE_CODE = 211; //"Successfully added post like."),

export const SUCCESS_ADD_COMMENT_LIKE_CODE = 212; //"Successfully added comment like."),

export const SUCCESS_ADD_COMMENT_REPLY_CODE = 213; //"Successfully added comment reply.");

export const SUCCESS_FETCH_COMMENT_REPLY_CODE = 214; // "Successfully fetched comment reply."), 

export const SUCCESS_UPDATE_AVATAR_CODE = 215; // "Successfully updated avatar.");

export const SUCCESS_UPDATE_CHAT_IMGS_CODE = 216; // "Successfully updated chat images.");

export const SUCCESS_FETCH_USER_FRIENDS_CODE = 217; //"Successfully fetch user friends.");

export const SUCCESS_ADD_USER_FRIEND_CODE = 218; // "Successfully add user friend.");

export const SUCCESS_SAVE_CHAT_MSG_CODE = 219; // "Successfully saved chat message."),

export const SUCCESS_FETCH_HISTORY_MSG_CODE = 220; // "Successfully fetched history chat messages.

export const SUCCESS_SAVE_CLASS_MSG_CODE = 221; //"Successfully saved new class.");

export const SUCCESS_SEARCH_CLASSES_CODE = 222; //, "Successfully search classes.");

export const SUCCESS_REQUESTED_JOIN_CLASS_MSG_CODE = 223; //, "Successfully requested joining class.");

export const SUCCESS_QUERY_CLASSES_MSG_CODE = 224; //"Successfully query classes.");

export const SUCCESS_QUERY_JOIN_CLASS_REQ_MSG_CODE = 225; //, "Successfully query join class requests.");

export const SUCCESS_JOINED_CLASS_CODE = 226; //, "Successfully handle join class requests.");

export const SUCCESS_QUERY_CLASSMATES_MSG_CODE = 227; //, "Successfully query classmates.");

export const SUCCESS_SAVE_CHATROOM_MSG_CODE = 228; //, "Successfully saved chatroom message.");

export const SUCCESS_FETCH_CHATROOM_HISTORY_MSG_CODE = 229; //, "Successfully fetched history chatroom messages.");

export const SUCCESS_UPLOAD_CHATROOM_IMGS_CODE = 230; //, "Successfully upload chatroom images.");

export const SUCCESS_HANDLE_LEAVE_CLASS_CODE = 231; //, "Successfully leave class."),

export const SUCCESS_HANDLE_DELETE_CLASS_CODE = 232; //, "Successfully delete class."),

export const SUCCESS_QUERY_CLASS_CODE = 233; // "Successfully get a class.");

export const SUCCESS_UPDATE_CLASS_CODE = 234; //, "Successfully update a class.");

// export const SUCCESS_QUERY_JOIN_CLASS_REQ_UNREAD_COUNT_CODE = 235; //, "Successfully query join class request count.");

export const SUCCESS_QUERY_CLASS_NOTIFICATION_CODE = 236; //, "Successfully query class notifications.");

export const SUCCESS_REMOVE_CLASSMATE_CODE = 237; //"Successfully removed classmate."),

export const SUCCESS_REMOVE_JOIN_CLASS_REQ_RESULT_CODE = 238; // "Successfully deleted."),

export const SUCCESS_REMOVE_USER_FRIEND_CODE = 239; //, "Successfully removeD friend.");

export const SUCCESS_FETCH_POST_IMAGE_CODE = 240; //, "Successfully fetched post images.");

export const SUCCESS_FETCH_POST_CODE = 241; //, "Successfully fetched a post and images.");

export const SUCCESS_UPDATE_POST_CODE = 242; //, "Successfully updated a post.");

export const SUCCESS_FETCH_MYCOMMENT_CODE = 245; //, "Successfully fetched my comments.");

export const SUCCESS_UPDATE_COMMENT_CODE = 248; //, "Successfully updated a comment."),
	
export const SUCCESS_UPDATE_REPLY_CODE = 249; //, "Successfully updated a reply.");

export const SUCCESS_VERIFIED_CHANGE_PASSWORD_CODE = 250; //, "Successfully verified change password code.");

export const SUCCESS_QUERY_BLACKBOARD_CODE = 251; //, "Successfully fetched class blackboard."); 

export const SUCCESS_UPDATE_BLACKBOARD_CODE = 252; //, "Successfully fetched class blackboard."); 

export const SUCCESS_FETCH_MYREPLY_CODE = 255; //, "Successfully fetched my replies.");

export const SUCCESS_FETCH_MYLIKE_POST_CODE = 256; //, "Successfully fetched my post likes."),
	
export const SUCCESS_FETCH_MYLIKE_COMMENT_CODE = 257; //, "Successfully fetched my comment likes."),

export const SUCCESS_FETCH_MYLIKE_REPLY_CODE = 258; //, "Successfully fetched my reply likes.");

export const SUCCESS_FETCH_MYPOSTS_CODE = 259; //, "Successfully fetched my posts.");

export const SUCCESS_ADD_COMMENT_REPLY_LIKE_CODE = 260; //, "Successfully added comment reply like.");

export const SUCCESS_CHANGED_CURRENT_SCHOOL_CODE = 261; //, "Successfully added comment reply like."),

export const SUCCESS_SUBSCRIBED_SCHOOL_CODE = 262; //, "Successfully added comment reply like.");

export const SUCCESS_UNSUBSCRIBE_SCHOOL_CODE = 263; //, "Successfully unsubscribed the school.");

export const SUCCESS_FETCH_MY_SCHOOLS_CODE = 264; //, "Successfully fetched my schools.");

export const SUCCESS_FETCH_USER_DETAIL_CODE = 265; //, "Successfully fetched user details.");

export const SUCCESS_UPDATE_USER_DETAIL_CODE = 266; //, "Successfully updated user details.");

export const SUCCESS_UPDATE_USER_LOCATION_CODE = 267; //, "Successfully updated user locations.");

export const SUCCESS_UPDATE_USER_BIO_CODE = 268; //, "Successfully updated user bio.");

export const SUCCESS_ADD_EDUCATION_CODE = 269; //, "Successfully added user education.");

export const SUCCESS_UPDATE_EDUCATION_CODE = 270; //, "Successfully updated user education."),
	
export const SUCCESS_QUERY_EDUCATION_CODE = 271; //, "Successfully queried user education.");

export const SUCCESS_REMOVE_EDUCATION_CODE = 272; //, "Successfully removed user education.");

export const SUCCESS_QUERY_SCHOOL_CODE = 273; //, "Successfully query school info.");

export const SUCCESS_LEFT_CHATROOM_CODE = 274; //, "Successfully left class chatroom.");

export const SUCCESS_QUERY_USER_PROFILE_CODE = 275; //, "Successfully query user profile.");

export const SUCCESS_FETCH_USER_PROFILE_POSTS_CODE = 276; //, "Successfully fetched user profile posts.");

export const SUCCESS_FETCH_POST_LIKES_CODE = 277; //, "Successfully fetched post likes."),

export const SUCCESS_FETCH_COMMENT_LIKES_CODE = 278; //, "Successfully fetched comment likes."),

export const SUCCESS_FETCH_REPLY_LIKES_CODE = 279; //, "Successfully fetched reply likes.");

export const SUCCESS_REPORTED_CODE = 280; //, "Successfully reported.");

// export const SUCCESS_TOKEN_LOGIN_CODE = 281; //, "Successfully token logged in.");

export const SUCCESS_SEARCHED_RESULTS_CODE = 282; //, "Successfully searched result.");

export const SUCCESS_FETCH_UNREAD_NOTIFS_COUNT_CODE = 283; //, "Successfully query unread notification count.");

export const SUCCESS_FETCH_UNREAD_NOTIFS_CODE = 284; //, "Successfully query unread notification count.");

export const SUCCESS_FETCH_NOTIFS_CODE = 285; //, "Successfully query notificationS.");

export const SUCCESS_FETCH_UNREAD_CLASS_MSG_COUNT_CODE = 286; //, "Successfully query unread class messages count."),

export const SUCCESS_AUTO_LOGIN_CODE = 287; //, "Successfully auto logined.");

export const SUCCESS_REGISTER_CURRENT_SCHOOL_CODE = 288; //, "Successfully registered current school.");

export const SUCCESS_REPORT_POST_CODE = 289; // "Successfully reported a post."),

export const SUCCESS_INIT_CHAT_CHANNEL_CODE = 290; // "Successfully initialized chat channel and participants conversation.");

// SUCCESS_SEND_CHAT_MESSAGE_CODE(291, "Successfully send one to one chat message."),

export const SUCCESS_FETCH_COLLEGEMATES_CODE = 292; // Successfully fetched my college mates.

// export const SUCCESS_MUTED_USER_CODE = 293; // Successfully muted user.

// export const SUCCESS_BLOCKED_USER_CODE = 294; // Successfully blocked user.

export const SUCCESS_QUERY_RESTRICT_CODE = 295; //Successfully query restrict accounts.

// export const SUCCESS_UNMUTE_USER_CODE = 296; //Successfully unmuted user.

// export const SUCCESS_UNBLOCK_USER_CODE = 297; //Successfully unblocked user.

export const SUCCESS_DELETE_ACCOUNT_CODE = 298; //, "Successfully delete class.");
