import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import McButtonOutlined from 'components/McButtonOutlined';
import { AgreeCookie } from 'store/actions';
import { MC_MAX_DAYS_AGREE_COOKIE } from 'mcconfig';

import useStyles from './CookiesNotification.style';

const CookiesNotification = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let mounted = true;
    if(mounted){
      const consent = Cookies.get('consent');
      if (!consent) {
        setOpen(true);
      }
    }
    return () => { mounted = false }
  }, []);

  const handleClose = () => {
    // Cookies.set('consent', 'true');
    Cookies.set('consent', 'true', { expires: MC_MAX_DAYS_AGREE_COOKIE });
    setOpen(false);
    // LoginForm will be informed, and login button will be enabled, otherwise, even
    // input username and password, login button is disabled. 
    dispatch(AgreeCookie());
  };

  if (!open) {
    return null;
  }

  return (
    <Paper
      className={classes.root}
      elevation={3}
    >
      <div className={classes.media}>
        <img
          alt="Cookies"
          className={classes.img}
          src="/images/cookie/cookies.png"
        />
      </div>
      <div className={classes.content}>
        <Typography variant="body1">
          We use Cookies to ensure that we give you the best experience on our
          website
          <Link
            className={classes.link}
            component="a"
            href="#"
            target="_blank"
          />
          .
        </Typography>
      </div>
      <div className={classes.actions}>
        <McButtonOutlined
          className={classes.agreeButton}
          onClick={handleClose}
        >
          I Agree
        </McButtonOutlined>
      </div>
    </Paper>
  );
};

export default CookiesNotification;
