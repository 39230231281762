import React                                      from 'react';
import { useDispatch }                            from 'react-redux';
import { useSelector }                            from 'react-redux';
import PropTypes                                  from 'prop-types';
import _                                          from 'lodash';
import clsx from 'clsx';

import IconButton             from '@material-ui/core/IconButton';
import Tooltip                from '@material-ui/core/Tooltip';

import { DisplaySimpleSnacker }                   from 'store/actions';
import { MC_SNACKER_TYPE_WARNING }                from 'mcconfig';
import useStyles                                  from './McAuthIconButton.style';

const McAuthIconButton = props => {
  const {className, size, title, onClick, children, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const {loggedIn, token} = useSelector(state => state.auth);

  const handleClickButton = () => {
    if(loggedIn && !_.isEmpty(token)){
      onClick();
    }else{
      dispatch(DisplaySimpleSnacker('Sign in please', MC_SNACKER_TYPE_WARNING));
    }
  }
  return (
    <Tooltip 
      title={title} 
    >
      <IconButton 
        {...rest}
        className={clsx(classes.root, className)}
        onClick={handleClickButton}
        size={_.isEmpty(size) ? 'medium' : size}
      >
        {children}
      </IconButton>
    </Tooltip>
  )
};

McAuthIconButton.propTypes = {
  children:       PropTypes.node,
  className:      PropTypes.string,
  onClick:        PropTypes.func,
  size:           PropTypes.oneOf(['small', 'medium', '']),
  title:          PropTypes.string,
};

export default McAuthIconButton;
