export const POST_ADD_FRIEND_URL = 'chat-api/mcchats/friends';

export const GET_FETCH_FRIENDS_URL = 'chat-api/mcchats/friends';

export const POST_SAVE_PEER_TXT_MSG_URL = 'chat-api/mcchats/messages/txt';

export const POST_SAVE_PEER_IMG_MSG_URL = 'chat-api/mcchats/messages/img';

export const POST_UPLOAD_PEER_IMG_MSG_URL = 'chat-api/mcchats/messages/uploads';

export const POST_FETCH_HISTORY_MESSAGES_URL = 'chat-api/mcchats/messages';

// Setting Profile
export const PUT_REMOVE_FRIEND_URL = 'chat-api/mcchats/friends';

// one to one chat
export const POST_INIT_CHAT_CHANNEL_MSG_URL = 'chat-api/mcchats/messages/channel';

export const POST_SEND_PEER_TXT_MSG_URL = 'chat-api/mcchats/messages/send';

export const POST_SEND_PEER_IMG_MSG_URL = 'chat-api/mcchats/messages/send-images';

export const DELETE_DELETE_PEER_MSG_URL = 'chat-api/mcchats/messages';

export const POST_SEND_PEER_AUDIO_MSG_URL = 'chat-api/mcchats/messages/send-audio';

export const POST_HIDE_CHANNEL_URL = 'chat-api/mcchats/channel/hide';

export const POST_DELETE_CHANNEL_URL = 'chat-api/mcchats/channel/delete';

// class chatroom
export const POST_SEND_CHATROOM_TXT_MSG_URL = 'chat-api/mcchats/messages/chatroom/send';

export const POST_SEND_CHATROOM_IMG_MSG_URL = 'chat-api/mcchats/messages/chatroom/send-images';

// export const DELETE_DELETE_CHATROOM_MSG_URL = 'chat-api/mcchats/messages/chatroom';
export const POST_SAVE_AND_DELETE_THREADS_URL = 'chat-api/mcchats/messages';

export const POST_SEND_CHATRROM_AUDIO_MSG_URL = 'chat-api/mcchats/messages/chatroom/send-audio';
