import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles(theme => ({
  content: {
    height: '100%',
    // paddingTop: 48,
    [theme.breakpoints.up('sm')]: {
      // paddingTop: 48
    },
    [theme.breakpoints.down('md')]: {
      minHeight: theme.spacing(125),
    },
    // backgroundImage: 'url("/images/covers/auth_bg.png")',
    backgroundSize: 'contain'
  },
  topBar: {
    zIndex: 2,
    position: 'relative',
    backgroundColor: '#007f99'
  },
}));

export default useStyles;