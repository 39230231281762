import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';

// import Avatar from '@material-ui/core/Avatar';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import ListItemText from '@material-ui/core/ListItemText';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import TurnedInNotOutlinedIcon from '@material-ui/icons/TurnedInNotOutlined';
import TextsmsOutlinedIcon from '@material-ui/icons/TextsmsOutlined';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';

import { MC_NOTIFICATION_TYPE_WELCOME, MC_NOTIFICATION_TYPE_COMMENT, MC_NOTIFICATION_TYPE_JOINCLASS
  , MC_NOTIFICATION_TYPE_JOINGROUP, MC_NOTIFICATION_TYPE_MESSAGE, MC_NOTIFICATION_TYPE_LIKE } from 'mcconfig'
import useStyles from './NotificationList.style';

const NotificationList = props => {
  const { notifications, onClick, className, ...rest } = props;

  const classes = useStyles();

  const avatars = {
    [MC_NOTIFICATION_TYPE_WELCOME]: (
      <Avatar className={classes.avatarOrange}>
        <TurnedInNotOutlinedIcon />
      </Avatar>
    ),
    [MC_NOTIFICATION_TYPE_COMMENT]: (
      <Avatar className={classes.avatarBlue}>
        <TextsmsOutlinedIcon />
      </Avatar>
    ),
    [MC_NOTIFICATION_TYPE_JOINCLASS]: (
      <Avatar className={classes.avatarGreen}>
        <SupervisedUserCircleOutlinedIcon />
      </Avatar>
    ),
    [MC_NOTIFICATION_TYPE_JOINGROUP]: (
      <Avatar className={classes.avatarLime}>
        <GroupOutlinedIcon />
      </Avatar>
    ),
    [MC_NOTIFICATION_TYPE_MESSAGE]: (
      <Avatar className={classes.avatarLime}>
        <ForumOutlinedIcon />
      </Avatar>
    ),
    [MC_NOTIFICATION_TYPE_LIKE]: (
      <Avatar className={classes.avatarRed}>
        <FavoriteBorderOutlinedIcon />
      </Avatar>
    )
  };

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {notifications.map((notification, i) => {
        if(i<=5){
          return (

            <ListItem>
              <ListItemText 
                primary={
                  <Stack>
                    <Typography variant="subtitle2" gutterBottom sx={{ display: 'block' }}>
                      {notification.title}
                    </Typography>
                    <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
                      {moment(notification.createDatetime).fromNow()}
                    </Typography>
                  </Stack>
                } 
                secondary={notification.content} 
              />
            </ListItem>
          )
        }
      })}

    </List>
    // <List
    //   {...rest}
    //   className={clsx(classes.root, className)}
    //   disablePadding
    // >
    //   {notifications.map((notification, i) => (
    //     <ListItem
    //       className={clsx(
    //         classes.listItem,
    //         {
    //           [classes.unread] : notification.status === 1
    //         })}
    //       component={RouterLink}
    //       divider={i < notifications.length - 1}
    //       key={notification.id}
    //       onClick={onClick}
    //       to="#"
    //     >
    //       <ListItemAvatar>{avatars[notification.type]}</ListItemAvatar>
    //       <ListItemText

  //         primary={
  //           <div  
  //             className={classes.postContent}
  //             dangerouslySetInnerHTML={{ __html:notification.title}} 
  //             id="notificationTitle"
  //             name="notificationTitle"
  //           />
  //         }
  //         primaryTypographyProps={{ variant: 'body1' }}
  //         secondary={moment(notification.createDatetime).fromNow()}
  //       />
  //     </ListItem>
  //   ))}
  // </List>
  );
};

NotificationList.propTypes = {
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired,
  onClick: PropTypes.func
};

export default NotificationList;
