import axios from '../../utils/mcaxios';
import {
  GET_QUERY_CLASSES_URL,
  GET_QUERY_CLASS_URL, //class detail with school, city
  PUT_UPDATE_OPTIONAL_CLASS_URL,
  POST_SAVE_CLASS_URL,
  POST_SEARCH_CLASS_BY_INPUT_URL,
  GET_QUERY_CLASSROOM_CLASSMATES_URL,
  POST_JOIN_CLASS_REQUEST_URL,
  GET_QUERY_JOIN_CLASS_REQ_URL,
  PUT_JOIN_CLASS_REQUEST_URL,
  DELETE_JOIN_CLASS_REQ_RESULT_URL,
  GET_QUERY_JOIN_CLASS_REQ_APPLICANT_URL,
  DELETE_REMOVE_CLASSMATE_URL,
  GET_QUERY_CLAZZ_URL, // only class record
  GET_CHECK_CLASSMATE_CHAT_STATUS_URL,
} from '../../store/rest-api';

const queryClasses = async () => {
  try {
    const result = await axios.get(GET_QUERY_CLASSES_URL);
    return result.data;
  } catch (error) { /* empty */ }
};
const leaveClass = async (URL, clazz) => {
  try {
    const result = await axios.delete(URL, {
      data: {
        classId: clazz.id,
        schoolId: clazz.schoolId,
      },
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const getSingleClass = async (classId) => {
  try {
    const result = await axios.get(
      GET_QUERY_CLASS_URL.concat('/').concat(classId),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const updateClass = async (editClazz) => {
  try {
    const result = await axios.put(PUT_UPDATE_OPTIONAL_CLASS_URL, {
      ...editClazz,
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const createClass = async (clazz) => {
  try {
    const result = await axios.post(POST_SAVE_CLASS_URL, {
      ...clazz,
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const searchClass = async (schoolId, keywords, skip) => {
  try {
    const result = await axios.post(POST_SEARCH_CLASS_BY_INPUT_URL, {
      schoolId,
      keywords,
      skip,
    });
    return result.data;
  } catch (error) { /* empty */ }
};
// const joinSearchClass = async (classId, schoolId) => {
//   try {
//     let result = await axios.put(PUT_JOIN_CLASS_URL, {
//       classId: classId,
//       schoolId: schoolId,
//     });
//     return result.data;
//   } catch (error) {}
// };
const queryClassmatesByClassId = async (classId, skip) => {
  try {
    // let result = await axios.get(
    //   GET_QUERY_CLASSROOM_CLASSMATES_URL.concat('/')
    //     .concat(classId)
    //     .concat('/')
    //     .concat(skip),
    // );
    const result = await axios.get(
      GET_QUERY_CLASSROOM_CLASSMATES_URL.concat('?')
        .concat('classId=')
        .concat(classId)
        .concat('&')
        .concat('skip=')
        .concat(skip),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const joinClassRequest = async (classId, description) => {
  try {
    const result = await axios.post(POST_JOIN_CLASS_REQUEST_URL, {
      classId,
      description,
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const queryJoinClassRequestsByClassId = async (classId, status, skip) => {
  try {
    const result = await axios.get(
      GET_QUERY_JOIN_CLASS_REQ_URL.concat('?')
        .concat('classId=')
        .concat(classId)
        .concat('&')
        .concat('status=')
        .concat(status)
        .concat('&')
        .concat('skip=')
        .concat(skip),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const handleJoinClass = async (requestId, userId, status) => {
  try {
    const result = await axios.put(PUT_JOIN_CLASS_REQUEST_URL, {
      requestId,
      userId,
      status,
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const deleteJoinClassRequest = async (requestId) => {
  try {
    const result = await axios.delete(DELETE_JOIN_CLASS_REQ_RESULT_URL, {
      data: {
        requestId,
      },
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const queryMyJoinClassRequests = async (status, skip) => {
  try {
    const result = await axios.get(
      GET_QUERY_JOIN_CLASS_REQ_APPLICANT_URL.concat('?')
        .concat('status=')
        .concat(status)
        .concat('&')
        .concat('skip=')
        .concat(skip),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const removeClassmate = async (classId, userId) => {
  try {
    const result = await axios.delete(DELETE_REMOVE_CLASSMATE_URL, {
      data: {
        classId,
        userId,
      },
    });
    return result.data;
  } catch (error) { /* empty */ }
};
const getClassById = async (classId) => {
  try {
    const result = await axios.get(
      GET_QUERY_CLAZZ_URL.concat('?').concat('classId=').concat(classId),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
const checkClassmateChatStatus = async (clazz) => {
  try {
    const result = await axios.get(
      GET_CHECK_CLASSMATE_CHAT_STATUS_URL.concat('?')
        .concat('classId=')
        .concat(clazz.id)
        .concat('&')
        .concat('classFsId=')
        .concat(clazz.classFsId),
    );
    return result.data;
  } catch (error) { /* empty */ }
};
export const classService = {
  queryClasses,
  leaveClass,
  getSingleClass,
  updateClass,
  createClass,
  searchClass,
  // joinSearchClass,
  queryClassmatesByClassId,
  joinClassRequest,
  queryJoinClassRequestsByClassId,
  handleJoinClass,
  deleteJoinClassRequest,
  queryMyJoinClassRequests,
  removeClassmate,
  getClassById,
  checkClassmateChatStatus,
};
