import React, { Fragment }    from 'react'
import { useSelector, 
  useDispatch }               from 'react-redux';
import PropTypes              from 'prop-types';
import PerfectScrollbar       from 'react-perfect-scrollbar';
import clsx                   from 'clsx';
import moment                 from 'moment';
import _                      from 'lodash';

import List                         from '@material-ui/core/List';
import ListItem                     from '@material-ui/core/ListItem';
import ListItemIcon                 from '@material-ui/core/ListItemIcon';
import ListItemText                 from '@material-ui/core/ListItemText';
import ListItemSecondaryAction      from '@material-ui/core/ListItemSecondaryAction';
import PersonAddIcon                from '@material-ui/icons/PersonAdd';
import Link                         from '@material-ui/core/Link';
import Typography                   from '@material-ui/core/Typography';

import { AddFriendToChatBar,
  DisplaySimpleSnacker }            from 'store/actions';
import { saveFriend }               from 'utils/ChatUtils';
import { 
  MC_SNACKER_TYPE_SUCCESS}          from 'mcconfig'
import { McAvatar, 
  McAuthIconButton,
  McButtonText, 
  McLoader}                         from 'components';
import { 
  SUCCESS_ADD_USER_FRIEND_CODE }    from 'store/statusCode';
import useStyles                    from './McLikesList.style';

export const McLikesList = (props) => {
  const {  likeList, loading, totalCount, 
    onFetchMorePostLikes, onUnlike,
    className, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  // const [openSnacker, setOpenSnacker] = useState(false);
  // const [peerName, setPeerName] = useState(false);
  const { peerIds } = useSelector(state => state.chat);
  const { agoraUid } = useSelector(state => state.auth.loginUserInfo);

  const handleCallback = (status, message, newFriendVo) => {
    if(status === SUCCESS_ADD_USER_FRIEND_CODE ){
      dispatch(AddFriendToChatBar(newFriendVo));
    }
    dispatch(DisplaySimpleSnacker(message, MC_SNACKER_TYPE_SUCCESS));
    // post.createUserName + ' is added as your Friend.
  }
  const handleAddFriend = (like) => () => {

    const newFriend = {
      peerId: like.createUserId,
      peerName: like.createUserName,
      peerAvatar: like.createUserAvatar,
    }
    // dispatch(AddFriendToChatBar(newFriend));
    saveFriend(newFriend, handleCallback);
    // setPeerName(like.createUserName)
    // setOpenSnacker(true);
  }
  // const handleCloseSnacker = () => {
  //   setOpenSnacker(false);
  // };
  const handleShowMoreMyPosts = () => {
    onFetchMorePostLikes();
  }
  const handleUnlike = ()  => {
    onUnlike()
  }
  return(
    <Fragment>
      <McLoader 
        className={classes.loadingPosts}
        loading={loading} 
        size={20}
        thickness={4}
        type="query"
      />
      <Typography
        className={classes.countLabel}
        variant="subtitle2"
      >
        {totalCount} Records
      </Typography>
      <PerfectScrollbar
        className={classes.scrollbar}
      >
        <List 
          {...rest}
          className={clsx(classes.root, className)}
          dense
          disablePadding
        >
          {
            likeList && likeList.map((like) => (
              <ListItem
                key={like.id}
              >
                <ListItemIcon>        
                  <McAvatar 
                    avatarUrl={like.createUserAvatar}
                    className={classes.avatar}
                    userName={like.createUserName}
                  />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItem}
                  primary={like.createUserName}
                  primaryTypographyProps={{
                    noWrap: true,
                    variant: 'h6'
                  }}
                  secondary={moment(like.createDatetime).local().fromNow()}
                  secondaryTypographyProps={{
                    noWrap: true,
                    variant: 'body2'
                  }}
                  title={like.createUserName + ' liked this post, in ' + moment(like.createDatetime).local().format('llll')}
                />
                <ListItemSecondaryAction>
                  {          
                    !_.includes(peerIds, like.createUserId)
                    &&
                    agoraUid !== like.createUserId
                    && 
                    <McAuthIconButton
                      onClick={handleAddFriend(like)}
                      size="small"
                      title="Add friend"
                    >
                      <PersonAddIcon className={classes.searchIcon} />
                    </McAuthIconButton>
                  }
                  {          
                    agoraUid === like.createUserId
                    && 
                    <McButtonText
                      onClick={handleUnlike}
                    >
                        Unlike
                    </McButtonText>
                  }
                </ListItemSecondaryAction>
              </ListItem>
            ))
          }
        </List>
      </PerfectScrollbar>

      {
        totalCount > likeList.length 
        &&
        !loading
        &&
        <div className={classes.loadingMoreLink}>
          <Link 
            className={classes.showPrevious}
            href="#" 
            onClick={handleShowMoreMyPosts}
            variant="caption"
          >
            Loading {totalCount - likeList.length} more posts
          </Link>
        </div>
      }
      {/* <McSnackbar 
        handleClose={handleCloseSnacker}
        message={'Added '.concat(peerName)}
        open={openSnacker}
        variant="success"
      /> */}
    </Fragment>
  )   
}
McLikesList.propTypes = {
  className: PropTypes.string,
  likeList: PropTypes.array,
  loading: PropTypes.bool,
  onFetchMorePostLikes: PropTypes.func,
  onUnlike: PropTypes.func,
  totalCount: PropTypes.number,
};
  
export default McLikesList;
