import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  topBar: {
    zIndex: 2,
    position: 'relative',
    backgroundColor: '#007f99'
  },
  container: {
    padding: '35px',
    paddingTop: '10px',
    // display: 'flex',
    // flex: '1 1 auto',
    // overflow: 'hidden',
    // width: '100%',
    // marginLeft: 'auto', 
    // marginRight: 'auto',
    // [theme.breakpoints.down('sm')]: {
    //   width: '100%',
    // }
  },
  navBar: {
    zIndex: 3,
    width: 256,
    minWidth: 256,
    flex: '0 0 auto'
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto'
  }
}));

export default useStyles;

/* chatBar: {
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  // padding: theme.spacing(0.5),
  position: 'fixed',

  [theme.breakpoints.down('sm')]: {
    left: 5,
    bottom: 5
  },
  [theme.breakpoints.up('md')]: {
    bottom: 28,
    right: 80,
  },
  [theme.breakpoints.between('sm', 'md')]: {
    left: 5,
    bottom: 5
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    left: 5,
    bottom: 5
  },
  zIndex: 500,
  border: '1px solid red'
},
chatBoxBar: {
  // padding: theme.spacing(0.5),
  border: '1px solid blue'
},
chatRoomBar: {
  // padding: theme.spacing(0.5),
  border: '1px solid lightgreen'

} */