
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
const useStyles = makeStyles(() => ({
  root: {}
}));

const McSchoolDetail = props => {
  const { schoolInfo, className,  ...rest } = props;

  const classes = useStyles();

  return (
    <Box
      sx={{
        // display: 'flex',
        // flexWrap: 'wrap',
        // '& > :not(style)': {
        //   m: 1,
        //   width: 128,
        //   height: 128,
        // },
        border: '1px solid grey',
        padding: '15px',
        margin: '5px',
        borderRadius: '5px'
      }}
    >
      <Paper elevation={0}>
        <Typography
          variant="subtitle2"
          // variant="overline"
        >
          { schoolInfo.name }
        </Typography>
        <Typography
          variant="caption"
        >
          { 'Campus' }
        </Typography>
        <Typography
          variant="body1"
          // variant="overline"
        >
          { schoolInfo.campus }
        </Typography>
        <Typography
          variant="caption"
        >
          { 'City' }
        </Typography>
        <Typography
          variant="body1"
          // variant="overline"
        >
          { schoolInfo.city }
        </Typography>
        <Typography
          variant="caption"
        >
          { 'Province/State' }
        </Typography>
        <Typography
          variant="body1"
          // variant="overline"
        >
          { schoolInfo.province }
        </Typography>
      </Paper>
    </Box>
    // <div
    //   {...rest}
    //   className={clsx(classes.root, className)}
    // >
    //   <Typography
    //     component="h2"
    //     variant="overline"
    //   >
    //     { schoolInfo.name }
    //   </Typography>
    // </div>
  );
};

McSchoolDetail.propTypes = {
  className: PropTypes.string,
  schoolInfo: PropTypes.object
};

export default McSchoolDetail;
