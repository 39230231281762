import React                                      from 'react';
import { useDispatch }                            from 'react-redux';
import { useSelector }                            from 'react-redux';
import PropTypes                                  from 'prop-types';
import _                                          from 'lodash';
import clsx from 'clsx';

import { DisplaySimpleSnacker }                   from 'store/actions';
import { MC_SNACKER_TYPE_WARNING }                from 'mcconfig';
import {McButtonOutlined }                        from 'components';
import useStyles                                  from './McAuthButton.style';

const McAuthIconButton = props => {
  const {className, onClick, disabled, children,  ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const {loggedIn, token} = useSelector(state => state.auth);

  const handleClickButton = () => {
    // console.log(loggedIn, token)
    if(loggedIn && !_.isEmpty(token)){
      onClick();
    }else{
      // console.log('not logged in...');
      dispatch(DisplaySimpleSnacker('Sign in please', MC_SNACKER_TYPE_WARNING));
    }
  }
  return (
    <McButtonOutlined
      {...rest}
      className={clsx(classes.root, className)}
      disabled={disabled === true ? true : false}
      onClick={handleClickButton}
    >
      {children}
    </McButtonOutlined>
  )
};

McAuthIconButton.propTypes = {
  children:       PropTypes.node,
  className:      PropTypes.string,
  disabled:       PropTypes.bool,
  onClick:        PropTypes.func,
};

export default McAuthIconButton;
