/**[ 1xx stands for exception ] **/
export const FAILED_OPERATED_CODE = 1000; //, "Failed operated."),

export const USER_NOT_EXIST_IN_REDIS_CODE = 1001; //, "Account not logged in redis."),

export const USER_NOT_EXIST_CODE = 100;

export const USER_NOT_ACTIVATED_CODE = 101;

export const MAX_LOGIN_ATTEMPTS_CODE = 102;

export const USER_EXIST_CODE = 103;

export const SCHOOL_NOT_EXIST_CODE = 104;

export const ROLE_NOT_EXIST_CODE = 105;

export const IDENTITY_NOT_EXIST_CODE = 106;

export const CONSTRAINT_VIOLATION_CODE = 107;

export const METHOD_ARGUMENT_NOT_VALID_CODE = 108;

export const GLOBAL_EXCEPTION_CODE = 109;

export const FAILED_ACTIVATE_ACCOUNT_CODE = 110;

export const  FAILED_REGISTER_ACCOUNT_CODE = 111;

export const  ACTIVATION_TOKEN_EXPIRED_CODE = 112;

export const  FAILED_RESEND_ACTIVATION_EMAIL_CODE = 113;

export const  FAILED_SEND_CHANGE_PASSWORD_EMAIL_CODE = 114;

export const  FAILED_CHANGE_PASSWORD_CODE = 115;

export const  EXPIRED_CHANGE_PASSWORD_CODE = 116;

export const  NO_MC_ACCOUNT_FOUND_CODE = 117;

export const  FAILED_ADD_POST_CODE = 118;

export const  FAILED_FETCH_POSTS_CODE = 119;

export const  FAILED_ADD_POST_COMMENT_CODE = 120; // "Failed to add post comment."),
	
export const  FAILED_ADD_POST_LIKE_CODE = 121; // "Failed to add post like."),

export const  FAILED_ADD_COMMENT_LIKE_CODE = 122; // "Failed to add comment like."),

export const  FAILED_ADD_COMMENT_REPLY_CODE = 123; // "Failed to add comment reply."),

export const  FAILED_UPDATE_AVATAR_CODE = 124; // "Failed to update avatar."),

export const FAILED_JOIN_CLASS_MSG_CODE = 127; //, "Failed join class."),

export const FAILED_ENTER_CHATROOM_CODE = 130; //, "Failed enter chat room."),

export const INCORRECT_OLD_PASSWORD_CODE = 137; //, "Your old password is wrong."),

export const ACTIVATION_INCORRECT_DIGITS_CODE = 138; //, "The 6 digits didn't match our record."),

export const CHANGEPASSWORD_INCORRECT_DIGITS_CODE = 139; //, "The 6 digits didn't match our record."),

export const FAILED_QUERY_SCHOOL_CODE = 140; //, "Failed to change school name, please try later."),

export const EXCEED_MAX_COUNT_CREATE_CLASS_CODE = 141; //, "Exceeded the maximum classes."),

export const DUPLICATE_JOIN_CLASS_CODE = 142; //, "Your already created this class."),

export const EXCEED_MAX_POST_ONEDAY_CODE = 143; //, "Sorry, maximium 5 posts per day."),

export const EXCEED_MAX_EMAIL_FORGET_PASSWORD_ONEDAY_CODE = 144; //, "Sorry, please try it in 24 hours."),

export const EXCEED_MAX_EMAIL_ACTIVATE_ACCOUNT_ONEDAY_CODE = 145; //, "Sorry, please try it in 24 hours."),

export const EXCEED_MAX_REGISTER_ACCOUNTS_ONEDAY_CODE = 146; //, "Sorry, please try it in 24 hours."),

export const EXCEED_MAX_AUTOCOMPLETE_SCHOOL_REQUEST_ONEDAY_CODE = 147; //, "Sorry, please try it in 24 hours."),

export const EXCEED_FAILED_VERIFY_RECAPTCHA_CODE = 148; //, "Please verify you are not a robot."),

export const EXISTED_CREATE_CLASS_CODE = 149; //, "This class is already existed."),

export const DUPLICATE_ADD_FRIEND_CODE = 150; //, "It's already in your friends list."),

export const EXCEED_MAX_COUNT_JOIN_CLASS_REQUEST_CODE = 151; //, "Exceeded the maximum join class requests, max. 20."), = 150;

export const CANCELED_JOIN_CLASS_REQUEST_CODE = 152; //, "This request is canceled"),

export const LOGIN_WRONG_PASSWORD_CODE = 153; //"Username and password didn't match our record."),

export const DELETE_ACCOUNT_IN_PROGRESS_CODE = 154; // "This account is being deleted from our system, it will take 24 hours to complete."),

export const CHAT_ACCOUNT_BLOCKED = 155; //"You blocked this account."),

export const CHAT_ACCOUNT_BE_BLOCKED = 156; //"This account blocked you."),

export const CHAT_ACCOUNT_NOT_EXIST = 157; // "This account not exists."),

export const CHAT_ACCOUNT_SUSPENDED = 158; // "This account is suspended."),