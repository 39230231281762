import * as actionTypes from '../actions/actionTypes';
const initialState = {
  loading: false,
  postCategories: [],
  mainFeedPosts: [],
  nextSkip: 0,
  postLikes: [],
};
import _ from 'lodash';
const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SINGLE_FEED_POST: {
      const mainFeedPostsUpdated = state.mainFeedPosts.map((feed) => {
        if (feed.id === action.feedItem.id) {
          feed = { ...action.feedItem };
        }
        return feed;
      });
      return {
        ...state,
        mainFeedPosts: [...mainFeedPostsUpdated],
      };
    }
    case actionTypes.DELETE_SINGLE_FEED_POST: {
      const mainFeedPostsUpdated = _.filter(
        state.mainFeedPosts,
        (feed) => feed.id !== action.feedId,
      );
      return {
        ...state,
        mainFeedPosts: [...mainFeedPostsUpdated],
      };
    }
    // remove blocked or muted user posts
    case actionTypes.DELETE_FEED_POSTS_BY_USERID: {
      const mainFeedPostsUpdated = _.filter(
        state.mainFeedPosts,
        (feed) => feed.createUserId !== action.userId,
      );
      return {
        ...state,
        mainFeedPosts: [...mainFeedPostsUpdated],
      };
    }
    case actionTypes.SET_SINGLE_FEED_POST_STATUS: {
      const mainFeedPostsUpdated = state.mainFeedPosts.map((feed) => {
        if (feed.id === action.feedId) {
          feed.status = action.status;
        }
        return feed;
      });
      return {
        ...state,
        mainFeedPosts: [...mainFeedPostsUpdated],
      };
    }
    /* [ 3 ], Fetching Post Categories */
    case actionTypes.FETCH_POST_CATEGORIES_SUCCESS: {
      return {
        ...state,
        postCategories: action.postCategories,
      };
    }
    case actionTypes.SET_MAIN_FEED_POSTS: {
      return {
        ...state,
        mainFeedPosts: [...state.mainFeedPosts, ...action.mainFeedPosts], // append
        nextSkip: action.nextSkip,
      };
    }
    case actionTypes.RESET_MAIN_FEED_POSTS: {
      return {
        ...state,
        mainFeedPosts: [...action.mainFeedPosts], //reset
        nextSkip: action.nextSkip,
      };
    }
    case actionTypes.SET_POST_LIKES: {
      return {
        ...state,
        postLikes: [...action.postLikes],
      };
    }
    // update single post like count
    case actionTypes.SET_POST_LATEST_LIKES_COUNT: {
      const mainFeedPostsUpdated = state.mainFeedPosts.map((feed) => {
        if (feed.id === action.feedId) {
          feed.likeCount = action.latestCount;
        }
        return feed;
      });
      return {
        ...state,
        mainFeedPosts: [...mainFeedPostsUpdated],
      };
    }
    case actionTypes.CLEAN_POST: {
      return {
        loading: false,
        postCategories: [],
        mainFeedPosts: [],
        nextSkip: 0,
      };
    }
    default: {
      return state;
    }
  }
};

export default postReducer;

/* [ 1 ], Add new Post */
/*     case actionTypes.ADD_POST_START: {
      return {
        ...state,
        loading: true
      };
    }
    case actionTypes.ADD_POST_FAILED: {
      return {
        ...state,
        loading: false,
        addPostStatus: action.status,
        addPostMessage: action.message,
        addPostTimestamp: action.timestamp
      };
    }
    case actionTypes.ADD_POST_SUCCESS: {
      return {
        ...state,
        loading: false,
        addPostStatus: action.status,
        addPostMessage: action.message,
        addPostTimestamp: action.timestamp
      };
    } */
/* [ 2 ], Fetching Posts 
    case actionTypes.FETCH_POSTS_START: {
      return {
        ...state,
        fetchPostLoading: true
      };
    }
    case actionTypes.FETCH_POSTS_FAILED: {
      return {
        ...state,
        fetchPostLoading: false,
      };
    }
    case actionTypes.FETCH_POSTS_SUCCESS: {
      return {
        ...state,
        fetchPostLoading: false,
        posts: action.posts,
      };
    }*/
