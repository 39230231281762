import React, {useState} from 'react'
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import _ from 'lodash';

import Paper from '@material-ui/core/Paper';

import axios from 'utils/mcaxios';
import {
  renderInputComponent,
  renderSuggestion,
  shouldRenderSuggestions
} from 'utils/AutoSuggestFuncs';

import useStyles from './McTextFieldAutoSuggest.style';
import { noauthService } from 'service';

const McTextFieldAutoSuggest = (props) => {
  const { id, label, name, 
    handleClearSchool, 
    setAllMatchedSchools,
    formState, handleChange, hasError, 
    getSuggestionValue } = props;
  const classes = useStyles();
  /** autocomplete */
  let [suggestions, setSuggestions] = useState([]); 

  const [autocompleteLoading, setAutoCompleteLoading] = useState(false);//useSelector(state => state.auth.autocompleteSchoolLoading);
  /**Schools autocomplete */
  suggestions = (suggestions == null ? [] : suggestions);

  const handleSuggestionsFetchRequested = ({ value }) => {

    if(_.trim(value) !== formState.values[name]){
      setAutoCompleteLoading(true);
      noauthService.autocompleteSchoolByCountry(
        formState.values['country'],
        _.trim(value),
      )
      // axios.get(url.concat('/').concat(value))
        .then(function (response) {
          const schools = response;
          if (schools) {
            let matchedSchools = JSON.parse(JSON.stringify(schools));
            const suggestions = matchedSchools.map((school) => {
              let parsedSchool = JSON.parse(school);
              return {
                id: parsedSchool.id,
                title:
                  parsedSchool.name +
                  ', ' +
                  parsedSchool.city +
                  ', ' +
                  parsedSchool.province,
              };
            });
            setSuggestions(suggestions);
            setAllMatchedSchools(matchedSchools);
          }
          setAutoCompleteLoading(false);
        })
        .catch(function (error) {
          setAutoCompleteLoading(false);
          console.log(error);
        });
    }
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  const autosuggestProps = {
    renderInputComponent,//Use it only if you need to customize the rendering of the input.
    suggestions: suggestions, // Array These are the suggestions that will be displayed. Items can take an arbitrary shape.
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,// Will be called every time you need to recalculate suggestions.
    onSuggestionsClearRequested: handleSuggestionsClearRequested, //Will be called every time you need to set suggestions to [].
    getSuggestionValue: getSuggestionValue, //Implement it to teach Autosuggest what should be the input value when suggestion is clicked.
    renderSuggestion,//Use your imagination to define how suggestions are rendered.
    shouldRenderSuggestions,//When the input is focused, Autosuggest will consult this function when to render suggestions. Use it, for example, if you want to display suggestions when input value is at least 2 characters long.
  };
  const handleClearAutoComplete = () => {
    handleSuggestionsClearRequested();
    handleClearSchool();
  }
  return (
    <Autosuggest
      {...autosuggestProps}
      inputProps={{
        classes,
        error: hasError(name),
        helperText: hasError(name) ? formState.errors[name][0] : null,
        name: name,
        onChange: handleChange(name),
        onClearAutoComplete: handleClearAutoComplete,
        value: formState.values[name] || '',
        id: id,
        label: label,
        placeholder: 'Search your '.concat(name),
        loading: autocompleteLoading
      }}
      name={name}
      renderSuggestionsContainer={options => {
        let elementList = '';
        if (options.children) {
          elementList =
            <Paper
              {...options.containerProps}
              square
            >
              {options.children}
            </Paper>
        }
        return elementList;
      }}
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
        sectionTitle: classes.sectionTitle
      }}
    />
  )
}
McTextFieldAutoSuggest.propTypes = {
  formState: PropTypes.object,
  getSuggestionValue : PropTypes.func, 
  handleChange: PropTypes.func, 
  hasError: PropTypes.func, 
  id: PropTypes.string,  
  label: PropTypes.string,  
  name: PropTypes.string,  
  url: PropTypes.string,
};
export default McTextFieldAutoSuggest
