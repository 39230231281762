import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import SendIcon from '@material-ui/icons/Send';
import TelegramIcon from '@material-ui/icons/Telegram';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import LockIcon from '@material-ui/icons/Lock';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(1)
  },
}));

const McLoader = props => {
  const { display, name } = props;
  const classes = useStyles();
  let result = '';
  if(display){
    switch (name) {
      case 'submit':        
        result = 
          <SendIcon 
            className={classes.icon} 
            style={{ fontSize: 20 }}
          />
        break;
      case 'telegram':        
        result = 
          <TelegramIcon 
            className={classes.icon} 
            style={{ fontSize: 20 }}
          />
        break;
      case 'groupadd':        
        result = 
          <GroupAddIcon 
            className={classes.icon} 
            style={{ fontSize: 20 }}
          />
        break;
      case 'findinpage':        
        result = 
          <FindInPageOutlinedIcon 
            className={classes.icon} 
            style={{ fontSize: 20 }}
          />
        break;
      case 'search':        
        result = 
          <SearchIcon 
            className={classes.icon} 
            style={{ fontSize: 20 }}
          />
        break;
      case 'back':        
        result = 
          <ArrowBackIcon 
            className={classes.icon} 
            style={{ fontSize: 20 }}
          />
        break;
      case 'return':        
        result = 
          <KeyboardReturnIcon 
            className={classes.icon} 
            style={{ fontSize: 20 }}
          />
        break;
      case 'adduser':        
        result = 
          <PersonAddIcon 
            className={classes.icon} 
            style={{ fontSize: 20 }}
          />
        break;
      case 'deny':        
        result = 
          <RemoveCircleOutlineIcon 
            className={classes.icon} 
            style={{ fontSize: 20 }}
          />
        break;
      case 'approve':        
        result = 
          <CheckCircleOutlineIcon 
            className={classes.icon} 
            style={{ fontSize: 20 }}
          />
        break;
      case 'clock':        
        result = 
          <AccessTimeIcon 
            className={classes.icon} 
            style={{ fontSize: 20 }}
          />
        break;
      case 'add':        
        result = 
          <AddCircleOutlineIcon 
            className={classes.icon} 
            style={{ fontSize: 20 }}
          />
        break;
      case 'lock':        
        result = 
          <LockIcon 
            className={classes.icon} 
            style={{ fontSize: 20 }}
          />
        break;
      case 'exit':        
        result = 
          <ExitToAppIcon 
            className={classes.icon} 
            style={{ fontSize: 20 }}
          />
        break;
      case 'poweroff':        
        result = 
          <PowerSettingsNewIcon 
            className={classes.icon} 
            style={{ fontSize: 20 }}
          />
        break;
      default:
        result = 
        <SendIcon 
          className={classes.icon} 
          style={{ fontSize: 20 }}
        />
        break;
    }
  }
  return result;
};
McLoader.propTypes = {
  display: PropTypes.bool.isRequired,
  name: PropTypes.string,
};

export default McLoader;
