import { makeStyles } from '@material-ui/styles';
import gradients from 'utils/gradients';
import { colors } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  },
  avatarBlue: {
    backgroundImage: gradients.blue
  },
  avatarGreen: {
    backgroundImage: gradients.green
  },
  avatarOrange: {
    backgroundImage: gradients.orange
  },
  avatarLime: {
    backgroundImage: gradients.lime
  },
  avatarRed: {
    backgroundImage: gradients.red
  },
  arrowForwardIcon: {
    color: theme.palette.icon
  },
  unread:{
    backgroundColor: colors.teal[100]
  },
  postContent: {
    fontSize: '15px',
    padding: theme.spacing(1, 0),
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',

    // wordWrap: 'anywhere',
    overflowWrap: 'anywhere',
    whiteSpace: 'break-spaces',
    wordWrap: 'break-word',
    wordBreak:'break-all',

    '& .twemoji': {
      width: '22px',
      marginLeft: '1px',
      marginRight: '1px',
      verticalAlign: 'middle',
    }
  },
}));

export default useStyles;