import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  likeButton: {},
  commentButton: {
    marginLeft: theme.spacing(2),
  },
  likedButton: {
    color: colors.red[600]
  },
  shareButton: {
    marginLeft: 'auto',
    padding: '0px'
  },
  shareIcon: {
      
  },
  count: {
    color: '#546e7a',
    fontSize: '14px',
  }
}));
export default useStyles;