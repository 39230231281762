import React, { 
  Suspense, 
  useState, 
  Fragment}                   from 'react';
import { useLocation }        from 'react-router-dom'
import { renderRoutes }       from 'react-router-config';
import PropTypes              from 'prop-types';
import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { 
  McTopBar, 
  McSnackbar }                      from 'components';
import { 
  NavBar }                        from './components';
import useStyles                    from './Dashboard.style';
import AutoLogin                    from 'views/AutoLogin';

const Dashboard = props => {
  const { route } = props;
  const classes = useStyles();
  // const dispatch = useDispatch();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };
  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  return (
    <Fragment>
      <AutoLogin redirect={useLocation().pathname}/>
      <Box className={classes.root}>
        <McTopBar
          className={classes.topBar}
          onOpenNavBarMobile={handleNavBarMobileOpen}
        />
        <div className={classes.container}>
          <NavBar
            className={classes.navBar}
            onMobileClose={handleNavBarMobileClose}
            openMobile={openNavBarMobile}
          />
          <Box
            component="main"
            sx={{
              '--Content-margin': '0 auto',
              '--Content-maxWidth': 'var(--maxWidth-xl)',
              '--Content-paddingX': '24px',
              '--Content-paddingY': { xs: '24px', lg: '64px' },
              '--Content-padding': 'var(--Content-paddingY) var(--Content-paddingX)',
              '--Content-width': '100%',
              display: 'flex',
              flex: '1 1 auto',
              flexDirection: 'column',
              // border: '5px solid red'
            }}
          >
            <Suspense fallback={<LinearProgress />}>
              {renderRoutes(route.routes)}
            </Suspense>
          </Box>
        </div>

        {/* <ChatBar rtm = {rtm} />

          <ChatBoxBar rtm = {rtm} />

          <ChatRoomBar rtm = {rtm} />

          {
            loggedAgora
          &&
          <Channels 
            clazzes={clazzes} 
            rtm = {rtm}
          />
          }

          <ClassBar rtm = {rtm} /> */}
        {/* </McAuthGuard> */}
        <McSnackbar /> 
      </Box>
      {/* </Beforeunload> */}
    </Fragment>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
