import React from 'react'
import { Link as RouterLink } from 'react-router-dom';

import { ROUTER_TO_HOME_PATH  } from 'store/routerPath';
import useStyles from './Logo.style';
const Logo = () => {
  const classes = useStyles();
  return (
    <RouterLink to={ROUTER_TO_HOME_PATH}>
      <img
        alt="Logo"
        className={classes.logo}
        src="/images/logos/mc-logo-black.png"
      />
    </RouterLink>
  )
}

export default Logo
